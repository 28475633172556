import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeRestriction } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AutorisationListItemComponent } from './autorisation-list-item.component';
import { mapEntites } from './autorisation.entites';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	templateUrl: './autorisation-list.component.html'
})
export class AutorisationListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,AutorisationListItemComponent>;

	/**
	* Récupération du mapping des entités
	*/
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private loggedUserService: LoggedUserService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,AutorisationListItemComponent>({
			uri: () => `/controller/Tenant/filtreAutorisations`,
			title: this.translateService.instant('tenant.autorisation.liste.title'),
			component: AutorisationListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'user',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user',
					filter: {
						typeRestrictionProfil: 'CLIENT'
					}
				},
				isDefault: true
			},{
				clef: 'tenant',
				title: this.translateService.instant('search.client'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'tenant'
				},
				isDefault: true
			},{
				clef: 'dateDebut',
				type: TypeFilter.DATE,
				dateOptions: {
					format: 'date',
					isStoreDate: true
				}
			},{
				clef: 'dateFin',
				type: TypeFilter.DATE,
				dateOptions: {
					format: 'date',
					isStoreDate: true
				}
			}],
			defaultOrder: '-dateDebut,-dateFin',
			getKeyFieldName: () => 'idAutorisation',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idAutorisation) && this.liste.addItem({
					isDisplayed: true,
					user: this.rightService.isRestricted(TypeRestriction.CLIENT) ? this.loggedUserService.getUser() : null
				});
			}
		});
	}
}