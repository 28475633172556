<form #vehiculeEnginForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>vehicule.engin.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
				<label translate>vehicule.immatriculation.selection.numero.item</label>
				<div class="input-group">
					<input type="text" name="numero" class="form-control" #numero="ngModel" [(ngModel)]="vehicule.reference" (ngModelChange)="vehicule.reference = $event.toUpperCase()" pattern="[A-Z0-9]*" required/>
					<div class="input-group-addon">
						<icon name="info_outline" [tooltip]="'vehicule.immatriculation.selection.numero.info' | translate"></icon>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': marque.invalid }">
				<label translate>vehicule.engin.marque</label>
				<div>
					<autocomplete name="marque" type="marque" #marque="ngModel" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION' }" [(ngModel)]="vehicule.marque" (ngModelChange)="vehicule.modele = null" required></autocomplete>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': modele.invalid }">
				<label translate>vehicule.engin.modele</label>
				<div>
					<autocomplete name="modele" type="modele" #modele="ngModel" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',idMarque: vehicule.marque?.idMarque }" [(ngModel)]="vehicule.modele" [disabled]="!vehicule.marque"></autocomplete>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': genre.invalid }">
				<label translate>vehicule.engin.genre</label>
				<div>
					<autocomplete name="genre" type="genre" #genre="ngModel" [(ngModel)]="vehicule.genre" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',type: 'ENGIN' }"></autocomplete>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveVehiculeEngin()" [disabled]="vehiculeEnginForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>