import { Component,Input,OnInit } from '@angular/core';
import { cloneDeep,uniqueId } from 'lodash-es';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'bareme-taxe-tranche-list',
	templateUrl: './bareme-taxe-tranche-list.component.html'
})
export class BaremeTaxeTrancheListComponent extends PageContentItem implements OnInit {
	/** Barème de taxe **/
	@Input() baremeTaxe: { listeTranches: Array<any>,[key: string]: any };

	/** Indicateur du mode édition **/
	@Input() isEdition: any = false;

	/** Enregistrement de l'objet **/
	@Input() saveBaremeTaxe: (baremeTaxe: any, close: Function) => void;

	/** Liste des tranches **/
	public listeTranches: Array<any>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Duplication de la liste des tranches
		this.listeTranches = cloneDeep(this.baremeTaxe.listeTranches?.map(tranche => ({ ...tranche,key: uniqueId() })));

		//Vérification du mode édition et de la liste des tranches
		if (this.isEdition && !this.listeTranches.length)
			//Ajout d'une ligne
			this.addTranche();
	}

	/**
	 * Enregistrement de la liste de tranches
	 */
	saveListeTranches() {
		let baremeTaxe: any;

		//Duplication de l'objet avant enregistrement
		baremeTaxe = cloneDeep(this.baremeTaxe);

		//Définition de la liste des tranches
		baremeTaxe.listeTranches = this.listeTranches;

		//Enregistrement du barème de taxe
		this.saveBaremeTaxe(baremeTaxe,this.close);
	}

	/**
	 * Suppression d'une tranche
	 */
	deleteTranche(index: number) {
		//Suppression de la tranche
		this.listeTranches.splice(index,1);
	}

	/**
	 * Ajout d'une tranche
	 */
	addTranche() {
		//Création d'une nouvelle tranche
		this.listeTranches.push({
			key: uniqueId(),
			debut: this.listeTranches?.length ? Math.max(...this.listeTranches.map(tranche => tranche?.fin || 0)) + 1 : null
		});
	}

	/**
	 * Interception d'une modification de la borne de fin d'une tranche
	 */
	onFinChange(tranche: any,index: number) {
		//Vérification de l'existence de la tranche suivante
		if (this.listeTranches?.[index + 1]) {
			//Mise à jour de la borne de début de la tranche suivante
			this.listeTranches[index + 1].debut = tranche.fin + 1;

			//Génération d'une nouvel identifiant (pour relancer les vérifications)
			this.listeTranches[index + 1].key = uniqueId();
		}
	}
}