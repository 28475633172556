/**
 * Types de comparaison
 */
export enum TypeComparaison {
	/** Enumération **/
	LIKE = 'LIKE',
	GREATER = 'GREATER',
	GREATER_EQUAL = 'GREATER_EQUAL',
	GREATER_OR_NULL = 'GREATER_OR_NULL',
	EQUAL = 'EQUAL',
	NOT_EQUALS = 'NOT_EQUALS',
	BETWEEN = 'BETWEEN',
	LESS = 'LESS',
	LESS_EQUAL = 'LESS_EQUAL',
	LESS_OR_NULL = 'LESS_OR_NULL',
	IS_NULL = 'IS_NULL',
	IS_NOT_NULL = 'IS_NOT_NULL',
	IN = 'IN',
	NOT_IN = 'NOT_IN'
}