import { Component,Input,OnInit } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { BaremeTaxeService } from './bareme-taxe.service';

@Component({
	selector: 'bareme-taxe-edit',
	templateUrl: './bareme-taxe-edit.component.html'
})
export class BaremeTaxeEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() baremeTaxe: any;

	/** Suppression de l'objet **/
	@Input() deleteBaremeTaxe: () => void;

	/** Enregistrement de l'objet **/
	@Input() saveBaremeTaxe: (baremeTaxe: any,close: Function) => void;

	/** Liste des types de barèmes **/
	protected listeTypesBaremeTaxe: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private baremeTaxeService: BaremeTaxeService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des types de barème de taxe
		this.listeTypesBaremeTaxe = this.baremeTaxeService.getListeTypesBaremeTaxe();
	}
}