import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { BaremeTaxeListItemComponent } from './bareme-taxe-list-item.component';
import { mapEntites } from './bareme-taxe.entites';
import { BaremeTaxeService } from './bareme-taxe.service';

@Component({
	templateUrl: './bareme-taxe-list.component.html'
})
export class BaremeTaxeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,BaremeTaxeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,private baremeTaxeService: BaremeTaxeService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,BaremeTaxeListItemComponent>({
			uri: () => `/controller/Fiscalite/filtreBaremesTaxe`,
			title: this.translateService.instant('fiscalite.baremeTaxe.liste.title'),
			component: BaremeTaxeListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'dateApplication',
				type: TypeFilter.DATE
			},{
				clef: 'typeBareme',
				title: this.translateService.instant('fiscalite.baremeTaxe.typeBareme.item'),
				type: TypeFilter.LONG,
				listeValues: this.baremeTaxeService.getListeTypesBaremeTaxe()
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			getKeyFieldName: () => 'idBaremeTaxe',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => this.layoutService.goToByUrl('/Fiscalite/loadBaremeTaxe/0'),
			defaultOrder: '-dateApplication,libelle'
		});
	}
}