<div class="modal-header">
	<div class="d-flex justify-content-space-between align-items-center">
		<h4 class="modal-title flex-1"><span translate>chart.preview.title</span>&nbsp;:&nbsp;{{ dashboardChart.chart.libelle + ' ('+ dashboardChart.chart.reference + ')' }}</h4>
		<div class="actions-container">
			<button mat-icon-button *ngIf="source == 'CHATBOT'" (click)="customize()" [tooltip]="'chart.preview.actions.filtresPersonnalises' | translate">
				<div class="badge" *ngIf="dashboardChart.rule?.listeDetails?.length">{{ dashboardChart.rule.listeDetails.length }}</div>
				<mat-icon>filter_list</mat-icon>
			</button>
		</div>
	</div>
</div>
<div class="modal-body">
	<ul class="liste_filters" *ngIf="source == 'CHATBOT' && listeFilters?.length">
		<li *ngFor="let filter of listeFilters; index as index;">
			<span class="label label-info">
				<span>
					<span class="value">{{ filter.libelle }}</span>
				</span>
				<icon name="close" *ngIf="filter.idDefinition == lastDefinition.idDefinition" class="clickable" (click)="removeFilter(filter)"></icon>
			</span>
		</li>
	</ul>

	<div style="height: 70vh; width: 100%;">
		<gridster [options]="{}">
			<gridster-item [item]="dashboardChart">
				<div class="card detail">
					<div class="card-body">
						<dashboard-chart [dashboardChart]="dashboardChart" [hasNoActions]="false" (onGoBack)="source == 'CHATBOT' && onGoBack($event)" (onSelect)="source == 'CHATBOT' && onSelect($event)" #dashboardChartRef></dashboard-chart>
					</div>
				</div>
			</gridster-item>
		</gridster>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="close()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" *ngIf="canAddChart" (click)="addChart()">
		<span translate>actions.ajouter</span>
	</button>
	<button mat-stroked-button color="primary" *ngIf="source == 'CHATBOT'" (click)="executeExtraction()">
		<span translate>actions.extraire</span>
	</button>
	<button mat-stroked-button color="primary" *ngIf="source == 'CHATBOT' && dashboardChartRef?.currentDefinition?.data" (click)="accessData()">
		<span translate>dashboard.chart.actions.accederDonnees</span>
	</button>
</div>