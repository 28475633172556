<div class="container-fluid">
	<form #templateMailForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full" *ngIf="templateMail">
			<fieldset [disabled]="!(null | right:'creation') || typeReferentiel == 'STANDARD' && !rightService.isRoot()">
				<div class="card-body card-padding">
					<div class="form-group" *ngIf="templateMail.templateMailOrigine">
						<label translate>templateMail.custom.item</label>
						<div>
							<mat-checkbox name="custom" #custom="ngModel" [(ngModel)]="templateMail.custom"><span translate>templateMail.custom.description</span></mat-checkbox>
						</div>
					</div>
					<fieldset [disabled]="typeReferentiel == 'LOCAL' && templateMail.templateMailOrigine && !templateMail.custom">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': type.invalid }">
									<label translate>templateMail.type.item</label>
									<div>
										<selectpicker name="type" #type="ngModel" [(ngModel)]="templateMail.type" (ngModelChange)="onTypeMailChange($event)" [disabled]="!rightService.isRoot()" required>
											<mat-option *ngFor="let type of listeTypesMail" [value]="type.code">{{ type.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
									<label translate>templateMail.reference</label>
									<div>
										<input type="text" name="reference" class="form-control" #reference="ngModel" [(ngModel)]="templateMail.reference" required maxlength="100" [disabled]="typeReferentiel == 'LOCAL' && templateMail.templateMailOrigine || templateMail.type && templateMail.type != 'AUTRE'"/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
									<label translate>templateMail.libelle</label>
									<div>
										<input type="text" name="libelle" class="form-control" #libelle="ngModel" [(ngModel)]="templateMail.libelle" required maxlength="300" [disabled]="typeReferentiel == 'LOCAL' && templateMail.templateMailOrigine"/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': entite.invalid }" *ngIf="templateMail.type == 'AUTRE'">
									<label translate>templateMail.entite</label>
									<div>
										<selectpicker name="entite" #entite="ngModel" [(ngModel)]="templateMail.entite" required>
											<mat-option *ngFor="let entite of listeBusinessData" [value]="entite.type">{{ entite.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': typeDiffusion.invalid }" *ngIf="rightService.isRoot() || user?.tenant.type == 'REVENDEUR'">
									<label translate>templateMail.typeDiffusion.itemShort</label>
									<div>
										<selectpicker name="diffusion" #typeDiffusion="ngModel" [(ngModel)]="templateMail.typeDiffusion" required>
											<mat-option *ngFor="let typeDiffusion of listeTypesDiffusion" [value]="typeDiffusion.code">{{ typeDiffusion.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" *ngIf="canSchedule()">
									<label translate>templateMail.planification</label>
									<div>
										<scheduler name="scheduler" [(ngModel)]="templateMail.scheduler"></scheduler>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>
										<span translate>templateMail.actif.item</span>
										<icon name="info_outline" class="m-l-5" [tooltip]="'templateMail.actif.info' | translate"></icon>
									</label>
									<div>
										<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="templateMail.actif" [disabled]="!templateMail.listeTemplateStructure || templateMail.listeTemplateStructure.length == 0 || typeReferentiel == 'LOCAL' && templateMail.templateMailOrigine"><span translate>templateMail.actif.description</span></mat-checkbox>
									</div>
								</div>
								<div class="form-group" *ngIf="rightService.isRoot() || templateMail.type == 'AUTRE'">
									<label>
										<span translate>templateMail.notificationManuelle.item</span>
										<icon name="info_outline" class="m-l-5" [tooltip]="'templateMail.notificationManuelle.info' | translate"></icon>
									</label>
									<div>
										<mat-checkbox name="notificationManuelle" #notificationManuelle="ngModel" [(ngModel)]="templateMail.notificationManuelle" ><span translate>templateMail.notificationManuelle.description</span></mat-checkbox>
									</div>
								</div>
								<div class="form-group" *ngIf="rightService.isRoot()">
									<label>
										<span translate>templateMail.desactivationDestinataires.item</span>
										<icon name="info_outline" class="m-l-5" [tooltip]="'templateMail.desactivationDestinataires.info' | translate"></icon>
									</label>
									<div>
										<mat-checkbox name="destinatairesNonModifiables" #destinatairesNonModifiables="ngModel" [(ngModel)]="templateMail.destinatairesNonModifiables"><span translate>templateMail.desactivationDestinataires.description</span></mat-checkbox>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': objet.invalid }">
							<label translate>templateMail.objet</label>
							<div>
								<input type="text" name="objet" class="form-control" #objet="ngModel" [(ngModel)]="templateMail.objet" required/>
							</div>
						</div>
					</fieldset>
				</div>

				<div class="card-body card-padding m-t-10">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>templateMail.personnalisationTheme</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>common.actif</label>
								<div>
									<mat-checkbox name="customLogo" #customLogo="ngModel" [(ngModel)]="templateMail.customLogo" ><span translate>templateMail.customLogo.description</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" *ngIf="templateMail.customLogo">
								<label translate>templateMail.customLogo.item</label>
								<div>
									<div [attachment]="templateMail" [typeAttachment]="TypeAttachment.TEMPLATE_MAIL" typeLink="LOGO" queueLimit="1" filters="jpeg|jpg|png"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteTemplateMail();" *ngIf="templateMail.idTemplateMail > 0 && (typeReferentiel == 'LOCAL' || rightService.isRoot()) && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="templateMailForm.invalid || typeReferentiel == 'LOCAL' && templateMail.templateMailOrigine && !templateMail.custom && !isCustom && templateMail.customLogo && !templateMail.listeLinks?.length" (click)="saveTemplateMail();" *ngIf="(null | right:'creation') && (typeReferentiel != 'STANDARD' || rightService.isRoot())">
				<span [translate]="'actions.' + (templateMail?.idTemplateMail > 0 ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="templateMail.idTemplateMail" [formDetectChanges]="{ doAction: close,isDirty: templateMailForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>