import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map } from 'rxjs';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { EtatLieuxTemplateDetailAddComponent } from './etat-lieux-template-detail-add.component';

@Component({
	selector: 'etat-lieux-template-detail',
	templateUrl: './etat-lieux-template-detail.component.html'
})
export class EtatLieuxTemplateDetailComponent  extends PageContentItem implements OnInit {
	/** Template d'état des lieux **/
	@Input() template: any;

	/** Liste des détails fusionnés **/
	@Input() listeDetailsMerged: Array<any>;

	/** Mode édition **/
	@Input() isEdition: boolean = false;

	/** Enregistrement du template **/
	@Input() saveTemplate: (template: any,close?: Function) => void;

	/** Liste des détails statiques **/
	listeStaticDetails: Array<any> = [];

	/** Liste des détails de type d'équipement **/
	listeTypeEquipementDetails: Array<any> = [];

	/** Liste des détails de type d'aménagement **/
	listeTypeAmenagementDetails: Array<any> = [];

	/** Liste des détails libres **/
	listeOtherDetails: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let listeDetails: Array<any> = [];

		//Vérification de la présence de la liste des détails
		if (this.isEdition && this.listeDetailsMerged || this.template.listeDetails) {
			//Récupération de la liste des détails
			listeDetails = this.isEdition && this.listeDetailsMerged || this.template.listeDetails;

			//Parcours de la liste des détails
			listeDetails.forEach((item) => {
				//Vérification du type de groupe
				if (!['TYPE_EQUIPEMENT','TYPE_AMENAGEMENT','AUTRE'].includes(item.type))
					//Ajout du détail dans la section correspondante
					this.listeStaticDetails.push(item);
				else if (item.type == 'TYPE_EQUIPEMENT' && item.typeEquipement)
					//Ajout du détail dans la section correspondante
					this.listeTypeEquipementDetails.push(item);
				else if (item.type == 'TYPE_AMENAGEMENT' && item.typeAmenagement)
					//Ajout du détail dans la section correspondante
					this.listeTypeAmenagementDetails.push(item);
				else if (item.type == 'AUTRE' && item.libelle)
					//Ajout du détail dans la section correspondante
					this.listeOtherDetails.push(item);
			});
		}
	}

	/**
	 * Enregistrement du template
	 */
	public saveDetails() {
		let listeDetails: Array<any> = [];

		//Concaténation des listes de détails
		listeDetails = listeDetails.concat(this.listeStaticDetails,this.listeTypeEquipementDetails,this.listeTypeAmenagementDetails,this.listeOtherDetails);

		//Vérification de la présence de détails
		if (listeDetails.length)
			//Filtrage des détails
			listeDetails = listeDetails.filter(item => item.visible);

		//Définition de la liste des détails
		this.template.listeDetails = listeDetails;

		//Enregistrement du template
		this.saveTemplate(this.template,this.close.bind(this));
	}

	/**
	 * Ajout d'un élément
	 */
	addItem(type: 'TYPE_EQUIPEMENT' | 'TYPE_AMENAGEMENT' | 'AUTRE') {
		let bsModalRef: BsModalRef<EtatLieuxTemplateDetailAddComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(EtatLieuxTemplateDetailAddComponent,{
			initialState: {
				type,
				listeDetails: type == 'TYPE_EQUIPEMENT' ? this.listeTypeEquipementDetails : type == 'TYPE_AMENAGEMENT' ? this.listeTypeAmenagementDetails : this.listeOtherDetails
			},
			class: 'modal-md'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.detail),
			filter(detail => !!detail)
		).subscribe({
			next: (detail: any) => {
				//Vérification du type
				if (type == 'TYPE_EQUIPEMENT')
					//Ajout du résultat
					this.listeTypeEquipementDetails.push(detail);
				else if (type == 'TYPE_AMENAGEMENT')
					//Ajout du résultat
					this.listeTypeAmenagementDetails.push(detail);
				else if (type == 'AUTRE')
					//Ajout du résultat
					this.listeOtherDetails.push(detail);
			}
		});
	}
}