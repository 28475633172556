import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { EquipementService } from 'src/app/components/materiel/equipement/equipement.service';

@Component({
	selector: 'etat-lieux-template-detail-add',
	templateUrl: './etat-lieux-template-detail-add.component.html'
})
export class EtatLieuxTemplateDetailAddComponent implements OnInit {
	/** Type de champ **/
	@Input() type: 'TYPE_EQUIPEMENT' | 'TYPE_AMENAGEMENT' | 'AUTRE';

	/** Détail **/
	detail: any;

	/** Liste des détails **/
	listeDetails: Array<any>;

	/** Résultat **/
	result: { detail: any };

	/** Liste des types d'équipement **/
	public listeTypesEquipement: Array<{ code: string,libelle: string }>;

	/** Liste des identifiants de type d'aménagement exclus **/
	listeExcludedIdsTypeAmenagement: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<EtatLieuxTemplateDetailAddComponent>,private equipementService: EquipementService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste des types d'équipement
		this.listeTypesEquipement = this.equipementService.getListeTypes();

		//Initialisation du détail
		this.detail = {
			type: this.type,
			visible: true
		};

		//Vérification de la liste
		if (this.listeDetails.length && (this.type == 'TYPE_EQUIPEMENT' || this.type == 'TYPE_AMENAGEMENT')) {
			//Vérification du type
			if (this.type == 'TYPE_EQUIPEMENT')
				//Filtrage des types d'équipement
				this.listeTypesEquipement = this.listeTypesEquipement.filter(type => !this.listeDetails.some(item => item.typeEquipement == type.code));
			else
				//Filtrage des types d'aménagement
				this.listeExcludedIdsTypeAmenagement = this.listeDetails.filter(item => item.typeAmenagement).map(item => item.typeAmenagement.idTypeAmenagement);
		}
	}

	/**
	 * Enregistrement du détail
	 */
	public saveDetail() {
		//Définition du résultat
		this.result = {
			detail: this.detail
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}