<div class="pull-left lv-avatar" [translate]="'templateMail.destinataire.type.court.'+data.type"></div>
<div class="media-body">
	<div class="lv-title"><a [routerLink]="[]" (click)="extraOptions?.addDestinataire?.(data)">{{ data.libelle }}</a></div>
	<ul class="lv-attrs">
		<li><span translate>templateMail.destinataire.type.item</span>&#160;:&#160;<span [translate]="'templateMail.destinataire.type.long.'+data.type"></span></li>
		<li><span translate>templateMail.destinataire.source.item</span>&#160;:&#160;<span [translate]="'templateMail.destinataire.source.'+(data.destinataireDynamique ? 'DYNAMIQUE' : data.emailStatique ? 'EMAIL' : 'COLLABORATEUR')"></span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation') && (rightService.isRoot() || extraOptions?.templateMail?.tenant && !extraOptions?.templateMail?.destinatairesNonModifiables)">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="deleteDestinataire();" translate>actions.supprimer</a></li>
		</ul>
	</div>
</div>