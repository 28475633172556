import { TranslateService } from '@ngx-translate/core';

import { CalendrierListComponent } from './components/calendrier/calendrier-list.component';
import { CalendrierComponent } from './components/calendrier/calendrier.component';
import { ClientCRMListComponent } from './components/client-crm/client-crm-list.component';
import { CompteServiceListComponent } from './components/compte-service/compte-service-list.component';
import { CompteServiceComponent } from './components/compte-service/compte-service.component';
import { ImportListComponent } from './components/connecteur/import/import-list.component';
import { ContratCRMListComponent } from './components/contrat-crm/contrat-crm-list.component';
import { ContratCRMComponent } from './components/contrat-crm/contrat-crm.component';
import { PaysListComponent } from './components/pays/pays-list.component';
import { ReferentielListComponent } from './components/referentiel-list/referentiel-list.component';
import { TenantListComponent } from './components/tenant/tenant-list.component';
import { TenantComponent } from './components/tenant/tenant.component';
import { PoolListComponent } from './components/vehicule/pool/pool-list.component';
import { PoolComponent } from './components/vehicule/pool/pool.component';
import { ReservationListComponent } from './components/vehicule/pool/reservation/reservation-list.component';
import { LoggedGuardProvider } from './core/security/logged-guard.provider';
import { NotLoggedGuardProvider } from './core/security/not-logged-guard.provider';
import { ExtendedRoute } from './domain/common/route/extended-route';
import { TypeDashboard } from './domain/dashboard/dashboard';
import { TypeReferentielList } from './domain/referentiel-list/referentiel-list';
import { ChartListComponent } from './components/chart/chart-list.component';
import { ChartComponent } from './components/chart/chart.component';
import { DataCustomizationListComponent } from './components/data-customization/data-customization-list.component';
import { DataCustomizationComponent } from './components/data-customization/data-customization.component';
import { TypeDroit,TypeTenant } from './domain/security/right';
import { LoginComponent } from './share/login/login.component';
import { TestComponent } from './test.component';
import { NatureListComponent } from './components/fournisseur/nature/nature-list.component';
import { NatureComponent } from './components/fournisseur/nature/nature.component';
import { UserCategorieListComponent } from './components/user/categorie/user-categorie-list.component';
import { FournisseurListComponent } from './components/fournisseur/fournisseur-list.component';
import { FournisseurComponent } from './components/fournisseur/fournisseur.component';
import { CoursListComponent } from './components/devise/cours/cours-list.component';
import { DeviseListComponent } from './components/devise/devise-list.component';
import { DeviseComponent } from './components/devise/devise.component';
import { CodeListComponent } from './components/fournisseur/code/code-list.component';
import { FormatInterfaceListComponent } from './components/fournisseur/format-interface/format-interface-list.component';
import { FormatInterfaceComponent } from './components/fournisseur/format-interface/format-interface.component';
import { OffreListComponent } from './components/offre/offre-list.component';
import { OffreComponent } from './components/offre/offre.component';
import { GenreListComponent } from './components/vehicule/genre/genre-list.component';
import { TypeAmenagementListComponent } from './components/vehicule/type-amenagement/type-amenagement-list.component';
import { TypeAmenagementComponent } from './components/vehicule/type-amenagement/type-amenagement.component';
import { TypeInfractionListComponent } from './components/vehicule/type-infraction/type-infraction-list.component';
import { SocieteListComponent } from './components/societe/societe-list.component';
import { AmenagementListComponent } from './components/materiel/amenagement/amenagement-list.component';
import { DemandeCotationListComponent } from './components/commande/demande-cotation/demande-cotation-list.component';
import { DemandeCotationComponent } from './components/commande/demande-cotation/demande-cotation.component';
import { ConducteurListComponent } from './components/vehicule/conducteur/conducteur-list.component';
import { ConducteurComponent } from './components/vehicule/conducteur/conducteur.component';
import { StructureExportListComponent } from './components/structure-export/structure-export-list.component';
import { StructureExportComponent } from './components/structure-export/structure-export.component';
import { EquipementListComponent } from './components/materiel/equipement/equipement-list.component';
import { EquipementComponent } from './components/materiel/equipement/equipement.component';
import { PlanComptableListComponent } from './components/comptabilite/plan-comptable/plan-comptable-list.component';
import { PlanComptableComponent } from './components/comptabilite/plan-comptable/plan-comptable.component';
import { CompteListComponent } from './components/comptabilite/compte/compte-list.component';
import { CompteComponent } from './components/comptabilite/compte/compte.component';
import { CompteAuxiliaireListComponent } from './components/comptabilite/compte-auxiliaire/compte-auxiliaire-list.component';
import { CompteAuxiliaireComponent } from './components/comptabilite/compte-auxiliaire/compte-auxiliaire.component';
import { ArticleListComponent } from './components/article/article-list.component';
import { ArticleComponent } from './components/article/article.component';
import { ArticleConsultationComponent } from './share/components/article/article-consultation.component';
import { DemandeIdentificationListComponent } from './components/vehicule/infraction/demande-identification/demande-identification-list.component';
import { DemandeIdentificationComponent } from './components/vehicule/infraction/demande-identification/demande-identification.component';
import { FactureListComponent } from './components/facture/facture-list.component';
import { FactureComponent } from './components/facture/facture.component';
import { DetailListComponent } from './components/facture/detail/detail-list.component';
import { DetailComponent } from './components/facture/detail/detail.component';
import { EntretienListComponent } from './components/vehicule/entretien/entretien-list.component';
import { UserListComponent } from './components/user/user-list.component';
import { UserComponent } from './components/user/user.component';
import { GroupeListComponent } from './components/groupe/groupe-list.component';
import { GroupeComponent } from './components/groupe/groupe.component';
import { LotComptableListComponent } from './components/comptabilite/lot-comptable/lot-comptable-list.component';
import { LotComptableComponent } from './components/comptabilite/lot-comptable/lot-comptable.component';
import { ConnecteurListComponent } from './components/connecteur/connecteur-list.component';
import { ConnecteurComponent } from './components/connecteur/connecteur.component';
import { ConnecteurTypeListComponent } from './components/connecteur/type/connecteur-type-list.component';
import { SinistreListComponent } from './components/vehicule/sinistre/sinistre-list.component';
import { SinistreComponent } from './components/vehicule/sinistre/sinistre.component';
import { LicenceConsommationListComponent } from './components/licence/consommation/licence-consommation-list.component';
import { LicenceConsommationComponent } from './components/licence/consommation/licence-consommation.component';
import { ExtractionListComponent } from './components/extraction/extraction-list.component';
import { ExtractionComponent } from './components/extraction/extraction.component';
import { TemplateMailListComponent } from './components/template-mail/template-mail-list.component';
import { TemplateMailComponent } from './components/template-mail/template-mail.component';
import { EtatLieuxListComponent } from './components/vehicule/etat-lieux/etat-lieux-list.component';
import { EtatLieuxComponent } from './components/vehicule/etat-lieux/etat-lieux.component';
import { ProfilListComponent } from './components/profil/profil-list.component';
import { ProfilComponent } from './components/profil/profil.component';
import { TaxeListComponent } from './components/fiscalite/taxe/taxe-list.component';
import { TaxeComponent } from './components/fiscalite/taxe/taxe.component';
import { AffectationEcheanceListComponent } from './components/vehicule/affectation-echeance/affectation-echeance-list.component';
import { ContraventionListComponent } from './components/vehicule/infraction/contravention/contravention-list.component';
import { ContraventionComponent } from './components/vehicule/infraction/contravention/contravention.component';
import { TypeModule } from './domain/connecteur/type-module';
import { ContratFinancementListComponent } from './components/vehicule/contrat/contrat-financement-list.component';
import { ContratFinancementComponent } from './components/vehicule/contrat/contrat-financement.component';
import { OptionCategorieListComponent } from './components/vehicule/modele/option-categorie/option-categorie-list.component';
import { PlanEntretienListComponent } from './components/vehicule/plan-entretien/plan-entretien-list.component';
import { PlanEntretienComponent } from './components/vehicule/plan-entretien/plan-entretien.component';
import { MarqueListComponent } from './components/vehicule/marque/marque-list.component';
import { ModeleListComponent } from './components/vehicule/modele/modele-list.component';
import { ModeleComponent } from './components/vehicule/modele/modele.component';
import { TypeCarrosserieListComponent } from './components/vehicule/type-carrosserie/type-carrosserie-list.component';
import { CarburantListComponent } from './components/vehicule/carburant/carburant-list.component';
import { ReleveEnergieListComponent } from './components/vehicule/releve-energie/releve-energie-list.component';
import { VehiculeListComponent } from './components/vehicule/vehicule-list.component';
import { VehiculeComponent } from './components/vehicule/vehicule.component';
import { SgiLotListComponent } from './components/sgi/sgi-lot-list.component';
import { TypeEntretienListComponent } from './components/vehicule/type-entretien/type-entretien-list.component';
import { RegleTaxeListComponent } from './components/fiscalite/regle-taxe/regle-taxe-list.component';
import { RegleTaxeComponent } from './components/fiscalite/regle-taxe/regle-taxe.component';
import { GrilleAttributionListComponent } from './components/vehicule/grille-attribution/grille-attribution-list.component';
import { GrilleAttributionComponent } from './components/vehicule/grille-attribution/grille-attribution.component';
import { GrilleAttributionModeleComponent } from './components/vehicule/grille-attribution/grille-attribution-modele/grille-attribution-modele.component';
import { MotifListComponent } from './components/workflow/motif/motif-list.component';
import { WorkflowListComponent } from './components/workflow/workflow-list.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { ControleReglementaireListComponent } from './components/vehicule/controle-reglementaire/controle-reglementaire-list.component';
import { ControleReglementaireComponent } from './components/vehicule/controle-reglementaire/controle-reglementaire.component';
import { RevendeurListComponent } from './components/revendeur/revendeur-list.component';
import { DatabaseListComponent } from './components/database/database-list.component';
import { ReleveCompteurListComponent } from './components/vehicule/releve-compteur/releve-compteur-list.component';
import { AffectationListComponent } from './components/vehicule/affectation/affectation-list.component';
import { ServiceListComponent } from './components/service/service-list.component';
import { ServiceComponent } from './components/service/service.component';
import { DemandeVehiculeListComponent } from './components/vehicule/commande/demande-vehicule/demande-vehicule-list.component';
import { DemandeVehiculeComponent } from './components/vehicule/commande/demande-vehicule/demande-vehicule.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardRedirectorComponent } from './components/dashboard/dashboard-redirector.component';
import { UpgradeListComponent } from './components/upgrade/upgrade-list.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { ArticleCRMListComponent } from './components/article-crm/article-crm-list.component';
import { ValidationListComponent } from './components/workflow/validation/validation-list.component';
import { ReponseDemandeCotationComponent } from './components/commande/reponse-cotation/reponse-demande-cotation.component';
import { PlanningComponent } from './components/planning/planning.component';
import { AccessForbiddenComponent } from './share/layout/access-forbidden/access-forbidden.component';
import { CguGuardProvider } from './core/security/cgu-guard.provider';
import { RegleAutomatiqueListComponent } from './components/regle-automatique/regle-automatique-list.component';
import { RegleAutomatiqueComponent } from './components/regle-automatique/regle-automatique.component';
import { AutorisationListComponent } from './components/tenant/autorisation/autorisation-list.component';
import { ConversationListComponent } from './components/conversation/conversation-list.component';
import { AvantageNatureAbattementListComponent } from './components/fiscalite/avantage-nature/avantage-nature-abattement-list.component';
import { EtatLieuxTemplateListComponent } from './components/vehicule/etat-lieux-template/etat-lieux-template-list.component';
import { EtatLieuxTemplateComponent } from './components/vehicule/etat-lieux-template/etat-lieux-template.component';
import { BaremeTaxeListComponent } from './components/fiscalite/bareme-taxe/bareme-taxe-list.component';
import { BaremeTaxeComponent } from './components/fiscalite/bareme-taxe/bareme-taxe.component';

/**
 * Export des routes
 */
export const routes: Array<ExtendedRoute> = [{
	path: 'Login',
	component: LoginComponent,
	canActivate: [NotLoggedGuardProvider],
	data: {
		state: 'login'
	}
},{
	path: 'Login/:codeTenant?route',
	component: LoginComponent,
	canActivate: [NotLoggedGuardProvider],
	data: {
		state: 'login-direct'
	}
},{
	path: 'Login/:action/:codeTenant/:idUser/:token',
	component: LoginComponent,
	canActivate: [NotLoggedGuardProvider],
	data: {
		state: 'login-action'
	}
},{
	path: 'Dashboard',
	component: DashboardComponent,
	canActivate: [LoggedGuardProvider],
	canDeactivate: [CguGuardProvider],
	data: {
		state: 'dashboard',
		title: 'dashboard.title'
	}
},{
	path: 'Dashboard/:typeDashboard',
	component: DashboardComponent,
	canActivate: [LoggedGuardProvider],
	canDeactivate: [CguGuardProvider],
	data: {
		state: 'dashboard-noreset',
		title: 'dashboard.title'
	}
},{
	path: 'DashboardRedirector/:typeDashboard',
	component: DashboardRedirectorComponent,
	canActivate: [LoggedGuardProvider]
},{
	path: 'ClientCRM/listeClientsCRM',
	component: ClientCRMListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_CLIENT_CRM,
		state: 'commerceReferentiel-listeClientsCRM',
		isChild: true
	}
},{
	path: 'ArticleCRM/listeArticlesCRM',
	component: ArticleCRMListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_ARTICLE_CRM,
		state: 'commerceReferentiel-listeArticlesCRM',
		isChild: true
	}
},{
	path: 'Database/listeDatabases',
	component: DatabaseListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_DATABASE,
		state: 'listeDatabases'
	}
},{
	path: 'Devise/listeCours',
	component: CoursListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_DEVISE,
		state: 'listeReferentiels-listeCours',
		isChild: true
	}
},{
	path: 'Devise/listeDevises',
	component: DeviseListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_DEVISE,
		state: 'listeReferentiels-listeDevises',
		isChild: true
	}
},{
	path: 'Devise/loadDevise/:typeReferentiel/:idDevise',
	component: DeviseComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_DEVISE,
		state: 'listeReferentiels-listeDevises-devise',
		title: 'devise.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Geographie/listePays',
	component: PaysListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_GEOGRAPHIE_PAYS,
		state: 'geographieReferentiel-listePays',
		isChild: true
	}
},{
	path: 'Geographie/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'geographieReferentiel',
		typeReferentielList: TypeReferentielList.GEOGRAPHIE,
		title: 'referentielList.geographie.title'
	},
},{
	path: 'Import/listeImports',
	component: ImportListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_IMPORT,
		state: 'listeImports',
		typeDashboard: TypeDashboard.IMPORT
	}
},{
	path: 'Licence/listeLicenceConsommations',
	component: LicenceConsommationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.LICENCE_CONSOMMATION,
		state: 'listeLicenceConsommations'
	}
},{
	path: 'Licence/loadLicenceConsommation/:idLicenceConsommation',
	component: LicenceConsommationComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		title: 'licence.consommation.title',
		typeDroit: TypeDroit.LICENCE_CONSOMMATION,
		state: 'listeLicenceConsommations-licenceConsommation',
		isHistory: true,
		isChild: true
	}
},{
	path: 'Devise/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeReferentiels',
		typeReferentielList: TypeReferentielList.DEVISE,
		title: 'referentielList.devise.title',
		typeDroit: TypeDroit.ADMIN_DEVISE
	}
},{
	path: 'Fiscalite/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fiscaliteReferentiel',
		typeReferentielList: TypeReferentielList.FISCALITE,
		title: 'referentielList.fiscalite.title'
	}
},{
	path: 'Fiscalite/listeTaxes',
	component: TaxeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fiscaliteReferentiel-listeTaxes',
		typeDroit: TypeDroit.ADMIN_TAXE,
		isChild: true
	}
},{
	path: 'Fiscalite/loadTaxe/:idTaxe',
	component: TaxeComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fiscaliteReferentiel-listeTaxes-taxe',
		typeDroit: TypeDroit.ADMIN_TAXE,
		title: 'fiscalite.taxe.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Fiscalite/listeReglesTaxes',
	component: RegleTaxeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fiscaliteReferentiel-listeReglesTaxes',
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		typeTenant: TypeTenant.ROOT,
		isChild: true
	}
},{
	path: 'Fiscalite/loadRegleTaxe/:idRegle',
	component: RegleTaxeComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fiscaliteReferentiel-listeReglesTaxes-regleTaxe',
		title: 'fiscalite.regleTaxe.title',
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		typeTenant: TypeTenant.ROOT,
		isChild: true,
		isHistory: true
	}
},{
	path: 'Fiscalite/listeBaremesTaxe',
	component: BaremeTaxeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fiscaliteReferentiel-listeBaremesTaxe',
		typeDroit: TypeDroit.ADMIN_TAXE,
		typeTenant: TypeTenant.ROOT,
		isChild: true
	}
},{
	path: 'Fiscalite/loadBaremeTaxe/:idBaremeTaxe',
	component: BaremeTaxeComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fiscaliteReferentiel-listeBaremesTaxe-baremeTaxe',
		title: 'fiscalite.baremeTaxe.title',
		typeDroit: TypeDroit.ADMIN_TAXE,
		typeTenant: TypeTenant.ROOT,
		isChild: true,
		isHistory: true
	}
},{
	path: 'Fiscalite/listeAvantageNatureAbattements',
	component: AvantageNatureAbattementListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fiscaliteReferentiel-listeAvantageNatureAbattements',
		typeDroit: TypeDroit.ADMIN_TAXE,
		typeTenant: TypeTenant.ROOT,
		isChild: true
	}
},{
	path: 'Vehicule/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel',
		typeReferentielList: TypeReferentielList.VEHICULE,
		title: 'referentielList.vehicule.title'
	}
},{
	path: 'Profil/listeProfils',
	component: ProfilListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeProfils',
		typeDroit: TypeDroit.ADMIN_PROFIL
	}
},{
	path: 'Profil/loadProfil/:idProfil',
	component: ProfilComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeProfils-profil',
		typeDroit: TypeDroit.ADMIN_PROFIL,
		title: 'profil.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'AffectationEcheance/listeAffectationEcheances',
	component: AffectationEcheanceListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeAffectationEcheances',
		typeDroit: TypeDroit.VEHICULE_AFFECTATION_ECHEANCE,
		typeDashboard: TypeDashboard.VEHICULE_AFFECTATION_ECHEANCE
	}
},{
	path: 'Vehicule/listePools',
	component: PoolListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listePools',
		typeDroit: TypeDroit.VEHICULE_POOL,
		typeModule: TypeModule.VEHICULE_REFERENTIEL,
		isChild: true
	}
},{
	path: 'Vehicule/listeMarques',
	component: MarqueListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeMarques',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_MARQUE,
		isChild: true
	}
},{
	path: 'Vehicule/listeModeles',
	component: ModeleListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeModeles',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_MODELE,
		isChild: true,
		typeModule: TypeModule.VEHICULE_REFERENTIEL
	}
},{
	path: 'Vehicule/loadVehiculeModele/:idModele',
	component: ModeleComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeModeles-modele',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_MODELE,
		title: 'vehicule.modele.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Vehicule/loadPool/:idPool',
	component: PoolComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listePools-pool',
		typeDroit: TypeDroit.VEHICULE_POOL,
		title: 'pool.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Vehicule/listeTypesAmenagement',
	component: TypeAmenagementListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeTypesAmenagement',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_TYPE_AMENAGEMENT,
		isChild: true
	}
},{
	path: 'Vehicule/loadTypeAmenagement/:idTypeAmenagement',
	component: TypeAmenagementComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeTypesAmenagement-typeAmenagement',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_TYPE_AMENAGEMENT,
		title: 'vehicule.typeAmenagement.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Vehicule/listeOptionsCategories',
	component: OptionCategorieListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeOptionsCategories',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_OPTION_CATEGORIE,
		isChild: true
	}
},{
	path: 'Vehicule/listeTypesInfraction',
	component: TypeInfractionListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeTypesInfraction',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_TYPE_INFRACTION,
		isChild: true
	}
},{
	path: 'Vehicule/listeGenres',
	component: GenreListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeGenres',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_GENRE,
		isChild: true
	}
},{
	path: 'Vehicule/listePlansEntretien',
	component: PlanEntretienListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listePlansEntretien',
		typeDroit: TypeDroit.ENTRETIEN,
		typeModule: TypeModule.VEHICULE_REFERENTIEL,
		isChild: true
	}
},{
	path: 'Vehicule/loadPlanEntretien/:idPlanEntretien',
	component: PlanEntretienComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listePlansEntretien-loadPlanEntretien',
		typeDroit: TypeDroit.ENTRETIEN,
		title: 'vehicule.planEntretien.title',
		isHistory: true,
		isChild: true
	}
},{
	path: 'Vehicule/listeTypesCarrosserie',
	component: TypeCarrosserieListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeTypesCarrosserie',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_TYPE_CARROSSERIE,
		isChild: true
	}
},{
	path: 'Vehicule/listeTypesEntretien',
	component: TypeEntretienListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeTypesEntretien',
		typeDroit: TypeDroit.ENTRETIEN,
		typeModule: TypeModule.VEHICULE_REFERENTIEL,
		isChild: true
	}
},{
	path: 'Vehicule/listeCarburants',
	component: CarburantListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeCarburants',
		typeDroit: TypeDroit.VEHICULE_REFERENTIEL_CARBURANT,
		isChild: true
	}
},{
	path: 'Vehicule/listeEtatLieuxTemplates',
	component: EtatLieuxTemplateListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeEtatLieuxTemplates',
		typeDroit: TypeDroit.ETAT_LIEUX,
		typeModule: TypeModule.VEHICULE_REFERENTIEL,
		isChild: true
	}
},{
	path: 'Vehicule/loadEtatLieuxTemplate/:idEtatLieuxTemplate',
	component: EtatLieuxTemplateComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeEtatLieuxTemplates-etatLieuxTemplate',
		typeDroit: TypeDroit.ETAT_LIEUX,
		title: 'vehicule.etatLieuxTemplate.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'GrilleAttribution/listeGrillesAttribution',
	component: GrilleAttributionListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeGrillesAttribution',
		typeDroit: TypeDroit.VEHICULE_GRILLE_ATTRIBUTION,
		typeDashboard: TypeDashboard.GRILLE_ATTRIBUTION
	}
},{
	path: 'GrilleAttribution/loadGrilleAttribution/:idGrilleAttribution',
	component: GrilleAttributionComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeGrillesAttribution-grilleAttribution',
		typeDroit: TypeDroit.VEHICULE_GRILLE_ATTRIBUTION,
		title: 'vehicule.grilleAttribution.title',
		isHistory: true,
		isChild: true
	}
},{
	path: 'GrilleAttribution/loadGrilleAttributionModele/:idGrilleAttribution/:idModele',
	component: GrilleAttributionModeleComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeGrillesAttribution-grilleAttributionModele',
		typeDroit: TypeDroit.VEHICULE_GRILLE_ATTRIBUTION,
		title: 'vehicule.grilleAttribution.modele.title',
		isHistory: true,
		isChild: true
	}
},{
	path: 'Fournisseur/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fournisseurReferentiel',
		typeReferentielList: TypeReferentielList.FOURNISSEUR,
		title: 'referentielList.fournisseur.title'
	}
},{
	path: 'Revendeur/listeRevendeurs',
	component: RevendeurListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeRevendeurs',
		typeDroit: TypeDroit.ADMIN_REVENDEUR
	}
},{
	path: 'Fournisseur/listeFournisseurs',
	component: FournisseurListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_FOURNISSEUR,
		state: 'fournisseurReferentiel-listeFournisseurs',
		typeModule: TypeModule.FOURNISSEUR,
		isChild: true
	}
},{
	path: 'Fournisseur/loadFournisseur/:typeReferentiel/:idFournisseur',
	component: FournisseurComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fournisseurReferentiel-listeFournisseurs-fournisseur',
		title: 'fournisseur.title',
		typeDroit: TypeDroit.ADMIN_FOURNISSEUR,
		isChild: true,
		isHistory: true
	}
},{
	path: 'Fournisseur/listeInterfaces',
	component: FormatInterfaceListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fournisseurReferentiel-listeInterfaces',
		typeDroit: TypeDroit.ADMIN_FOURNISSEUR_INTERFACE,
		isChild: true
	}
},{
	path: 'Fournisseur/loadInterface/:idInterface',
	component: FormatInterfaceComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fournisseurReferentiel-listeInterfaces-interface',
		typeDroit: TypeDroit.ADMIN_FOURNISSEUR_INTERFACE,
		title: 'fournisseur.formatInterface.title',
		isHistory: true,
		isChild: true
	}
},{
	path: 'Fournisseur/listeCodes',
	component: CodeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fournisseurReferentiel-listeCodes',
		typeDroit: TypeDroit.ADMIN_FOURNISSEUR_INTERFACE,
		isChild: true
	}
},{
	path: 'Fournisseur/listeNatures',
	component: NatureListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fournisseurReferentiel-listeFournisseurNatures',
		typeDroit: TypeDroit.ADMIN_FOURNISSEUR_NATURE,
		isChild: true
	}
},{
	path: 'Fournisseur/loadNature/:idNature',
	component: NatureComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fournisseurReferentiel-listeFournisseurNatures-loadNature',
		typeDroit: TypeDroit.ADMIN_FOURNISSEUR_NATURE,
		title: 'nature.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Commerce/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'commerceReferentiel',
		typeReferentielList: TypeReferentielList.COMMERCE,
		title: 'referentielList.commerce.title'
	}
},{
	path: 'Offre/listeOffres',
	component: OffreListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'commerceReferentiel-listeOffres',
		typeDroit: TypeDroit.ADMIN_OFFRE,
		isChild: true
	}
},{
	path: 'Offre/loadOffre/:idOffre',
	component: OffreComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'commerceReferentiel-listeOffres-offre',
		typeDroit: TypeDroit.ADMIN_OFFRE,
		title: 'offre.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Comptabilite/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'comptabiliteReferentiel',
		typeReferentielList: TypeReferentielList.COMPTABILITE,
		title: 'referentielList.comptabilite.title'
	}
},{
	path: 'Comptabilite/listePlanComptables',
	component: PlanComptableListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		state: 'comptabiliteReferentiel-listePlanComptables',
		typeModule: TypeModule.COMPTABILITE,
		isChild: true
	}
},{
	path: 'Comptabilite/loadPlanComptable/:idPlanComptable',
	component: PlanComptableComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'comptabiliteReferentiel-listePlanComptables-planComptable',
		title: 'comptabilite.planComptable.title',
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		isChild: true,
		isHistory: true
	}
},{
	path: 'Comptabilite/listeComptes',
	component: CompteListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		state: 'comptabiliteReferentiel-listeComptes',
		typeModule: TypeModule.COMPTABILITE,
		isChild: true
	}
},{
	path: 'Comptabilite/loadCompte/:idCompte',
	component: CompteComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		state: 'comptabiliteReferentiel-listeComptes-compte',
		title: 'comptabilite.compte.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Comptabilite/listeComptesAuxiliaires',
	component: CompteAuxiliaireListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		state: 'comptabiliteReferentiel-listeComptesAuxiliaires',
		typeModule: TypeModule.COMPTABILITE,
		isChild: true
	}
},{
	path: 'Comptabilite/loadCompteAuxiliaire/:idCompteAuxiliaire',
	component: CompteAuxiliaireComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		state: 'comptabiliteReferentiel-listeComptesAuxiliaires-compteAuxiliaire',
		title: 'comptabilite.compteAuxiliaire.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'VehiculeCommande/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeCommandeReferentiels',
		typeReferentielList: TypeReferentielList.VEHICULE_COMMANDE,
		title: 'referentielList.vehiculeCommande.title'
	}
},{
	path: 'VehiculeCommande/listeDemandesVehicule',
	component: DemandeVehiculeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeCommandeReferentiels-listeDemandesVehicule',
		typeDroit: TypeDroit.DEMANDE_VEHICULE,
		typeDashboard: TypeDashboard.DEMANDE_VEHICULE,
		typeModule: TypeModule.VEHICULE,
		isChild: true
	}
},{
	path: 'VehiculeCommande/loadDemandeVehicule/:idDemandeVehicule',
	component: DemandeVehiculeComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeCommandeReferentiels-listeDemandesVehicule-detail',
		title: 'demandeVehicule.title.main',
		typeDroit: TypeDroit.DEMANDE_VEHICULE,
		validationClass: 'com.notilus.data.vehicule.cotation.DemandeVehicule',
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'Infraction/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'infractionReferentiel',
		typeReferentielList: TypeReferentielList.VEHICULE_INFRACTION,
		title: 'referentielList.vehiculeInfraction.title'
	}
},{
	path: 'Materiel/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'materielReferentiel',
		typeReferentielList: TypeReferentielList.MATERIEL,
		title: 'referentielList.materiel.title'
	}
},{
	path: 'Equipement/listeEquipements',
	component: EquipementListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'materielReferentiel-listeEquipements',
		typeDroit: TypeDroit.EQUIPEMENT,
		typeDashboard: TypeDashboard.EQUIPEMENT,
		typeModule: TypeModule.EQUIPEMENT,
		isChild: true
	}
},{
	path: 'Equipement/loadEquipement/:idEquipement',
	component: EquipementComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'materielReferentiel-listeEquipements-loadEquipement',
		title: 'equipement.title',
		typeDroit: TypeDroit.EQUIPEMENT,
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'Societe/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'demandeIdentificationReferentiel',
		typeReferentielList: TypeReferentielList.DEMANDE_IDENTIFICATION,
		title: 'referentielList.demandeIdentification.title',
		typeDroit: TypeDroit.ADMIN_SOCIETE
	}
},{
	path: 'Collaborateur/listeReferentiels',
	component: ReferentielListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'collaborateurReferentiel',
		typeReferentielList: TypeReferentielList.COLLABORATEUR,
		title: 'referentielList.collaborateur.title'
	}
},{
	path: 'VehiculeReservation/listeReservations',
	component: ReservationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.VEHICULE_RESERVATION,
		state: 'listeReservations',
		typeDashboard: TypeDashboard.VEHICULE_RESERVATION
	}
},{
	path: 'Contravention/listeContraventions',
	component: ContraventionListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'infractionReferentiel-listeVehiculeContraventions',
		typeDroit: TypeDroit.VEHICULE_CONTRAVENTION,
		typeDashboard: TypeDashboard.CONTRAVENTION,
		typeModule: TypeModule.VEHICULE,
		isChild: true
	}
},{
	path: 'Contravention/loadContravention/:idContravention',
	component: ContraventionComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'infractionReferentiel-listeVehiculeContraventions-contravention',
		title: 'infraction.contravention.title',
		typeDroit: TypeDroit.VEHICULE_CONTRAVENTION,
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'ContratCRM/listeContratsCRM',
	component: ContratCRMListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'commerceReferentiel-listeContratsCRM',
		typeDroit: TypeDroit.ADMIN_CONTRAT_CRM,
		isChild: true
	}
},{
	path: 'ContratCRM/loadContratCRM/:idContrat',
	component: ContratCRMComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'commerceReferentiel-listeContratsCRM-loadContratCRM',
		typeDroit: TypeDroit.ADMIN_CONTRAT_CRM,
		title: 'contratCRM.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Chart/listeCharts',
	component: ChartListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeCharts',
		typeDroit: TypeDroit.ADMIN_CHART
	}
},{
	path: 'Chart/loadChart/:idChart',
	component: ChartComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeCharts-chart',
		title: 'chart.title',
		typeDroit: TypeDroit.ADMIN_CHART,
		isHistory: true,
		isChild: true
	}
},{
	path: 'CompteService/listeComptes',
	component: CompteServiceListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeComptesService',
		typeDroit: TypeDroit.ADMIN_COMPTE_SERVICE
	}
},{
	path: 'CompteService/loadCompteService/:idCompteService',
	component: CompteServiceComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeComptesService-compteService',
		typeDroit: TypeDroit.ADMIN_COMPTE_SERVICE,
		title: 'compteService.title',
		isChild: true,
		isHistory: true
	}
},{
	path: 'Tenant/listeTenants',
	component: TenantListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeTenants',
		typeDroit: [TypeDroit.ADMIN_TENANT,TypeDroit.ADMIN_ENVIRONNEMENT,TypeDroit.ADMIN_TENANT_AUTORISATION]
	}
},{
	path: 'Tenant/:idTenant/:idContrat',
	component: TenantComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeTenants-tenant',
		title: 'tenant.title',
		typeDroit: [TypeDroit.ADMIN_TENANT,TypeDroit.ADMIN_TENANT_AUTORISATION],
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'Calendrier/listeCalendriers',
	component: CalendrierListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeCalendriers',
		typeDroit: TypeDroit.ADMIN_CALENDRIER
	}
},{
	path: 'Calendrier/loadCalendrier/:idCalendrier',
	component: CalendrierComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeCalendriers-calendrier',
		title: 'calendrier.title',
		typeDroit: TypeDroit.ADMIN_CALENDRIER,
		isChild: true,
		isHistory: true
	}
},{
	path: 'DataCustomization/listeDataCustomizations',
	component: DataCustomizationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'dataCustomization-listeDataCustomizations',
		typeDroit: TypeDroit.DATA_CUSTOMIZATION_ENTITE
	}
},{
	path: 'DataCustomization/loadDataCustomization/:idDataCustomizationEntite',
	component: DataCustomizationComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'dataCustomization-listeDataCustomizations-loadDataCustomization',
		typeDroit: TypeDroit.DATA_CUSTOMIZATION_ENTITE,
		title: 'dataCustomization.title',
		isChild: true,
		isHistory: false
	}
},{
	path: 'Collaborateur/listeCategories',
	component: UserCategorieListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_UTILISATEUR_CATEGORIE,
		state: 'collaborateurReferentiel-listeCategories',
		isChild: true
	}
},{
	path: 'Societe/listeSocietes',
	component: SocieteListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_SOCIETE,
		state: 'demandeIdentificationReferentiel-listeSocietes',
		typeModule: TypeModule.DEMANDE_IDENTIFICATION_REFERENTIEL,
		isChild: true
	}
},{
	path: 'Amenagement/listeAmenagements',
	component: AmenagementListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.VEHICULE_AMENAGEMENT,
		state: 'materielReferentiel-listeAmenagements',
		typeModule: TypeModule.VEHICULE,
		isChild: true
	}
},{
	path: 'VehiculeCommande/listeDemandesCotation',
	component: DemandeCotationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.DEMANDE_COTATION,
		state: 'vehiculeCommandeReferentiels-listeDemandesCotation',
		typeDashboard: TypeDashboard.DEMANDE_COTATION,
		isChild: true
	}
},{
	path: 'VehiculeCommande/loadDemandeCotation/:idDemandeCotation',
	component: DemandeCotationComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.DEMANDE_COTATION,
		title: 'demandeCotation.title',
		state: 'vehiculeCommandeReferentiels-listeDemandesCotation-detail',
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'VehiculeCommande/loadReponseDemandeCotation/:idReponse',
	component: ReponseDemandeCotationComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeCommandeReferentiels-listeReponsesCotation-detail',
		title: 'reponseDemandeCotation.title.main',
		typeDroit: TypeDroit.REPONSE_COTATION
	}
},{
	path: 'VehiculeConducteur/listeConducteurs',
	component: ConducteurListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeConducteurs',
		typeDroit: TypeDroit.VEHICULE_CONDUCTEUR,
		typeDashboard: TypeDashboard.CONDUCTEUR,
		typeModule: TypeModule.CONDUCTEUR
	}
},{
	path: 'VehiculeConducteur/loadConducteur/:idConducteur',
	component: ConducteurComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeConducteurs-loadConducteur',
		title: 'conducteur.title',
		typeDroit: TypeDroit.VEHICULE_CONDUCTEUR,
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'StructureExport/listeStructuresExport',
	component: StructureExportListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'comptabiliteReferentiel-listeStructuresExport',
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		isChild: true
	}
},{
	path: 'StructureExport/:typeReferentiel/:idStructure',
	component: StructureExportComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'comptabiliteReferentiel-listeStructuresExport-loadStructureExport',
		title: 'structureExport.title',
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		isHistory: true,
		isChild: true
	}
},{
	path: 'Aide/listeArticles',
	component: ArticleListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeArticles',
		typeDroit: TypeDroit.ADMIN_AIDE_ARTICLE,
		typeDashboard: TypeDashboard.ARTICLE,
		typeModule: TypeModule.AIDE
	}
},{
	path: 'Aide/loadArticle/:idArticle',
	component: ArticleComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeArticles-loadArticle',
		title: 'article.title',
		typeDroit: TypeDroit.ADMIN_AIDE_ARTICLE,
		isChild: true,
		isHistory: true,
		isDocument: true
	}
},{
	path: 'Aide/consultArticle/:idArticle',
	component: ArticleConsultationComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeArticles-consultArticle',
		title: 'article.title'
	}
},{
	path: 'DemandeIdentification/listeDemandesIdentification',
	component: DemandeIdentificationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'infractionReferentiel-listeDemandesIdentification',
		typeDroit: TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION,
		typeDashboard: TypeDashboard.DEMANDE_IDENTIFICATION,
		isChild: true
	}
},{
	path: 'DemandeIdentification/loadDemandeIdentification/:idIdentification',
	component: DemandeIdentificationComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'infractionReferentiel-listeDemandesIdentification-identification',
		title: 'demandeIdentification.title',
		typeDroit: TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION,
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'Facture/listeFactures',
	component: FactureListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeFactures',
		typeDroit: TypeDroit.FACTURE,
		typeDashboard: TypeDashboard.FACTURE,
		typeModule: TypeModule.FACTURE
	}
},{
	path: 'Facture/loadFacture/:idFacture',
	component: FactureComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeFactures-load',
		title: 'facture.title',
		typeDroit: TypeDroit.FACTURE,
		typeDashboard: TypeDashboard.FACTURE,
		isEmbeddedDashboard: true,
		isHistory: true,
		isChild: true
	}
},{
	path: 'FactureDetail/listeDetails',
	component: DetailListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeFactureDetails',
		typeDroit: TypeDroit.FACTURE_DETAIL,
		typeDashboard: TypeDashboard.FACTURE_DETAIL
	}
},{
	path: 'FactureDetail/loadDetail/:idDetail',
	component: DetailComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeFactureDetails-detail',
		title: 'facture.detail.title',
		typeDroit: TypeDroit.FACTURE_DETAIL,
		typeDashboard: TypeDashboard.FACTURE_DETAIL,
		isHistory: true,
		isChild: true
	}
},{
	path: 'Entretien/listeEntretiens',
	component: EntretienListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeEntretiens',
		typeDroit: TypeDroit.ENTRETIEN,
		typeDashboard: TypeDashboard.ENTRETIEN,
		typeModule: TypeModule.VEHICULE
	}
},{
	path: 'User/listeUsers',
	component: UserListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeUsers',
		typeDroit: TypeDroit.ADMIN_UTILISATEUR,
		typeDashboard: TypeDashboard.USER,
		typeModule: TypeModule.COLLABORATEUR
	}
},{
	path: 'User/loadUser/:idUser',
	component: UserComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_UTILISATEUR,
		state: 'listeUsers-user',
		title: 'user.title',
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'Groupe/listeGroupes',
	component: GroupeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeGroupes',
		typeDroit: TypeDroit.ADMIN_GROUPE
	}
},{
	path: 'Groupe/loadGroupe/:idGroupe',
	component: GroupeComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeGroupes-groupe',
		title: 'groupe.title',
		typeDroit: TypeDroit.ADMIN_GROUPE,
		isChild: true,
		isHistory: true
	}
},{
	path: 'Comptabilite/listeLotsComptables',
	component: LotComptableListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeLotsComptables',
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		typeDashboard: TypeDashboard.LOT_COMPTABLE
	}
},{
	path: 'Comptabilite/loadLotComptable/:idLotComptable',
	component: LotComptableComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeLotsComptables-lotComptable',
		title: 'comptabilite.lotComptable.title',
		typeDroit: TypeDroit.ADMIN_PLAN_COMPTABLE,
		typeDashboard: TypeDashboard.LOT_COMPTABLE,
		isEmbeddedDashboard: true,
		isChild: true,
		isHistory: true
	}
},{
	path: 'Connecteur/listeConnecteurs',
	component: ConnecteurListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeConnecteurs',
		typeDroit: TypeDroit.CONNECTEUR,
		isChild: true
	}
},{
	path: 'Connecteur/loadConnecteur/:idConnecteur',
	component: ConnecteurComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		title: 'connecteur.title',
		state: 'listeConnecteurs-connecteur',
		typeDroit: TypeDroit.CONNECTEUR,
		isChild: true,
		isHistory: true
	}
},{
	path: 'Connecteur/listeConnecteurTypes',
	component: ConnecteurTypeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'fournisseurReferentiel-listeConnecteurTypes',
		typeDroit: TypeDroit.ADMIN_FOURNISSEUR_INTERFACE,
		isChild: true
	}
},{
	path: 'Sinistre/listeSinistres',
	component: SinistreListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeVehiculeSinistres',
		typeDroit: TypeDroit.VEHICULE_SINISTRE,
		typeDashboard: TypeDashboard.SINISTRE,
		typeModule: TypeModule.VEHICULE
	}
},{
	path: 'Sinistre/loadSinistre/:idSinistre',
	component: SinistreComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		title: 'vehicule.sinistre.title',
		state: 'listeVehiculeSinistres-sinistre',
		typeDroit: TypeDroit.VEHICULE_SINISTRE,
		isChild: true,
		isHistory: true,
		isMailHistory: true
	}
},{
	path: 'Extraction/listeExtractions',
	component: ExtractionListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeExtractions',
		typeDroit: TypeDroit.ADMIN_EXTRACTION
	}
},{
	path: 'Extraction/loadExtraction/:typeReferentiel/:idExtraction',
	component: ExtractionComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeExtractions-extraction',
		title: 'extraction.title',
		typeDroit: TypeDroit.ADMIN_EXTRACTION,
		isChild: true,
		isHistory: true
	}
},{
	path: 'TemplateMail/listeTemplateMails',
	component: TemplateMailListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeTemplateMails',
		typeDroit: TypeDroit.ADMIN_TEMPLATE_MAIL
	}
},{
	path: 'TemplateMail/:typeReferentiel/:idTemplateMail',
	component: TemplateMailComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeTemplateMails-templateMail',
		title: 'templateMail.title',
		typeDroit: TypeDroit.ADMIN_TEMPLATE_MAIL,
		isChild: true,
		isHistory: true
	}
},{
	path: 'EtatLieux/listeEtatsLieux',
	component: EtatLieuxListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeEtatsLieux',
		typeDroit: TypeDroit.ETAT_LIEUX,
		typeModule: TypeModule.ETAT_LIEUX,
		typeDashboard: TypeDashboard.ETAT_LIEUX
	}
},{
	path: 'EtatLieux/etatLieux/:idEtatLieux',
	component: EtatLieuxComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeEtatsLieux-etatLieux',
		title: 'vehicule.etatLieux.title',
		typeDroit: TypeDroit.ETAT_LIEUX,
		isChild: true,
		isHistory: true,
		isMailHistory: true,
		isDocument: true
	}
},{
	path: 'ContratFinancement/listeContratsFinancement',
	component: ContratFinancementListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeVehiculeContratsFinancement',
		typeDroit: TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,
		typeDashboard: TypeDashboard.FINANCEMENT,
		typeModule: TypeModule.VEHICULE
	}
},{
	path: 'ContratFinancement/loadContratFinancement/:idContrat',
	component: ContratFinancementComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeVehiculeContratsFinancement-loadContratFinancement',
		title: 'contrat.financement.title',
		typeDroit: TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,
		typeDashboard: TypeDashboard.FINANCEMENT,
		isChild: true,
		isHistory: true,
		isMailHistory: true,
		isEmbeddedDashboard: true
	}
},{
	path: 'VehiculeReleveEnergie/listeReleveEnergies',
	component: ReleveEnergieListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeVehiculeReleveEnergie',
		typeDroit: TypeDroit.VEHICULE_RELEVE_ENERGIE,
		typeDashboard: TypeDashboard.VEHICULE_RELEVE_ENERGIE,
		typeModule: TypeModule.VEHICULE,
		isChild: true
	}
},{
	path: 'VehiculeCompteur/listeCompteurs',
	component: ReleveCompteurListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeVehiculeCompteur',
		typeDroit: TypeDroit.VEHICULE_COMPTEUR,
		typeDashboard: TypeDashboard.VEHICULE_COMPTEUR,
		typeModule: TypeModule.VEHICULE,
		isChild: true
	}
},{
	path: 'Vehicule/listeVehicules',
	component: VehiculeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeVehicules',
		typeDroit: TypeDroit.ADMIN_VEHICULE,
		typeDashboard: TypeDashboard.VEHICULE,
		typeModule: TypeModule.VEHICULE
	}
},{
	path: 'Vehicule/loadVehicule/:idVehicule',
	component: VehiculeComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeVehicules-loadVehicule',
		title: 'vehicule.title',
		typeDroit: TypeDroit.ADMIN_VEHICULE,
		typeDashboard: TypeDashboard.VEHICULE,
		isChild: true,
		isHistory: true,
		isMailHistory: true,
		isEmbeddedDashboard: true
	}
},{
	path: 'Sgi/listeLots',
	component: SgiLotListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'sgi-listeLots',
		typeDroit: TypeDroit.SGI_LOT,
		typeDashboard: TypeDashboard.SGI_LOT
	}
},{
	path: 'Workflow/listeMotifs',
	component: MotifListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'workflowReferentiel-listeMotifs',
		typeDroit: TypeDroit.ADMIN_WORKFLOW_MOTIF
	}
},{
	path: 'Workflow/listeWorkflows',
	component: WorkflowListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeWorkflows',
		typeDroit: TypeDroit.ADMIN_WORKFLOW
	}
},{
	path: 'Workflow/loadWorkflow/:idWorkflow',
	component: WorkflowComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeWorkflows-workflow',
		title: 'workflow.title',
		typeDroit: TypeDroit.ADMIN_WORKFLOW,
		isChild: true,
		isHistory: true
	}
},{
	path: 'Vehicule/listeControlesReglementaires',
	component: ControleReglementaireListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeControlesReglementaires',
		typeDroit: TypeDroit.CONTROLE_REGLEMENTAIRE,
		isChild: true
	}
},{
	path: 'Vehicule/loadControleReglementaire/:idControleReglementaire',
	component: ControleReglementaireComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'vehiculeReferentiel-listeControlesReglementaires-loadControleReglementaire',
		title: 'vehicule.controleReglementaire.title',
		typeDroit: TypeDroit.CONTROLE_REGLEMENTAIRE,
		isChild: true,
		isHistory: true
	}
},{
	path: 'VehiculeAffectation/listeAffectations',
	component: AffectationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeAffectations',
		typeDroit: [TypeDroit.ADMIN_VEHICULE,TypeDroit.VEHICULE_CONDUCTEUR],
		typeDashboard: TypeDashboard.VEHICULE_AFFECTATION,
		typeModule: TypeModule.VEHICULE,
		isChild: true
	}
},{
	path: 'Analytique/listeServices',
	component: ServiceListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeServices',
		typeDroit: TypeDroit.ADMIN_ANALYTIQUE_SERVICE,
		typeModule: TypeModule.ANALYTIQUE
	}
},{
	path: 'Analytique/loadService/:idService',
	component: ServiceComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeServices-service',
		title: (serviceComponent: any,translateService: TranslateService) => translateService.instant(`service.type.${serviceComponent.service?.type || 'SERVICE'}`),
		typeDroit: TypeDroit.ADMIN_ANALYTIQUE_SERVICE,
		isHistory: true,
		isChild: true
	}
},{
	path: 'Validation/listeObjets/:typeListe',
	component: ValidationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeObjetsValidation'
	}
},{
	path: 'Validation/listeObjets/:typeListe/:typeEntite',
	component: ValidationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeObjetsValidationTypeEntite'
	}
},{
	path: 'Upgrade/listeUpgrades',
	component: UpgradeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_UPGRADER,
		state: 'listeUpgrades'
	}
},{
	path: 'PersonalData',
	component: PersonalDataComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: TypeDroit.ADMIN_PERSONAL_DATA,
		state: 'personalData',
		title: 'personalData.title'
	}
},{
	path: 'Planning/:typePlanning',
	component: PlanningComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typeDroit: [TypeDroit.ADMIN_VEHICULE,TypeDroit.VEHICULE_CONDUCTEUR],
		state: 'planning'
	}
},{
	path: 'RegleAutomatique/listeReglesAutomatiques',
	component: RegleAutomatiqueListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeReglesAutomatiques',
		typeDroit: TypeDroit.REGLE_AUTOMATIQUE
	}
},{
	path: 'RegleAutomatique/loadRegleAutomatique/:idRegleAutomatique',
	component: RegleAutomatiqueComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeReglesAutomatiques-regleAutomatique',
		title: 'regleAutomatique.title',
		typeDroit: TypeDroit.REGLE_AUTOMATIQUE,
		isHistory: true,
		isChild: true
	}
},{
	path: 'TenantAutorisation/listeAutorisations',
	component: AutorisationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeTenantAutorisations',
		typeDroit: TypeDroit.ADMIN_TENANT_AUTORISATION
	}
},{
	path: 'Conversation/listeConversations',
	component: ConversationListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'listeConversations',
		typeDroit: TypeDroit.ADMIN_CONVERSATION
	}
},{
	path: 'Access/Forbidden',
	component: AccessForbiddenComponent,
	data: {
		state: 'access-forbidden',
		title: 'accessForbidden.title'
	}
},{
	path: 'Test',
	component: TestComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'test'
	}
},{
	path: '',
	component: DashboardComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'dashboard',
		title: 'dashboard.title'
	}
},{
	path: '**',
	component: DashboardComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		state: 'dashboard',
		title: 'dashboard.title'
	}
}];