<form #detailAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>vehicule.etatLieuxTemplate.detail.addDetail.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': typeEquipement.invalid }" *ngIf="type == 'TYPE_EQUIPEMENT'">
			<label translate>vehicule.etatLieuxTemplate.detail.addDetail.typeEquipement</label>
			<div>
				<selectpicker name="typeEquipement" #typeEquipement="ngModel" [(ngModel)]="detail.typeEquipement" required>
					<mat-option *ngFor="let type of listeTypesEquipement" [value]="type.code">{{ type.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': typeAmenagement.invalid }" *ngIf="type == 'TYPE_AMENAGEMENT'">
			<label translate>vehicule.etatLieuxTemplate.detail.addDetail.typeAmenagement</label>
			<div>
				<autocomplete name="typeAmenagement" #typeAmenagement="ngModel" type="typeAmenagement" [(ngModel)]="detail.typeAmenagement" [filter]="{ listeExcludedIds: listeExcludedIdsTypeAmenagement }" required></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }" *ngIf="type == 'AUTRE'">
			<label translate>vehicule.etatLieuxTemplate.detail.addDetail.libelle</label>
			<div>
				<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="detail.libelle" maxlength="300" required/>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="detailAddForm.invalid" (click)="saveDetail()">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>