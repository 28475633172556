import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { BaremeTaxeListItemComponent } from './bareme-taxe-list-item.component';
import { BaremeTaxeListComponent } from './bareme-taxe-list.component';
import { BaremeTaxeService } from './bareme-taxe.service';
import { BaremeTaxeEditComponent } from './bareme-taxe-edit.component';
import { BaremeTaxeComponent } from './bareme-taxe.component';
import { BaremeTaxeTrancheListComponent } from './bareme-taxe-tranche-list.component';

@NgModule({
	imports: [ShareModule],
	declarations: [BaremeTaxeListComponent,BaremeTaxeListItemComponent,BaremeTaxeEditComponent,BaremeTaxeComponent,BaremeTaxeTrancheListComponent],
	providers: [BaremeTaxeService]
})
export class BaremeTaxeModule { }