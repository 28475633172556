<div class="detail-item">
	<span>{{ getLibelle() }}</span>
	<div>
		<div class="checkbox" [tooltip]="'vehicule.etatLieuxTemplate.detail.sections.STATIQUE.visible' | translate" *ngIf="isEdition">
			<label>
				<input [disabled]="!isEdition" type="checkbox" name="verification" #verification="ngModel" [(ngModel)]="detail.visible" (ngModelChange)="onDetailChange()"/>
				<i class="input-circle-helper visible"></i>
			</label>
		</div>
		<div class="checkbox" [ngClass]="{ 'disabled': !detail.visible }" [tooltip]="'vehicule.etatLieuxTemplate.detail.sections.STATIQUE.verification' | translate">
			<label>
				<input [disabled]="!detail.visible" type="checkbox" name="verification" #verification="ngModel" [(ngModel)]="detail.verificationObligatoire"/>
				<i class="input-circle-helper verification"></i>
			</label>
		</div>
		<div class="checkbox" [ngClass]="{ 'disabled': !detail.visible }" [tooltip]="'vehicule.etatLieuxTemplate.detail.sections.STATIQUE.photo' | translate">
			<label>
				<input [disabled]="!detail.visible" type="checkbox" name="photo" #photo="ngModel" [(ngModel)]="detail.photoObligatoire"/>
				<i class="input-circle-helper camera"></i>
			</label>
		</div>
		<div class="checkbox" [ngClass]="{ 'disabled': !detail.visible }" [tooltip]="'vehicule.etatLieuxTemplate.detail.sections.STATIQUE.remarque' | translate">
			<label>
				<input [disabled]="!detail.visible" type="checkbox" name="remarque" #remarque="ngModel" [(ngModel)]="detail.remarqueObligatoire"/>
				<i class="input-circle-helper remarque"></i>
			</label>
		</div>
	</div>
</div>