import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';

import { Filter,ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/domain/common/http/list-result';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { ListItem } from 'src/app/domain/common/list-view/list-item';
import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';
import { AutocompleteOptions } from './../autocomplete/autocomplete';

/**
 * Service de gestion des listes
 */
@Injectable()
export class ListViewService<T> {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private layoutService: LayoutService,private autocompleteService: AutocompleteService) {

	}

	/**
	 * Chargement des données
	 */
	public loadData(liste: ListView<any,any>,numPage?: number): Observable<Page<T>> {
		let listeFilter: Array<Filter>;
		let searchSpec: any;
		let uri: string;

		//Récupération de l'uri à contacter
		uri = typeof liste.uri == 'function' ? liste.uri() : liste.uri;

		//Génération de la liste des filtres
		listeFilter = this.generateListeFilters({
			defaultSearch: liste.defaultSearch,
			isSearchExtended: liste.isSearchExtended,
			listeStaticFilters: typeof liste.listeStaticFilters == 'function' ? liste.listeStaticFilters() : liste.listeStaticFilters,
			listeFilters: liste.listeSelectedFilters,
			mapDefaultSearchEncryptedFields: liste.mapDefaultSearchEncryptedFields
		});

		//Définition de la recherche
		searchSpec = {
			numPage: typeof numPage != 'undefined' ? numPage : liste.numPage,
			defaultOrder: liste.defaultOrder,
			nbObjetsParPage: liste.nbObjetsParPage || 25,
			listeFilter,
			extraData: liste.extraData,
			listeExtraData: liste.listeExtraData?.()
		};

		//Chargement des données si nécessaire
		return uri ? this.http.post<Page<T>>(environment.baseUrl + uri,searchSpec).pipe(
			map((page: Page<T>) => ({ ...page,searchSpec }))
		) : of({ content: null,searchSpec });
	}

	/**
	 * Génération de la liste des filtres
	 */
	public generateListeFilters({ defaultSearch,isSearchExtended,listeStaticFilters,listeFilters,mapDefaultSearchEncryptedFields }: { defaultSearch: string,isSearchExtended: boolean,listeStaticFilters: Array<Filter>,listeFilters: Array<Filter>,mapDefaultSearchEncryptedFields: { [clef: string]: string }}) {
		//Définition de la liste des filtres
		return [...(listeStaticFilters || []),...listeFilters].map(f => ({
			clef: f.clef || (f.type != TypeFilter.AUTOCOMPLETE ? defaultSearch : ''),
			type: f.type || TypeFilter[TypeFilter.STRING],
			typeComparaison: f.typeComparaison || f.type == TypeFilter.STRING && f.listeValues && TypeComparaison[TypeComparaison.EQUAL] || f.type == TypeFilter.AUTOCOMPLETE && f.listeObjects && TypeComparaison[TypeComparaison.IN] || TypeComparaison[TypeComparaison.LIKE],
			valeur: f.valeur || null,
			listeObjects: f.listeObjects,
			dateDebut: f.dateDebut,
			dateFin: f.dateFin,
			min: f.min,
			max: f.max,
			autocomplete: f.autocomplete,
			isSearchExtended: f.isSearchExtended,
			mapEncryptedFields: f.mapEncryptedFields || !f.clef && mapDefaultSearchEncryptedFields || null,
			dateOptions: f.dateOptions
		})).map(f => {
			let dateDebut: moment.Moment;
			let dateFin: moment.Moment;

			//Vérification du type
			if (f.type == TypeFilter[TypeFilter.STRING] && ![TypeComparaison[TypeComparaison.EQUAL],TypeComparaison[TypeComparaison.NOT_EQUALS]].includes(f.typeComparaison) && typeof f.valeur == 'string') {
				//Normalisation de la valeur
				if (f.valeur && !f.valeur.endsWith('*') && !f.valeur.endsWith('%'))
					//Ajout du pourcentage final
					f.valeur += '*';

				//Vérification de la recherche étendue
				if (f.valeur && !f.valeur.startsWith('*') && !f.valeur.startsWith('%') && (isSearchExtended || f.isSearchExtended))
					//Ajout du pourcentage initial
					f.valeur = '*' + f.valeur;

				//Vérification d'un pourcentage au début
				if (f.valeur && f.valeur.startsWith('%'))
					//Remplacement du pourcentage
					f.valeur = f.valeur.replace(/%/g,'*');
			} else if (f.type == TypeFilter.AUTOCOMPLETE) {
				//Définition de la clef
				f.clef = `${f.clef ? f.clef + '.' : ''}${f.autocomplete.options.getKeyFieldName()}`;

				//Vérification de la liste d'objets
				if (f.listeObjects)
					//Définition des objets
					f.listeObjects = f.listeObjects.map(valeur => valeur[f.autocomplete.options.getKeyFieldName()]);
				else
					//Définition de la valeur
					f.valeur = f.valeur[f.autocomplete.options.getKeyFieldName()];

				//Définition du type
				f.type = f.listeObjects || Number.isFinite(f.valeur) ? TypeFilter.LONG : TypeFilter.STRING;

				//Définition du type de comparaison
				f.typeComparaison = f.typeComparaison != TypeComparaison.LIKE ? f.typeComparaison : TypeComparaison.EQUAL;

				//Suppression de l'autocomplete
				delete f.autocomplete;

				//Suppression de la recherche étendue
				delete f.isSearchExtended;
			} else if (f.type == TypeFilter.DATE && f.dateDebut) {
				//Récupération des dates du filtre dans le fuseau adapté au type de stockage (date ou timestamp)
				dateDebut = f.dateOptions?.isStoreDate ? moment.utc(f.dateDebut) : moment(f.dateDebut);
				dateFin = f.dateOptions?.isStoreDate ? moment.utc(f.dateFin) : moment(f.dateFin);

				//Vérification du type de comparaison
				if ([TypeComparaison.LESS_EQUAL,TypeComparaison.GREATER,TypeComparaison.GREATER_OR_NULL].includes(f.typeComparaison))
					//Mise à jour de la date de début
					f.dateDebut = dateDebut.endOf(['datetime','time'].includes(f.dateOptions?.format) ? 'minute' : 'day').toDate();

				//Vérification du type de comparaison
				if (f.typeComparaison == TypeComparaison.EQUAL) {
					//Modification du type de comparaison
					f.typeComparaison = TypeComparaison.BETWEEN;

					//Définition de la date de fin
					f.dateFin = dateDebut.endOf(['datetime','time'].includes(f.dateOptions?.format) ? 'minute' : 'day').toDate();
				} else if (f.typeComparaison == TypeComparaison.BETWEEN)
					//Mise à jour de la date de fin
					f.dateFin = dateFin.endOf(['datetime','time'].includes(f.dateOptions?.format) ? 'minute' : 'day').toDate();

				//Suppression des options de date
				delete f.dateOptions;
			}

			return f;
		});
	}

	/**
	 * Enregistrement des filtres de la liste
	 */
	public saveListeFilters(liste: ListView<any,any>) {
		let stateName: string;

		//Récupération du nom de la route
		stateName = this.layoutService.getExtendedRouteData()?.state;

		//Vérification de la route
		if (stateName) {
			//Vérification de la présence de filtres
			if (liste.listeSelectedFilters?.length || liste.extraData) {
				//Mémorisation des filtres de la liste
				sessionStorage.setItem(`savedSearch.${stateName}`,JSON.stringify({
					listeSelectedFilters: liste.listeSelectedFilters.map(f => ({ ...f,listeValues: [] })),
					isSearchExtended: liste.isSearchExtended,
					extraData: liste.extraData
				}));
			} else
				//Suppression de la mémorisation
				sessionStorage.removeItem(`savedSearch.${stateName}`);
		}
	}

	/**
	 * Chargement des filtres mémorisés de la liste
	 */
	public loadListeFilters(liste: ListView<any,any>) {
		let stateName: string;
		let savedSearch: any;

		//Récupération du nom de la route
		stateName = this.layoutService.getExtendedRouteData()?.state;

		//Vérification de la route
		if (stateName) {
			//Récupération des filtres enregistrés
			savedSearch = JSON.parse(sessionStorage.getItem(`savedSearch.${stateName}`));

			//Mise à jour de la liste
			liste.listeSelectedFilters = savedSearch?.listeSelectedFilters || [];
			liste.isSearchExtended = savedSearch?.isSearchExtended || false;
			liste.extraData = savedSearch?.extraData || null;

			//Retrait des filtres non persistants
			liste.listeSelectedFilters = liste.listeSelectedFilters.filter(f => !f.isKeptForSelector || liste.selectedSelector && f.isKeptForSelector == liste.selectedSelector);

			//Parcours des filtres de type autocomplete
			liste.listeSelectedFilters.filter(f => f.type == TypeFilter.AUTOCOMPLETE).forEach(f => {
				let defaultAutocompleteOptions: AutocompleteOptions;

				//Récupération des options d'autocomplete par défaut pour le type
				defaultAutocompleteOptions = this.autocompleteService.getOptionsForType(f.autocomplete.type);

				//Rétablissement de la fonction de récupération de la clé (perdue lors de la sérialisation)
				f.autocomplete.options.getKeyFieldName = defaultAutocompleteOptions.getKeyFieldName;
			});
		}
	}

	/**
	 *	Fabrication de l'action de masse
	 */
	public getActionMasse(liste: ListView<any,any>): ActionMasse {
		let listeFilter: Array<Filter>;

		//Génération de la liste des filtres
		listeFilter = this.generateListeFilters({
			defaultSearch: liste.defaultSearch,
			isSearchExtended: liste.isSearchExtended,
			listeStaticFilters: typeof liste.listeStaticFilters == 'function' ? liste.listeStaticFilters() : liste.listeStaticFilters,
			listeFilters: liste.listeSelectedFilters,
			mapDefaultSearchEncryptedFields: liste.mapDefaultSearchEncryptedFields
		});

		//Retour de l'action de masse issue de la sélection
		return {
			typeActionMasse: liste.typeActionMasse,
			searchSpec: { listeFilter,extraData: liste.extraData },
			listeIdObjects: liste.data.content.filter((item: ListItem) => item.isSelected).map(item => item[liste.getKeyFieldName()]),
			nbSelected: liste.nbSelectedItems,
			firstIdObject: liste.typeActionMasse == 'FULL' && liste.data.content[0][liste.getKeyFieldName()] || liste.data.content.filter((item: ListItem) => item.isSelected).map(item => item[liste.getKeyFieldName()])[0]
		};
	}
}