import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { mapEntites } from './bareme-taxe.entites';
import { BaremeTaxeService } from './bareme-taxe.service';

@Component({
	templateUrl: './bareme-taxe-list-item.component.html'
})
export class BaremeTaxeListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Type de barème de taxe **/
	protected typeBareme: { code: string,libelle: string,avatar: string };

	/**
	 * Constructeur
	 */
	constructor(private baremeTaxeService: BaremeTaxeService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Définition du type de barème
		this.typeBareme = this.baremeTaxeService?.getListeTypesBaremeTaxe()?.find(item => item.code === this.data?.typeBareme);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteBaremeTaxe() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.baremeTaxeService.deleteBaremeTaxe(this.data))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.fiscalite.BaremeTaxe',this.data.idBaremeTaxe);
	}
}