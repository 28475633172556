<div class="container" *ngIf="devise">
	<form #deviseForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="!(null | right:'creation') || typeReferentiel == 'STANDARD' && !rightService.isRoot()">
			<div class="card focus">
				<div class="card-body">
					<div class="form-group" *ngIf="devise.deviseOrigine">
						<label translate>devise.personnalisee.libelle</label>
						<div>
							<mat-checkbox name="custom" #custom="ngModel" [(ngModel)]="devise.custom"><span translate>devise.personnalisee.description</span></mat-checkbox>
						</div>
					</div>
					<fieldset [disabled]="typeReferentiel == 'LOCAL' && devise.deviseOrigine && !devise.custom">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
									<label translate>devise.libelle</label>
									<div>
										<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="devise.libelle" required/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': code.invalid }">
									<label translate>devise.code</label>
									<div class="half">
										<input type="text" name="code" #code="ngModel" class="form-control" [(ngModel)]="devise.code" required maxlength="3"/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': numeroIso.invalid }">
									<label translate>devise.numeroIso</label>
									<div class="half">
										<input type="text" name="numeroIso" #numeroIso="ngModel" class="form-control" [(ngModel)]="devise.numeroIso" nNumber required/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': nbDecimales.invalid }">
									<label translate>devise.nbDecimales</label>
									<div class="half">
										<input type="text" name="nbDecimales" class="form-control" [(ngModel)]="devise.nbDecimales" #nbDecimales="ngModel" required nNumber [min]="0" [noZero]="true"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label translate>devise.actif.item</label>
									<div>
										<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="devise.actif"><span translate>devise.actif.description</span></mat-checkbox>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		</fieldset>

		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteDevise();" *ngIf="devise?.idDevise && (null | right:'suppression') && (typeReferentiel == 'LOCAL' || rightService.isRoot())">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveDevise();" *ngIf="(null | right:'creation') && (typeReferentiel != 'STANDARD' || rightService.isRoot())" [disabled]="deviseForm.invalid || typeReferentiel == 'LOCAL' && devise.deviseOrigine && !devise.custom && !isCustom">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button *ngIf="devise?.idDevise" [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>