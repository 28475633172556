<div class="card-padding">
	<div class="row">
		<div class="col-md-6 p-l-40 p-t-40">
			<div class="p-b-10">
				<h5 translate>notification.selection.destinataire.principaux</h5>
			</div>
			<div class="p-b-20 d-flex align-items-center" *ngFor="let destinataire of (listeDestinataires | filterBy:{ type: 'PRINCIPAL' })">
				<mat-checkbox [(ngModel)]="destinataire.selected" [disabled]="selectedMail.destinatairesNonModifiables"><span>{{ destinataire.libelle }}</span></mat-checkbox>
				<icon *ngIf="destinataire.check" class="m-l-10 clickable icon-hc-1-dot-6x" [name]="destinataire.check.withoutError ? 'check_circle' : 'warning'" [ngClass]="{ 'c-success': destinataire.check.withoutError,'c-warning': !destinataire.check.withoutError }" (click)="showDestinataireCheckDetail(destinataire)"></icon>
			</div>
			<div *ngIf="!selectedMail.destinatairesNonModifiables">
				<a [routerLink]="[]" class="clickable" (click)="addNewDestinataire('PRINCIPAL')"><icon class="icon-hc-lg pull-left" name="add"></icon><span class="p-l-10" translate>notification.selection.actions.addDestinataire</span></a>
			</div>
			<div *ngIf="selectedMail.destinatairesNonModifiables && (listeDestinataires | filterBy:{ type: 'PRINCIPAL' }).length == 0" class="p-t-10" translate>common.aucun</div>
		</div>

		<div class="col-md-6 p-l-40 p-t-40">
			<div class="p-b-10">
				<h5 translate>notification.selection.destinataire.copie</h5>
			</div>
			<div class="p-b-20 d-flex" *ngFor="let destinataire of (listeDestinataires | filterBy:{ type: 'COPIE' })">
				<mat-checkbox [(ngModel)]="destinataire.selected" [disabled]="selectedMail.destinatairesNonModifiables"><span>{{ destinataire.libelle }}</span></mat-checkbox>
				<icon *ngIf="destinataire.check" class="m-l-10 clickable icon-hc-1-dot-6x" [name]="destinataire.check.withoutError ? 'check_circle' : 'warning'" [ngClass]="{ 'c-success': destinataire.check.withoutError,'c-warning': !destinataire.check.withoutError }" (click)="showDestinataireCheckDetail(destinataire)"></icon>
			</div>
			<div *ngIf="!selectedMail.destinatairesNonModifiables">
				<a [routerLink]="[]" class="clickable" (click)="addNewDestinataire('COPIE')"><icon class="icon-hc-lg pull-left" name="add"></icon><span class="p-l-10" translate>notification.selection.actions.addDestinataire</span></a>
			</div>
			<div *ngIf="selectedMail.destinatairesNonModifiables && (listeDestinataires | filterBy:{ type: 'COPIE' }).length == 0" class="p-t-10" translate>common.aucun</div>
		</div>
	</div>
</div>