import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { EtatLieuxTemplateService } from './etat-lieux-template.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'etat-lieux-template-edit',
	templateUrl: './etat-lieux-template-edit.component.html'
})
export class EtatLieuxTemplateEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() template: any;

	/** Suppression du template **/
	@Input() deleteTemplate: () => void;

	/** Liste des types d'affectation **/
	public listeTypesAffectation: Array<{ code: string,libelle: string }> = [];

	/** Configuration de l'éditeur de texte **/
	summernoteConfig: any;

	/**
	 * Constructeur
	 */
	constructor(private etatLieuxTemplateService: EtatLieuxTemplateService,private toastrService: ToastrService,private translateService: TranslateService,private rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la liste des types d'affectation
		this.listeTypesAffectation = this.etatLieuxTemplateService.getListeTypesAffectation();

		//Configuration des éditeurs de texte
		this.summernoteConfig = {
			height: 200,
			disableDragAndDrop: true,
			fontNames: ['Arial','Comic Sans MS','Courier New','Helvetica','Times New Roman','Verdana','Century Gothic','Calibri'],
			toolbar: [
				['style',['bold','italic','underline','clear','strikethrough']],
				['fontsize',['color','fontsize','height']],
				['fontname',['fontname']],
				['para',['ul','ol','paragraph']],
				['table',['table']],
				['insert',['link']],
				(this.rightService.isRoot() || this.rightService.isRoot(true)) && ['view',['codeview']] || null
			]
		};
	}

	/**
	 * Enregistrement du template
	 */
	saveTemplate() {
		//Enregistrement du template
		this.etatLieuxTemplateService.saveTemplate(this.template).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du template
					this.template = result.data.template;

					//Mise à jour du template et fermeture du formulaire
					this.close(this.template);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Vérification de l'état (actif et défaut)
					if (result.data.doublon & Math.pow(2,2))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.actif'),this.translateService.instant('actions.doublon.defaut'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}