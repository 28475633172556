import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter, switchMap } from 'rxjs';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { EtatLieuxTemplateService } from './etat-lieux-template.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	selector: 'etat-lieux-template-list-item',
	templateUrl: './etat-lieux-template-list-item.component.html'
})
export class EtatLieuxTemplateListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private etatLieuxTemplateService: EtatLieuxTemplateService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Suppression de l'objet
	 */
	deleteTemplate() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.etatLieuxTemplateService.deleteTemplate(this.data))
		).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'éxecution
					this.onObjectChange(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.etatLieux.EtatLieuxTemplate',this.data.idEtatLieuxTemplate);
	}

	/**
	 * Duplication de l'objet
	 */
	duplicateTemplate() {
		//Duplication de l'objet
		this.etatLieuxTemplateService.duplicateTemplate(this.data).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Redirection vers le template dupliqué
					this.layoutService.goToByState('vehiculeReferentiel-listeEtatLieuxTemplates-etatLieuxTemplate',{
						routeParams: {
							idEtatLieuxTemplate: result.data.template.idEtatLieuxTemplate
						}
					});
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}
