import { AfterViewInit,Component,ComponentRef,Input,ViewChild,ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Step } from 'src/app/domain/common/stepper/step';

@Component({
	selector: 'stepper',
	exportAs: 'stepper',
	templateUrl: './stepper.component.html'
})
export class StepperComponent implements AfterViewInit {
	/** Liste des étapes **/
	@Input() listeSteps: Array<Step>;

	/** Orientation du stepper **/
	@Input() vertical: boolean = false;

	/** Etape pré-selectionnée **/
	@Input() selectedType?: string;

	/** Présence d'indicateurs **/
	@Input() isWithIndicators?: boolean;

	/** Récepteur de composant **/
	@ViewChild('holder',{ read: ViewContainerRef }) holder: ViewContainerRef;

	/** Etape sélectionnée **/
	selectedStep: Step = null;

	/** Composant courant **/
	componentRef: ComponentRef<any>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) { }

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Initialisation de l'étape sélectionnée
		this.selectStepByType(this.selectedType);
	}

	/**
	 * Sélection de l'étape via son type
	 */
	public selectStepByType(type: string) {
		let step: Step;

		//Recherche de l'onglet
		step = this.listeSteps?.find?.(s => s.type === type);

		//Sélection de l'étape trouvée (ou de la première à défaut)
		this.selectStep(step || this.listeSteps?.[0]);
	}

	/**
	 * Sélection de l'étape
	 */
	public selectStep(step: Step) {
		//Mise à jour de l'étape sélectionnée
		this.selectedStep = step;

		//Retrait du composant courant
		this.holder.clear();

		//Vérification de l'étape
		if (this.selectedStep?.component) {
			//Création du composant
			this.componentRef = this.holder.createComponent(this.selectedStep.component);

			//Définition des données du composant
			Object.assign(this.componentRef.instance,step.retrieveComponentData?.() || {});
		}
	}

	/**
	 * Récupération de la liste des étapes visibles
	 */
	public getListeVisibleSteps(listeSteps: Array<Step>): Array<Step> {
		//Retour de la liste des étapes visibles
		return listeSteps?.filter(step => !step.isVisible || step.isVisible?.());
	}

	/**
	 * Récupération de l'étape sélectionnée
	 */
	public getSelectedStep: () => Step = () => this.selectedStep;

	/**
	 * Récupération de l'instance du composant courant
	 */
	public getComponentInstance: () => any = () => this.componentRef?.instance;

	/**
	 * Récupération du libellé d'une étape
	 */
	public getLibelleForStep(step: Step) {
		//Récupération du libellé
		return step.libelle instanceof Function ? step.libelle() : this.translateService.instant(step.libelle);
	}
}