<form #dashboardChartCustomForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>dashboard.custom.title</h4>
	</div>
	<div class="modal-body dashboard-custom-container">
		<!-- Gestion du titre -->
		<div class="section">
			<h4 translate>dashboard.custom.generalites</h4>
			<a class="action" [routerLink]="[]" (click)="mapToggles.title = !mapToggles.title">
				<icon [name]="mapToggles.title ? 'chevron_right' : 'expand_more'"></icon>
			</a>
		</div>
		<div *ngIf="mapToggles.title">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label translate *ngIf="!dashboardChart.customLibelle?.length">dashboard.custom.titre</label>
						<label translate [translateParams]="{ titreOrigine: dashboardChart.chart.libelle }" *ngIf="dashboardChart.customLibelle?.length">dashboard.custom.titrePersonnalise</label>
						<div>
							<input type="text" name="customLibelle" #customLibelle="ngModel" class="form-control" [placeholder]="dashboardChart.chart.libelle" [(ngModel)]="dashboardChart.customLibelle" [disabled]="typeAffichage == 'FILTRE_ONLY'" maxlength="300"/>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label translate>dashboard.custom.entite</label>
						<div class="half">
							<span>{{ translateEntityCode(dashboardChart.chart.extraction.entity.split('.').pop(-1)) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="typeAffichage != 'FILTRE_ONLY'">
			<!-- Gestion des couleurs -->
			<ng-container *ngIf="isColorCustomizable()">
				<div class="section">
					<h4 translate>dashboard.custom.color.title</h4>
					<a class="action" [routerLink]="[]" (click)="mapToggles.color = !mapToggles.color">
						<icon [name]="mapToggles.color ? 'chevron_right' : 'expand_more'"></icon>
					</a>
				</div>
				<ng-container *ngIf="mapToggles.color">
					<div class="rule">
						<ul class="rule-detail" *ngIf="dashboardChart.chart.firstType == 'KPI'">
							<li class="header">
								<div class="actions dropdown pull-right">
									<a [routerLink]="[]" data-toggle="dropdown">
										<icon name="more_vert"></icon>
									</a>
									<ul class="dropdown-menu dropdown-menu-right">
										<li (click)="addCustomColor()"><a [routerLink]="[]" translate>rule.actions.ajouterRule</a></li>
									</ul>
								</div>
								<mat-button-toggle-group>
									<mat-button-toggle [checked]="true">
										<span translate>rule.condition</span>
									</mat-button-toggle>
								</mat-button-toggle-group>
							</li>
							<li class="content">
								<ul class="rule-list">
									<li *ngFor="let color of listeCustomColors; let index = index">
										<dashboard-chart-customizer-color [color]="color" (onRemove)="removeCustomColor(index)"></dashboard-chart-customizer-color>
									</li>
									<li *ngIf="!listeCustomColors?.length">
										<div class="rule-item hoverable">
											<a [routerLink]="[]" (click)="initListeCustomColors()" translate>dashboard.custom.color.actions.ajouterCouleur</a>
										</div>
									</li>
									<li class="header">
										<mat-button-toggle-group>
											<mat-button-toggle [checked]="true">
												<span translate>dashboard.custom.color.defaut</span>
											</mat-button-toggle>
										</mat-button-toggle-group>
									</li>
									<li>
										<dashboard-chart-customizer-color [isDefault]="true" [color]="defaultColor" *ngIf="defaultColor"></dashboard-chart-customizer-color>
									</li>
								</ul>
							</li>
						</ul>
						<div class="color-picker-field">
							<dashboard-chart-customizer-color [isDefault]="true" [color]="defaultColor" *ngIf="defaultColor && (dashboardChart.chart.firstType == 'BAR' || dashboardChart.chart.firstType == 'LINE')"></dashboard-chart-customizer-color>
						</div>
					</div>
				</ng-container>
			</ng-container>

			<!-- Gestion des seuils min/max -->
			<ng-container *ngIf="dashboardChart?.chart.listeDefinitions[0].seuil">
				<div class="section">
					<h4 translate>dashboard.custom.seuil.title</h4>
					<a class="action" [routerLink]="[]" (click)="mapToggles.seuil = !mapToggles.seuil">
						<icon [name]="mapToggles.seuil ? 'chevron_right' : 'expand_more'"></icon>
					</a>
				</div>
				<div class="" *ngIf="mapToggles.seuil">
					<div class="form-group" [ngClass]="{ 'has-error': seuilMin.invalid }">
						<label translate>dashboard.custom.seuil.min</label>
						<div class="half">
							<input type="text" name="seuilMin" #seuilMin="ngModel" class="form-control" [(ngModel)]="dashboardChart.min" [nNumber]="2" [max]="dashboardChart.max"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': seuilMax.invalid }">
						<label translate>dashboard.custom.seuil.max</label>
						<div class="half">
							<input type="text" name="seuilMax" #seuilMax="ngModel" class="form-control" [(ngModel)]="dashboardChart.max" [nNumber]="2" [min]="dashboardChart.min"/>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-container>

		<!-- Gestion des filtres standards -->
		<ng-container *ngIf="isLoaded && (typeAffichage == 'FILTRE_ONLY' || dashboardChart.chart.extraction.rule?.listeDetails?.length)">
			<div class="section">
				<h4 translate>dashboard.custom.filtresStandards.title</h4>
				<a class="action" [routerLink]="[]" (click)="mapToggles.filtreRoot = !mapToggles.filtreRoot">
					<icon [name]="mapToggles.filtreRoot ? 'chevron_right' : 'expand_more'"></icon>
				</a>
			</div>
			<div *ngIf="mapToggles.filtreRoot">
				<rule *ngIf="dashboardChart.chart.extraction.rule?.listeDetails?.length" [entite]="dashboardChart.entityName" [rule]="dashboardChart.chart.extraction.rule" [isEdition]="false" [isModeLight]="true"></rule>
				<div class="text-center p-10" *ngIf="!dashboardChart.chart.extraction.rule?.listeDetails?.length" translate>dashboard.custom.filtresStandards.empty</div>
			</div>
		</ng-container>

		<!-- Gestion des filtres spécifiques -->
		<ng-container *ngIf="isLoaded && dashboardChart?.rule && (typeAffichage == 'FILTRE_ONLY' || dashboardChart?.chart?.listeDefinitions?.[0]?.condition)">
			<div class="section">
				<h4 translate>dashboard.custom.filtresPersonnalises</h4>
				<a class="action" [routerLink]="[]" *ngIf="typeAffichage != 'FILTRE_ONLY'" (click)="mapToggles.filtre = !mapToggles.filtre">
					<icon [name]="mapToggles.filtre ? 'chevron_right' : 'expand_more'"></icon>
				</a>
			</div>
			<div *ngIf="mapToggles.filtre">
				<rule [entite]="dashboardChart.entityName" [rule]="dashboardChart.rule" [isEdition]="true" [isModeLight]="true"></rule>
			</div>
		</ng-container>

		<ng-container *ngIf="typeAffichage != 'FILTRE_ONLY'">
			<!-- Options supplémentaires (interactions - notifications) -->
			<ng-container *ngIf="dashboard?.entity && dashboardChart?.chart?.firstType != 'KPI' && dashboardChart?.chart?.listeDefinitions?.[0]?.interaction || dashboardChart?.chart?.firstType == 'KPI' && dashboardChart?.chart?.listeDefinitions?.[0]?.notification">
				<div class="section">
					<h4 translate>dashboard.custom.options.title</h4>
					<a class="action" [routerLink]="[]" (click)="mapToggles.options = !mapToggles.options">
						<icon [name]="mapToggles.seuil ? 'chevron_right' : 'expand_more'"></icon>
					</a>
				</div>
				<ng-container *ngIf="mapToggles.options">
					<ng-container *ngIf="dashboard?.entity && dashboardChart?.chart?.firstType != 'KPI' && dashboardChart?.chart?.listeDefinitions?.[0]?.interaction">
						<div class="form-group">
							<label>
								<span translate>dashboard.custom.options.intergraphique.info</span>
								<icon name="info_outline" class="m-l-5" [tooltip]="'dashboard.custom.options.intergraphique.description' | translate"></icon>
							</label>
							<div>
								<mat-button-toggle-group name="interGraphique" [(ngModel)]="dashboardChart.interGraphique">
									<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
									<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
								</mat-button-toggle-group>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="dashboardChart?.chart?.listeDefinitions?.[0]?.notification">
						<div class="form-group">
							<label>
								<span translate>dashboard.custom.options.notification.info</span>
								<icon name="info_outline" class="m-l-5" [tooltip]="'dashboard.custom.options.notification.description' | translate"></icon>
							</label>
							<div>
								<mat-button-toggle-group name="notification" [(ngModel)]="dashboardChart.notification">
									<mat-button-toggle [value]="dashboardChart.notification || {}"><span translate>common.oui</span></mat-button-toggle>
									<mat-button-toggle [value]="null" checked><span translate>common.non</span></mat-button-toggle>
								</mat-button-toggle-group>
							</div>
						</div>
						<ng-container class="row" *ngIf="dashboardChart.notification">
							<div class="rule">
								<ul class="rule-detail">
									<li class="header">
										<mat-button-toggle-group>
											<mat-button-toggle checked="true">
												<span translate>rule.condition</span>
											</mat-button-toggle>
										</mat-button-toggle-group>
									</li>
									<li class="content">
										<ul class="rule-list">
											<li>
												<div class="rule-item hoverable">
													<fieldset>
														<div class="field">
															<span translate>dashboard.custom.color.siValeur</span>
														</div>

														<div class="operateur">
															<div class="form-group" [ngClass]="{ 'has-error': typeComparaisonNotificationField.invalid }">
																<selectpicker name="typeComparaisonNotification" #typeComparaisonNotificationField="ngModel" [(ngModel)]="dashboardChart.notification.typeComparaison" required>
																	<mat-option *ngFor="let typeComparaison of listeTypeComparaisons" [value]="typeComparaison"><span [translate]="'liste.filterOperator.female.' + typeComparaison"></span></mat-option>
																</selectpicker>
															</div>
														</div>

														<div class="value">
															<div *ngIf="dashboardChart?.notification?.typeComparaison == TypeComparaison.BETWEEN" class="form-group " [ngClass]="{ 'has-error': notificationMin?.invalid || notificationMax?.invalid }">
																<div class="between">
																	<input type="text" name="notificationMin" #notificationMin="ngModel" class="form-control" [(ngModel)]="dashboardChart.notification.min" [nNumber]="2" [max]="dashboardChart.notification.max" [disabled]="!dashboardChart.notification.typeComparaison" [placeholder]="'common.minimum' | translate" required/>
																	<div class="bold" translate>common.et</div>
																	<input type="text" name="notificationMax" #notificationMax="ngModel" class="form-control" [(ngModel)]="dashboardChart.notification.max" [nNumber]="2" [min]="dashboardChart.notification.min" [disabled]="!dashboardChart.notification.typeComparaison" [placeholder]="'common.maximum' | translate" required/>
																</div>
															</div>
															<div *ngIf="dashboardChart?.notification?.typeComparaison != TypeComparaison.BETWEEN" class="form-group" [ngClass]="{ 'has-error': notificationMin?.invalid }">
																<div>
																	<input type="text" name="notificationMin" #notificationMin="ngModel" class="form-control" [(ngModel)]="dashboardChart.notification.min" [nNumber]="2" [disabled]="!dashboardChart.notification?.typeComparaison" required/>
																</div>
															</div>
														</div>
													</fieldset>
												</div>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</ng-container>
						<div class="form-group" [ngClass]="{ 'has-error': scheduler.invalid }" *ngIf="dashboardChart.notification">
							<label translate>dashboard.custom.options.planification</label>
							<div>
								<scheduler name="scheduler" #scheduler="ngModel" [(ngModel)]="dashboardChart.notification.scheduler" required></scheduler>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>

			<!-- Gestion du nombre de valeurs à afficher -->
			<div *ngIf="isNbValuesCustomizable()">
				<div class="section">
					<h4 translate>dashboard.custom.nbValues.title</h4>
					<a class="action" [routerLink]="[]" (click)="mapToggles.nbValues = !mapToggles.nbValues">
						<icon [name]="mapToggles.nbValues ? 'chevron_right' : 'expand_more'"></icon>
					</a>
				</div>
				<div *ngIf="mapToggles.nbValues">
					<label translate>dashboard.custom.nbValues.label</label>
					<span class="m-l-10">
						<mat-button-toggle-group name="nbValues" [value]="dashboardChart.nbValues" (change)="dashboardChart.nbValues = $event.value">
							<mat-button-toggle *ngFor="let value of [6,12,24]" [value]="value">{{ value }}</mat-button-toggle>
						</mat-button-toggle-group>
					</span>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveDashboardChartCustomization()" [disabled]="dashboardChartCustomForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>