import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { EtatLieuxTemplateEditComponent } from './etat-lieux-template-edit.component';
import { EtatLieuxTemplateListItemComponent } from './etat-lieux-template-list-item.component';
import { EtatLieuxTemplateListComponent } from './etat-lieux-template-list.component';
import { EtatLieuxTemplateComponent } from './etat-lieux-template.component';
import { EtatLieuxTemplateService } from './etat-lieux-template.service';
import { EtatLieuxTemplateEtatVehiculeComponent } from './etat-lieux-template-etat-vehicule.component';
import { EtatLieuxTemplateDetailComponent } from './etat-lieux-template-detail.component';
import { EtatLieuxTemplateDetailLineComponent } from './etat-lieux-template-detail-line.component';
import { EtatLieuxTemplateDetailAddComponent } from './etat-lieux-template-detail-add.component';

@NgModule({
	imports: [ShareModule],
	declarations: [EtatLieuxTemplateListComponent,EtatLieuxTemplateListItemComponent,EtatLieuxTemplateComponent,EtatLieuxTemplateEditComponent,EtatLieuxTemplateEtatVehiculeComponent,EtatLieuxTemplateDetailComponent,EtatLieuxTemplateDetailLineComponent,EtatLieuxTemplateDetailAddComponent],
	providers: [EtatLieuxTemplateService]
})
export class EtatLieuxTemplateModule { }
