<form #baremeTaxeTrancheForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="m-t-20">
	<div class="card" [ngClass]="{ 'detail': isEdition }">
		<div class="card-header">
			<h2 class="card-title" translate>fiscalite.baremeTaxe.tranche.liste.title</h2>
		</div>
		<div class="card-body">
			<fieldset [disabled]="!isEdition" class="listview">
				<div class="lv-body">
					<div class="text-center p-10" *ngIf="!listeTranches?.length">
						<h5 translate>fiscalite.baremeTaxe.tranche.empty</h5>
					</div>
					<div class="lv-item media hoverable" *ngFor="let tranche of listeTranches; let index = index;">
						<div class="row" *ngIf="tranche.key">
							<div class="p-l-0 col-sm-3">
								<div class="form-group" [ngClass]="{ 'has-error': isEdition && debut.invalid }">
									<label translate>fiscalite.baremeTaxe.tranche.debut</label>
									<div>
										<input type="text" data-test-id="debut" name="debut-{{ tranche.key }}" #debut="ngModel" class="form-control" [(ngModel)]="tranche.debut" nNumber="0" [max]="tranche.fin" [required]="isEdition" [disabled]="index > 0"/>
									</div>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="form-group" [ngClass]="{ 'has-error': isEdition && fin.invalid }">
									<label translate>fiscalite.baremeTaxe.tranche.fin</label>
									<div>
										<input type="text" data-test-id="debut" name="fin-{{ tranche.key }}" #fin="ngModel" class="form-control" [(ngModel)]="tranche.fin" [min]="tranche.debut" nNumber="0" (ngModelChange)="onFinChange(tranche,index)"/>
									</div>
								</div>
							</div>
							<div class="p-r-0 col-sm-5">
								<div class="form-group" [ngClass]="{ 'has-error': isEdition && montant.invalid }">
									<label translate>fiscalite.baremeTaxe.tranche.montant</label>
									<div class="input-group">
										<input type="text" data-test-id="montant" name="montant-{{ tranche.key }}" #montant="ngModel" class="form-control" [(ngModel)]="tranche.montant" nNumber="2" [required]="isEdition"/>
										<span class="input-group-addon no-border">EUR</span>
									</div>
								</div>
							</div>
						</div>
						<div class="lv-actions actions hovered-item" *ngIf="isEdition">
							<a [routerLink]="[]" (click)="deleteTranche(index); $event.stopPropagation();"><icon name="delete" class="icon-hc-1x"></icon></a>
						</div>
					</div>
					<div class="p-t-20 p-b-20" *ngIf="isEdition">
						<div class="lv-item media text-center lv-item-add" matRipple (click)="baremeTaxeTrancheForm.valid && addTranche();">
							<h5>
								<a [routerLink]="[]" [ngClass]="{ 'c-gray': baremeTaxeTrancheForm.invalid }" translate>fiscalite.baremeTaxe.actions.ajouterTranche</a>
							</h5>
						</div>
					</div>
				</div>
			</fieldset>

			<div *ngIf="isEdition">
				<span class="pull-right">
					<button mat-raised-button color="primary" (click)="saveListeTranches()" *ngIf="null | right:'creation'" [disabled]="baremeTaxeTrancheForm.invalid">
						<span translate>actions.enregistrer</span>
					</button>
				</span>
				<button mat-button *ngIf="baremeTaxe?.idBaremeTaxe" [formDetectChanges]="{ doAction: close,isDirty: baremeTaxeTrancheForm.dirty }">
					<span translate>actions.fermer</span>
				</button>
			</div>
		</div>
	</div>
</form>