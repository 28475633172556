<div class="container-fluid">
	<form #etatLieuxTemplateForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>vehicule.etatLieuxTemplate.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="template.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>vehicule.etatLieuxTemplate.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="template.reference" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeAffectation.invalid }" *ngIf="listeTypesAffectation?.length">
								<label translate>vehicule.etatLieuxTemplate.typeAffectation.item</label>
								<div>
									<selectpicker name="typeAffectation" #typeAffectation="ngModel" [(ngModel)]="template.typeAffectation" required>
										<mat-option *ngFor="let type of listeTypesAffectation" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group">
								<label translate>vehicule.etatLieuxTemplate.defaut.item</label>
								<div>
									<mat-checkbox name="etatLieuxAutomatique" [(ngModel)]="template.defaut"><span translate>vehicule.etatLieuxTemplate.defaut.description</span></mat-checkbox>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>
									<span translate>vehicule.etatLieuxTemplate.actif.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.etatLieuxTemplate.actif.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" [(ngModel)]="template.actif" [disabled]="!template.etatVehicule"><span translate>vehicule.etatLieuxTemplate.actif.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>vehicule.etatLieuxTemplate.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" [(ngModel)]="template.remarque" rows="3"></textarea>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="col-md-12 p-0">
						<div class="template-bloc">
							<div class="form-group">
								<label>
									<span translate>vehicule.etatLieuxTemplate.instructions.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.etatLieuxTemplate.instructions.info' | translate"></icon>
								</label>
								<div>
									<summernote [config]="summernoteConfig" name="templateContent" [(ngModel)]="template.instructions"></summernote>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary"(click)="deleteTemplate()" *ngIf="template?.idEtatLieuxTemplate && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="etatLieuxTemplateForm.invalid" (click)="saveTemplate()" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(template?.idEtatLieuxTemplate ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="template.idEtatLieuxTemplate" [formDetectChanges]="{ doAction: close,isDirty: etatLieuxTemplateForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>