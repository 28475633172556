import { Component,Input,OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { EtatLieuxTemplateService } from './etat-lieux-template.service';

@Component({
	templateUrl: './etat-lieux-template-etat-vehicule.component.html'
})
export class EtatLieuxTemplateEtatVehiculeComponent extends PageContentItem implements OnInit {
	/** Template d'état des lieux **/
	@Input() template: any;

	/** Enregistrement du template **/
	@Input() saveTemplate: (template: any,close?: Function) => void;

	/** Mode 'Edition' **/
	@Input() isEdition: boolean = false;

	/** Liste des types de masques intérieurs **/
	public listeTypesMasqueInterieur: Array<{ code: string,libelle: string }>;

	/** Liste des types de masques extérieurs **/
	public listeTypesMasqueExterieur: Array<{ code: string,libelle: string }>;

	/** Contrôle de l'état du véhicule **/
	public etatVehicule: any = {};

	/**
	 * Constructeur
	 */
	constructor(private etatLieuxTemplateService: EtatLieuxTemplateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie du template
		this.template = cloneDeep(this.template);

		//Création du contrôle de l'état du véhicule
		this.etatVehicule = this.template.etatVehicule || this.isEdition && {
			exterieur: false,
			interieur: false,
			exterieurPhotoObligatoire: true,
			interieurPhotoObligatoire: true,
			typeMasqueInterieur: 'QUATRE_ZONES',
			typeMasqueExterieur: 'HUIT_ZONES'
		} || {
			exterieur: true,
			interieur: false
		};

		//Récupération de la liste des types de masques intérieurs
		this.listeTypesMasqueInterieur = this.etatLieuxTemplateService.getListeTypesMasqueInterieur();

		//Récupération de la liste des types de masques extérieurs
		this.listeTypesMasqueExterieur = this.etatLieuxTemplateService.getListeTypesMasqueExterieur();
	}


	/**
	 * Enregistrement du template
	 */
	public saveEtatVehicule() {
		//Enregistrement du template
		this.saveTemplate({
			...this.template,
			etatVehicule: this.etatVehicule
		});

		//Fermeture de la fenêtre
		this.close({ ...this.template,etatVehicule: this.etatVehicule });

		//Passage en mode consultation
		this.isEdition = false;
	}
}