import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CoursListItemComponent } from './cours-list-item.component';

@Component({
	templateUrl: './cours-list.component.html'
})
export class CoursListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,CoursListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,CoursListItemComponent>({
			uri: `/controller/Devise/filtreCours`,
			title: this.translateService.instant('devise.cours.liste.title'),
			component: CoursListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'deviseObject',
				title: this.translateService.instant('search.devise'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'devise',
					filter: { referentiel: TypeReferentiel.STANDARD }
				},
				isDefault: true
			},{
				clef: 'deviseContrepartieObject',
				title: this.translateService.instant('search.deviseContrepartie'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'devise',
					filter: { referentiel: TypeReferentiel.STANDARD }
				},
			},{
				clef: 'dateEchange',
				title: this.translateService.instant('search.dateChange'),
				type: TypeFilter.DATE,
				dateOptions: {
					format: 'date',
					isStoreDate: true
				}
			}],
			defaultOrder: 'devise,deviseContrepartie,-dateEchange',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(i => !i.idCours) && this.liste.addItem({
					isDisplayed: true,
					source: 'BCE'
				});
			})
		});
	}
}