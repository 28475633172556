import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ContratFinancementListItemComponent } from './contrat-financement-list-item.component';
import { mapEntites } from './contrat-financement.entites';
import { Result } from 'src/app/domain/common/http/result';
import { ContratFinancementService } from './contrat-financement.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './contrat-financement-list.component.html'
})
export class ContratFinancementListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ContratFinancementListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/** Résumé **/
	resume: { nbTous: number,nbEnCours: number,nbEchus: number } = {
		nbTous: 0,
		nbEnCours: 0,
		nbEchus: 0
	};

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public dashboardService: DashboardService,private rightService: RightService,private layoutService: LayoutService,public extractionService: ExtractionService,private contratFinancementService: ContratFinancementService,public connecteurService: ConnecteurService) {}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ContratFinancementListItemComponent>({
			uri: () => `/controller/Vehicule/filtreContratsFinancement${!this.liste?.selectedSelector || this.liste?.selectedSelector == 'TOUS' ? '' : '/' + this.liste.selectedSelector}`,
			title: this.translateService.instant('contrat.financement.liste.title'),
			component: ContratFinancementListItemComponent,
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				isDefault: true
			},{
				clef: 'vehicule',
				title: this.translateService.instant('search.immatriculation'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule',
					filter: 'VEHICULE_FONCTION_SERVICE'
				},
				isDefault: true
			},{
				clef: 'type',
				title: this.translateService.instant('search.typeFinancement'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'typeFinancement'
				}
			},{
				clef: 'dateDebut',
				type: TypeFilter.DATE,
				dateOptions: {
					format: 'date',
					isStoreDate: true
				}
			},{
				clef: 'dateFin',
				type: TypeFilter.DATE,
				dateOptions: {
					format: 'date',
					isStoreDate: true
				}
			},{
				clef: '*lastLoiRoulage.reference',
				title: this.translateService.instant('search.referenceAvenant')
			},{
				clef: '*lastLoiRoulage.duree',
				title: this.translateService.instant('search.duree'),
				type: TypeFilter.DECIMAL
			},{
				clef: '*lastLoiRoulage.distance',
				title: this.translateService.instant('search.distance'),
				type: TypeFilter.DECIMAL
			},{
				clef: '*lastLoiRoulage.temps',
				title: this.translateService.instant('search.temps'),
				type: TypeFilter.DECIMAL
			}],
			listeSelectors: [{
				code: 'TOUS',
				libelle: this.translateService.instant('contrat.financement.typeListe.TOUS'),
				count: () => this.resume.nbTous
			},{
				code: 'EN_COURS',
				libelle: this.translateService.instant('contrat.financement.typeListe.EN_COURS'),
				count: () => this.resume.nbEnCours
			},{
				code: 'ECHU',
				libelle: this.translateService.instant('contrat.financement.typeListe.ECHU'),
				count: () => this.resume.nbEchus
			}],
			selectedSelector: 'TOUS',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => this.layoutService.goToByUrl('/ContratFinancement/loadContratFinancement/0'),
			getKeyFieldName: () => 'idContrat',
			onRefresh: (liste,result) => {
				//Chargement du résumé
				this.contratFinancementService.loadResume(liste.selectedSelector == 'EN_COURS' ? 'ECHU' : 'EN_COURS',result.searchSpec).subscribe({
					next: (resultData: Result) => {
						//Vérification du type de liste
						if (liste.selectedSelector === 'EN_COURS') {
							//Définition des compteurs
							this.resume.nbEnCours = result?.totalElements || 0;
							this.resume.nbEchus = resultData?.data?.count || 0;
							this.resume.nbTous = this.resume.nbEnCours + this.resume.nbEchus;
						} else if (liste.selectedSelector === 'ECHU') {
							//Définition des compteurs
							this.resume.nbEchus = result?.totalElements || 0;
							this.resume.nbEnCours = resultData?.data?.count || 0;
							this.resume.nbTous = this.resume.nbEnCours + this.resume.nbEchus;
						} else if (liste.selectedSelector === 'TOUS') {
							//Définition des compteurs
							this.resume.nbTous = result?.totalElements || 0;
							this.resume.nbEnCours = resultData?.data?.count || 0;
							this.resume.nbEchus = this.resume.nbTous - this.resume.nbEnCours;
						}
					}
				});
			}
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}