import { Component,Input,OnInit,OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter,switchMap,debounceTime,distinctUntilChanged,takeUntil } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { BusinessDataService } from 'src/app/share/components/entite/business-data/business-data.service';
import { TemplateMailService } from 'src/app/share/components/template-mail/template-mail.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'template-mail-destinataire-edit',
	templateUrl: './template-mail-destinataire-edit.component.html'
})
export class TemplateMailDestinataireEditComponent extends PageContentItem implements OnInit,OnDestroy {
	/** Destinataire **/
	@Input() destinataire: any;

	/** Lecture seule **/
	@Input() isReadOnly: boolean;

	/** Type de source du destinataire **/
	typeSource: 'DYNAMIQUE' | 'EMAIL' | 'COLLABORATEUR' = null;

	/** Liste des types de destinataire **/
	listeTypesDestinataire: Array<any>;

	/** Liste des types de source de destinataire **/
	listeTypesSourceDestinataire: Array<any>;

	/** Notification du changement de l'email saisi **/
	emailChanged: Subject<string> = new Subject<string>();

	/** Notification de la destruction du composant **/
	unsubscribe$: Subject<any> = new Subject<any>();

	/**
	 * Constructeur
	 */
	constructor(private templateMailService: TemplateMailService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,public rightService: RightService,private businessDataService: BusinessDataService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération des types de destinataire
		this.listeTypesDestinataire = this.templateMailService.getListeTypesDestinataire().filter(td => td.code != 'COPIE_CACHEE');

		//Récupération des types de source de destinataire
		this.listeTypesSourceDestinataire = this.templateMailService.getListeTypesSourceDestinataire();

		//Vérification du tenant
		if (!this.rightService.isRoot() || this.destinataire.templateMail.private) {
			//Définition du type de source
			this.typeSource = this.destinataire.idDestinataire && (this.destinataire.destinataireDynamique ? 'DYNAMIQUE' :  this.destinataire.emailStatique ? 'EMAIL' : 'COLLABORATEUR') || null;
		} else {
			//Définition du type de source
			this.typeSource =  'DYNAMIQUE';

			//Restriction de la liste des sources disponibles
			this.listeTypesSourceDestinataire = this.listeTypesSourceDestinataire.filter(ts => ts.code == 'DYNAMIQUE');
		}

		//Temporisation des modifications de l'email
		this.emailChanged.pipe(
			debounceTime(1000),
			distinctUntilChanged(),
			takeUntil(this.unsubscribe$)
		).subscribe(email => {
			//Récupération de l'email avant modification
			const previousEmail = this.destinataire.emailStatique;

			//Mise à jour de l'email du destinataire
			this.destinataire.emailStatique = email;

			//Vérification que le libellé est vide ou identique à la précédente valeur par défaut
			if (email && (!this.destinataire.libelle || this.destinataire.libelle == previousEmail))
				//Pré-remplissage du libellé du destinataire
				this.destinataire.libelle = email;
		});
	}

	/**
	 * Interception d'un changement d'utilisateur
	 */
	onUserStatiqueChange(user: any) {
		//Vérification que le libellé est vide
		if (user && !this.destinataire.libelle)
			//Pré-remplissage du libellé du destinataire
			this.destinataire.libelle = `${user.nom} ${user.prenom} (${user.matricule}${user.societe ? ' - '+user.societe.libelle : ''})`;
	}

	/**
	 * Interception d'un changement d'email
	 */
	onEmailChange(email: string){
		//Notification de la modification
		this.emailChanged.next(email);
	}

	/**
	 * Enregistrement du destinataire
	 */
	saveDestinataire() {
		//Vérification du type de source
		if (this.typeSource == 'DYNAMIQUE') {
			//Vidage des champs inutiles
			delete this.destinataire.emailStatique;
			delete this.destinataire.userStatique;
		} else if (this.typeSource == 'EMAIL') {
			//Vidage des champs inutiles
			delete this.destinataire.destinataireDynamique;
			delete this.destinataire.userStatique;
		} else {
			//Vidage des champs inutiles
			delete this.destinataire.emailStatique;
			delete this.destinataire.destinataireDynamique;
		}

		//Enregistremnt du destinataire
		this.templateMailService.saveDestinataire(this.destinataire).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du destinataire
				this.destinataire = result.data?.destinataire;

				//Fermeture de la fenêtre
				this.close({ destinataire: this.destinataire });
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: this.translateService.instant('actions.doublon.destinataire')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Suppression du destinataire
	 */
	deleteDestinataire() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.templateMailService.deleteDestinataire(this.destinataire))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Fermeture de la fenêtre
				this.close({ destinataire: null });
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Affichage du catalogue des destinataires possibles pour l'entité associée au template de mail
	 */
	showCatalogueDestinataires() {
		//Ouverture de la popup de catalogue des données métier
		this.businessDataService.showBusinessData(this.destinataire.templateMail.entite,{
			typeContext: 'MAIL_DESTINATAIRE'
		}).pipe(filter(item => !!item)).subscribe(item => {
			//Mise à jour du destinataire
			this.destinataire.destinataireDynamique = item.entity.path;

			//Définition des libellés
			this.destinataire.libelleOrigine = item.entity.pathLibelle;
			this.destinataire.libelle = item.entity.pathLibelle;
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Suppression des intercepteurs
		this.unsubscribe$.next(null);
	}
}
