import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { environment } from 'src/environments/environment';
import { TemplateMailPreviewComponent } from './template-mail-preview.component';

/**
 * Service de gestion des templates de mail
 */
@Injectable()
export class TemplateMailService {
	/** Liste des types de templates de mail **/
	private listeTypesMail: Array<{ code: string,libelle: string,reference?: string,entite?: string,scheduling?: boolean }> = [{
		code: 'AUTRE',
		libelle: this.translateService.instant('templateMail.type.AUTRE')
	},{
		code: 'ACTIVATE_ACCOUNT',
		libelle: this.translateService.instant('templateMail.type.ACTIVATE_ACCOUNT'),
		reference: 'activateAccount',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'RESET_PASSWORD',
		libelle: this.translateService.instant('templateMail.type.RESET_PASSWORD'),
		reference: 'resetPassword',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'SEND_ACCESS_TOKEN',
		libelle: this.translateService.instant('templateMail.type.SEND_ACCESS_TOKEN'),
		reference: 'sendAccessToken',
		entite: 'com.notilus.data.user.CompteService'
	},{
		code: 'SAVE_CONTRAT',
		libelle: this.translateService.instant('templateMail.type.SAVE_CONTRAT'),
		reference: 'saveContrat',
		entite: 'com.notilus.data.crm.ContratCRM'
	},{
		code: 'ANONYMIZE_USER',
		libelle: this.translateService.instant('templateMail.type.ANONYMIZE_USER'),
		reference: 'anonymizeUser',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'SEND_ACCOUNT_LOCKED',
		libelle: this.translateService.instant('templateMail.type.SEND_ACCOUNT_LOCKED'),
		reference: 'sendAccountLocked',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'DELETE_TENANT',
		libelle: this.translateService.instant('templateMail.type.DELETE_TENANT'),
		reference: 'deleteTenant',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'SEND_DEMANDE_COTATION',
		libelle: this.translateService.instant('templateMail.type.SEND_DEMANDE_COTATION'),
		reference: 'sendDemandeCotation',
		entite: 'com.notilus.data.vehicule.cotation.ReponseDemandeCotation'
	},{
		code: 'REJECT_REPONSE_COTATION',
		libelle: this.translateService.instant('templateMail.type.REJECT_REPONSE_COTATION'),
		reference: 'rejectReponseCotation',
		entite: 'com.notilus.data.vehicule.cotation.ReponseDemandeCotation'
	},{
		code: 'VALIDATE_REPONSE_COTATION',
		libelle: this.translateService.instant('templateMail.type.VALIDATE_REPONSE_COTATION'),
		reference: 'validateReponseCotation',
		entite: 'com.notilus.data.vehicule.cotation.ReponseDemandeCotation'
	},{
		code: 'SEND_COMMANDE_VEHICULE',
		libelle: this.translateService.instant('templateMail.type.SEND_COMMANDE_VEHICULE'),
		reference: 'sendCommandeVehicule',
		entite: 'com.notilus.data.vehicule.cotation.DemandeVehicule'
	},{
		code: 'SEND_DEMANDE_RELEVE_COMPTEUR',
		libelle: this.translateService.instant('templateMail.type.SEND_DEMANDE_RELEVE_COMPTEUR'),
		reference: 'sendDemandeReleveCompteur',
		entite: 'com.notilus.data.vehicule.Vehicule',
		scheduling: true
	},{
		code: 'SEND_DESIGNATION_CONDUCTEUR',
		libelle: this.translateService.instant('templateMail.type.SEND_DESIGNATION_CONDUCTEUR'),
		reference: 'sendDesignationConducteur',
		entite: 'com.notilus.data.sgi.DemandeIdentification'
	},{
		code: 'CONTACT_ADMINISTRATEUR',
		libelle: this.translateService.instant('templateMail.type.CONTACT_ADMINISTRATEUR'),
		reference: 'contactAdministrateur',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'SEND_CONNECTEUR_FILE',
		libelle: this.translateService.instant('templateMail.type.SEND_CONNECTEUR_FILE'),
		reference: 'sendConnecteurFile',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'SEND_WORKFLOW_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_WORKFLOW_REMINDER'),
		reference: 'sendWorkflowReminder',
		entite: 'com.notilus.data.user.User',
		scheduling: true
	},{
		code: 'SEND_KPI_ALERT',
		libelle: this.translateService.instant('templateMail.type.SEND_KPI_ALERT'),
		reference: 'sendKPIAlert',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'SEND_REPORT',
		libelle: this.translateService.instant('templateMail.type.SEND_REPORT'),
		reference: 'sendReport',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'SEND_AFFECTATION_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_AFFECTATION_REMINDER'),
		reference: 'sendAffectationReminder',
		entite: 'com.notilus.data.vehicule.Affectation',
		scheduling: true
	},{
		code: 'ACTIVATE_USER_MOBILE_ACCOUNT',
		libelle: this.translateService.instant('templateMail.type.ACTIVATE_USER_MOBILE_ACCOUNT'),
		reference: 'activateUserMobileAccount',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'ACTIVATE_DRIVER_MOBILE_ACCOUNT',
		libelle: this.translateService.instant('templateMail.type.ACTIVATE_DRIVER_MOBILE_ACCOUNT'),
		reference: 'activateDriverMobileAccount',
		entite: 'com.notilus.data.vehicule.Conducteur'
	},{
		code: 'SEND_CONTROLE_TECHNIQUE_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_CONTROLE_TECHNIQUE_REMINDER'),
		reference: 'sendControleTechniqueReminder',
		entite: 'com.notilus.data.vehicule.Entretien',
		scheduling: true
	},{
		code: 'SEND_DEMANDE_ATTESTATION_PERMIS_CONDUIRE',
		libelle: this.translateService.instant('templateMail.type.SEND_DEMANDE_ATTESTATION_PERMIS_CONDUIRE'),
		reference: 'sendDemandeAttestationPermisConduire',
		entite: 'com.notilus.data.vehicule.Conducteur',
		scheduling: true
	},{
		code: 'SEND_ENTRETIEN_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_ENTRETIEN_REMINDER'),
		reference: 'sendEntretienReminder',
		entite: 'com.notilus.data.vehicule.Entretien',
		scheduling: true
	},{
		code: 'VALIDATE_OR_REJECT_RESERVATION_VEHICULE',
		libelle: this.translateService.instant('templateMail.type.VALIDATE_OR_REJECT_RESERVATION_VEHICULE'),
		reference: 'validateOrRejectReservationVehicule',
		entite: 'com.notilus.data.vehicule.pool.Reservation'
	},{
		code: 'SEND_RESERVATION_UPDATE',
		libelle: this.translateService.instant('templateMail.type.SEND_RESERVATION_UPDATE'),
		reference: 'sendReservationUpdate',
		entite: 'com.notilus.data.vehicule.Affectation'
	},{
		code: 'SEND_ETAT_LIEUX_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_ETAT_LIEUX_REMINDER'),
		reference: 'sendEtatLieuxReminder',
		entite: 'com.notilus.data.vehicule.etatLieux.EtatLieux',
		scheduling: true
	},{
		code: 'SEND_DEMANDE_VEHICULE_SELECTION_MODELE',
		libelle: this.translateService.instant('templateMail.type.SEND_DEMANDE_VEHICULE_SELECTION_MODELE'),
		reference: 'sendDemandeVehiculeSelectionModele',
		entite: 'com.notilus.data.vehicule.cotation.DemandeVehicule'
	},{
		code: 'SEND_DEMANDE_VEHICULE_MODELE_SELECTED',
		libelle: this.translateService.instant('templateMail.type.SEND_DEMANDE_VEHICULE_MODELE_SELECTED'),
		reference: 'sendDemandeVehiculeModeleSelected',
		entite: 'com.notilus.data.vehicule.cotation.DemandeVehicule'
	},{
		code: 'SEND_TODO_LIST_ITEM_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_TODO_LIST_ITEM_REMINDER'),
		reference: 'sendTodoListItemReminder',
		entite: 'com.notilus.data.user.User'
	},{
		code: 'SEND_ENTRETIEN_CONSTRUCTEUR_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_ENTRETIEN_CONSTRUCTEUR_REMINDER'),
		reference: 'sendEntretienConstructeurReminder',
		entite: 'com.notilus.data.vehicule.Entretien',
		scheduling: true
	},{
		code: 'SEND_CONTROLE_ANTIPOLLUTION_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_CONTROLE_ANTIPOLLUTION_REMINDER'),
		reference: 'sendControleAntiPollutionReminder',
		entite: 'com.notilus.data.vehicule.Entretien',
		scheduling: true
	},{
		code: 'SEND_CONNECTEUR_EXECUTION_REPORT',
		libelle: this.translateService.instant('templateMail.type.SEND_CONNECTEUR_EXECUTION_REPORT'),
		reference: 'sendConnecteurExecutionReport',
		entite: 'com.notilus.data.connecteur.ConnecteurExecution'
	},{
		code: 'SEND_TODO_LIST_ITEM_SHARE',
		libelle: this.translateService.instant('templateMail.type.SEND_TODO_LIST_ITEM_SHARE'),
		reference: 'sendTodoListItemShare',
		entite: 'com.notilus.data.todoList.TodoListItem'
	},{
		code: 'SEND_TENANT_AUTORISATION',
		libelle: this.translateService.instant('templateMail.type.SEND_TENANT_AUTORISATION'),
		reference: 'sendTenantAutorisation',
		entite: 'com.notilus.data.tenant.TenantAutorisation'
	},{
		code: 'SEND_ANONYMISATION_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_ANONYMISATION_REMINDER'),
		reference: 'sendAnonymisationReminder',
		entite: 'com.notilus.data.user.AutomatisationAnonymisation'
	},{
		code: 'SEND_ANONYMISATION_REPORT',
		libelle: this.translateService.instant('templateMail.type.SEND_ANONYMISATION_REPORT'),
		reference: 'sendAnonymisationReport',
		entite: 'com.notilus.data.user.AutomatisationAnonymisation'
	},{
		code: 'SEND_AUTODESIGNATION_CONDUCTEUR',
		libelle: this.translateService.instant('templateMail.type.SEND_AUTODESIGNATION_CONDUCTEUR'),
		reference: 'sendAutoDesignationConducteur',
		entite: 'com.notilus.data.sgi.DemandeIdentification',
		scheduling: true
	},{
		code: 'SEND_SECRET_EXPIRATION_REMINDER',
		libelle: this.translateService.instant('templateMail.type.SEND_SECRET_EXPIRATION_REMINDER'),
		reference: 'sendSecretExpirationReminder',
		entite: 'com.notilus.data.user.User',
		scheduling: false
	}];

	/** Liste des types de destinataires **/
	private listeTypesDestinataire: Array<string> = ['PRINCIPAL','COPIE','COPIE_CACHEE'];

	/** Liste des types de source destinataires **/
	private listeTypesSourceDestinataire: Array<string> = ['DYNAMIQUE','EMAIL','COLLABORATEUR'];

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private http: HttpClient,private translateService: TranslateService) {

	}

	/**
	 * Récupération de la liste des types de mails
	 */
	public getListeTypesMail(): Array<{ code: string,libelle: string,reference?: string,entite?: string,scheduling?: boolean }> {
		//Retour de la liste triée des types de mails
		return [
			this.listeTypesMail[0],
			...sortBy(this.listeTypesMail.slice(1),'libelle')
		];
	}

	/**
	 * Récupération de la liste des types de destinataires
	 */
	public getListeTypesDestinataire(): Array<{ code: string,libelleCourt: string,libelleLong: string }> {
		//Retour de la liste des types de destinataires
		return this.listeTypesDestinataire.map(code => ({
			code,
			libelleCourt: this.translateService.instant(`templateMail.destinataire.type.court.${code}`),
			libelleLong: this.translateService.instant(`templateMail.destinataire.type.long.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de source de destinataires
	 */
	public getListeTypesSourceDestinataire(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de source de destinataires
		return this.listeTypesSourceDestinataire.map(code => ({
			code,
			libelle: this.translateService.instant(`templateMail.destinataire.source.${code}`)
		}));
	}

	/**
	 * Chargement du template de mail
	 */
	public loadTemplateMail(idTemplateMail: number): Observable<Result> {
		//Chargement du template de mail
		return this.http.post<Result>(`${environment.baseUrl}/controller/TemplateMail/loadTemplateMail/${idTemplateMail}`,null);
	}

	/**
	 * Sauvegarde d'un template de mail
	 */
	public saveTemplateMail(templateMail: any,typeReferentiel: TypeReferentiel): Observable<Result> {
		//Sauvegarde du template de mail
		return this.http.put<Result>(`${environment.baseUrl}/controller/TemplateMail/saveTemplateMail/${typeReferentiel}`,templateMail);
	}

	/**
	 * Suppression du template de mail
	 */
	public deleteTemplateMail(typeReferentiel: TypeReferentiel,templateMail: any): Observable<Result> {
		//Suppression du template de mail
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TemplateMail/deleteTemplateMail/${typeReferentiel}/${templateMail.idTemplateMail}`);
	}

	/**
	 * Affichage de la prévisualisation d'un template de mail
	 */
	public previewTemplateMail(templateMail: any,idObjet: number): Observable<any> {
		let bsModalRef: BsModalRef<TemplateMailPreviewComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(TemplateMailPreviewComponent,{
			initialState: {
				templateMail,
				idObjet
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}

	/**
	 * Chargement du contenu de prévisualisation d'un mail
	 */
	public loadPreviewContent(templateMail:any ,idObjet: number): Observable<Result> {
		//Récupération du contenu de la prévisualisation
		return this.http.post<Result>(`${environment.baseUrl}/controller/TemplateMail/previewTemplateMail${idObjet ? '/' + idObjet : ''}`,templateMail);
	}

	/**
	 * Sauvegarde du destinataire
	 */
	public saveDestinataire(destinataire: any): Observable<Result> {
		//Sauvegarde d'un destinataire d'un template de mail
		return this.http.put<Result>(`${environment.baseUrl}/controller/TemplateMail/saveDestinataire`,destinataire);
	}

	/**
	 * Suppression du destinataire
	 */
	public deleteDestinataire(destinataire: any): Observable<Result> {
		//Suppression d'un destinataire d'un template de mail
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TemplateMail/deleteDestinataire/${destinataire.idDestinataire}`);
	}

	/**
	 * Duplication du template de mail
	 */
	public duplicateTemplateMail(templateMail: any): Observable<Result> {
		//Duplication du template de mail
		return this.http.post<Result>(`${environment.baseUrl}/controller/TemplateMail/duplicateTemplateMail/${templateMail.idTemplateMail}`,null);
	}
 }