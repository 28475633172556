import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { ControleReglementaireService } from 'src/app/components/vehicule/controle-reglementaire/controle-reglementaire.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListView } from 'src/app/domain/common/list-view';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EntretienService } from './entretien.service';
import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';

@Component({
	selector: 'entretien-content',
	templateUrl: './entretien-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class EntretienContentComponent implements OnInit {
	/** Elément courant **/
	@Input() entretien: any;

	/** Source **/
	@Input() source: 'ENTRETIEN' | 'VEHICULE' | 'CONDUCTEUR' | 'FACTURE';

	/** Véhicule **/
	@Input() vehicule: any;

	/** Dernier compteur du véhicule **/
	@Input() lastCompteur: any;

	/** Liste **/
	@Input() liste: ListView<any,any>;

	/** Mise à jour du véhicule **/
	@Input() updateVehicule?: (vehicule: any) => void;

	/** Nombre d'interventions **/
	@Input() nbInterventions?: number = 0;

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Date courante **/
	public today = moment().endOf('day');

	/** Utilisateur connecté **/
	public user: User;

	/** Liste des types d'entretien **/
	public listeTypesEntretien: Array<{ code: string,libelle: string }>;

	/** Liste des sous-types **/
	public listeSousTypes: Array<{ code: string,libelle: string }>;

	/** Liste des types d'avis de contrôle **/
	public listeTypesAvisControle: Array<{ code: string,libelle: string }>;

	/** Liste des types de source **/
	public listeTypesSource: Array<string> = ['COLLABORATEUR','FOURNISSEUR'];

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Copie de l'entretien **/
	public savedEntretien: any;

	/**
	 * Constructeur
	 */
	constructor(public entretienService: EntretienService,private layoutService: LayoutService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService
			,public rightService: RightService,public vehiculeService: VehiculeService,private store: Store<AppState>,private messagingService: MessagingService,private controleReglementaireService: ControleReglementaireService,private pluralTranslatePipe: PluralTranslatePipe) {
		//Binding des méthodes
		this.saveEntretien = this.saveEntretien.bind(this);
		this.deleteEntretien = this.deleteEntretien.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie de l'entretien avant modifications
		this.savedEntretien = cloneDeep(this.entretien);

		//Définition des types d'entretien et des sous-types
		this.retrieveListeTypesEntretienAndSousType(this.entretien.vehicule,this.entretien);

		//Récupération de la liste des types d'avis de contrôle
		this.listeTypesAvisControle = this.entretienService.getListeTypesAvisControle();

		//Vérification de l'entretien prévisionnel
		if (this.entretien.previsionnel)
			//Suppression de la date de réalisation
			this.entretien.dateRealisation = null;

		//Vérification de l'absence d'une source
		if (this.entretien.typeSource == null)
			//Définition de la source
			this.entretien.typeSource = this.source == 'FACTURE' ? 'FOURNISSEUR' : 'COLLABORATEUR';

		//Vérification du type de véhicule
		if (this.source == 'VEHICULE' && this.vehicule?.typeVehicule == 'ENGIN') {
			//Filtre sur la liste des types d'entretiens
			this.listeTypesEntretien = this.listeTypesEntretien.filter(typeEntretien => typeEntretien.code == 'COURANT');

			//Définition du seul type d'entretien autorisé pour un engin
			this.entretien.type = 'COURANT';
		}

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}
	/**
	 * Récupération du nombre d'interventions prévues
	 */
	getNbInterventionsPrevues() {
		//Retour du nombre d'interventions prévues
		return this.entretien.listeLinksVehiculePlanEntretienDetail?.filter(link => link.planEntretienDetail && link.statut == 'A_REALISER')?.length || 0;
	}

	/**
	 * Suppression de l'entretien
	 */
	deleteEntretien() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.entretienService.deleteEntretien(this.entretien))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Fermeture du formulaire
				this.close.emit();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Sauvegarde de l'entretien
	 */
	saveEntretien() {
		let messaging$: MessagingObservables;
		let handleResult: (result: Result) => void;
		let isPrevisionnel: boolean;
		let listeDoublons = new Array<string>();

		//Mémorisation de l'indicateur 'Prévisionnel'
		isPrevisionnel = this.entretien.previsionnel;

		//Modification de l'indicateur prévisionnel
		this.entretien.previsionnel = !this.entretien.dateRealisation;

		//Définition de la méthode de traitement du résultat
		handleResult = result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de l'entretien
				Object.assign(this.entretien,result.data?.entretien);

				//Fermeture du formulaire
				this.source != 'VEHICULE' && this.close.emit(result.data?.entretien);
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Vérification de la date de réalisation
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.dateRealisation'));

				//Vérification de la possibilité de créer un controle réglementaire
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.controleReglementairePrevisionnel'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));

				//Réinitialisation de l'indicateur 'Prévisionnel'
				this.entretien.previsionnel = isPrevisionnel;
			}
		};

		//Enregistrement de l'entretien
		(isPrevisionnel && this.entretien.dateRealisation && this.entretien.listeLinksVehiculePlanEntretienDetail?.length > 0 && !this.hasInterventionRealisee() ? this.confirmService.showConfirm(this.translateService.instant('entretien.previsionnel.confirmation'),{ actionColor: 'primary' }) : of(true))
			.subscribe({
				next: (isConfirmed) => {
					//Vérification de la confirmation
					if (isConfirmed) {
						//Vérification de la source
						if (this.source == 'VEHICULE' || this.source == 'CONDUCTEUR') {
							//Enregistrement de l'entretien par websocket
							messaging$ = this.messagingService.init({
								entryPoint: 'controller/Entretien/saveEntretien',
								outputPoint: '/messaging/entretien/saveEntretien/status',
								params: this.entretien,
								method: 'PUT'
							}).onResult({
								next: handleResult
							}).onFinish({
								next: () => {
									//Rechargement du véhicule si nécessaire
									(this.vehicule?.idVehicule && this.source == 'VEHICULE' ? this.vehiculeService.loadVehicule(this.vehicule.idVehicule) : of(null)).subscribe({
										next: result => {
											//Rafraichissement de la liste des entretiens avec remise à zéro des filtres
											this.liste?.refresh?.(true);

											//Vérification du résultat
											if (result) {
												//Mise à jour du véhicule (si nécessaire)
												this.updateVehicule?.(result?.data?.vehicule);

												//Fermeture du formulaire
												this.close.emit(this.entretien);

												//Fermeture des souscriptions
												messaging$.unsubscribe();
											}
										},
										complete: () => {
											//Fermeture des souscriptions
											messaging$.unsubscribe();
										}
									});
								}
							}).onError({
								next: () => {
									//Fermeture des souscriptions
									messaging$.unsubscribe();
								}
							});
						} else {
							//Enregistrement de l'entretien
							this.entretienService.saveEntretien(this.entretien).subscribe(handleResult);
						}
					} else {
						//Réinitialisation de l'indicateur 'Prévisionnel'
						this.entretien.previsionnel = isPrevisionnel;
					}
				}
			})
	}

	/**
	 * Accès au véhicule
	 */
	goToVehicule() {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.entretien.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Interception du changement du véhicule
	 */
	onVehiculeChange(vehicule: any) {
		//Vérification du véhicule et de son type
		if (this.entretien.vehicule?.typeVehicule == 'ENGIN')
			//Définition du type d'entretien
			this.entretien.type = 'COURANT';

		//Calcul des types d'entretien et sous-types disponibles
		this.retrieveListeTypesEntretienAndSousType(vehicule,this.entretien);
	}

	/**
	 * Interception du changement de type d'entretien
	 */
	onTypeChange(typeEntretien: 'CONTROLE_REGLEMENTAIRE' | 'ENTRETIEN' | 'COURANT' | null) {
		//Suppression de la date prévisionnelle
		this.entretien.datePrevisionnelle = null;

		//Remise à zéro du type d'entretien courant
		this.entretien.typeEntretienItem = null;

		//Vérification du type d'entretien
		if (this.listeSousTypes.length && typeEntretien == 'CONTROLE_REGLEMENTAIRE')
			//Mise à jour du sous-type
			this.entretien.sousType = this.listeSousTypes.length == 1 ? this.listeSousTypes[0].code : 'TECHNIQUE';
		else
			//Réinitialisation du sous-type
			this.entretien.sousType = null;
	}

	/**
	 * Récupération du libellé pour l'affichage des révisions
	 */
	getLibelleForListeRevisions(): string {
		let countRealisees: number;
		let countARealiser: number;
		let countReportees: number;
		let libelleRealisees: string;
		let libelleARealiser: string;
		let libelleReportees: string;
		let listeLibelles: Array<string> = [];

		//Comptage des entretiens réalisés ou à réaliser
		countRealisees = this.entretien?.listeLinksVehiculePlanEntretienDetail?.filter(link => link?.statut == 'REALISE')?.length || 0;
		countARealiser = this.entretien?.listeLinksVehiculePlanEntretienDetail?.filter(link => link?.statut == 'A_REALISER' || link.entretien?.idEntretien != this.entretien.idEntretien && link?.statut == 'REPORTE')?.length || 0;
		countReportees = this.entretien?.listeLinksVehiculePlanEntretienDetail?.filter(link => link?.statut == 'REPORTE' && link.entretien?.idEntretien == this.entretien.idEntretien)?.length || 0;

		//Définition des libellés
		libelleRealisees = this.translateService.instant(this.pluralTranslatePipe.transform('entretien.intervention.realisee',countRealisees),{ count: countRealisees });
		libelleARealiser = this.translateService.instant(this.pluralTranslatePipe.transform('entretien.intervention.prevue',countARealiser),{ count: countARealiser });
		libelleReportees = this.translateService.instant(this.pluralTranslatePipe.transform('entretien.intervention.reportee',countReportees),{ count: countReportees });

		//Vérification de l'entretien prévisionnel
		if (this.entretien.previsionnel) {
			//Vérification de la présence de révisions à réaliser
			if (countARealiser > 0 || this.entretien?.listeLinksVehiculePlanEntretienDetail?.length == 0)
				//Ajout des révisions à réaliser
				listeLibelles.push(libelleARealiser)

			//Vérification de la présence de révisions réalisées
			if (countRealisees > 0)
				//Ajout des révisions réalisées
				listeLibelles.push(libelleRealisees)
		} else
			//Ajout des révisions réalisées
			listeLibelles.push(libelleRealisees)

		//Vérification de la présence de révisions reportées
		if (countReportees > 0)
			//Ajout des révisions reportées
			listeLibelles.push(libelleReportees)

		//Retour du résultat
		return listeLibelles.join(', ');
	}

	/**
	 * Affichage de la liste des révisions pour le véhicule
	 */
	showListeInterventions() {
		//Affichage de la liste des révisions pour le véhicule
		this.entretienService.showListeInterventions(this.entretien).subscribe({
			next: listeLinksVehiculePlanEntretienDetail => {
				//Mise à jour du résultat
				this.entretien.listeLinksVehiculePlanEntretienDetail = listeLinksVehiculePlanEntretienDetail;
			}
		});
	}

	/**
	 * Vérification de la présence d'une intervention réalisée
	 */
	hasInterventionRealisee(): boolean {
		//Vérification de la présence d'une intervention réalisée
		return this.entretien?.listeLinksVehiculePlanEntretienDetail?.some(l => l.statut == 'REALISE');
	}

	/**
	 * Vérification de la présence de contre-visites
	 */
	hasContreVisite(): boolean {
		//Vérification de la présence d'une contre-visite
		return this.entretien.typeAvisControle == 'DEFAVORABLE' && this.entretien?.listeContreVisites?.length > 0;
	}

	/**
	 * Interception d'un changement de la date de réalisation
	 */
	onDateRealisationChange(date: Date) {
		//Vérification de la date
		if (date == null) {
			//Suppression de la date limite de contre-visite
			this.entretien.dateLimiteContreVisite = null;

			//Réinitialisation de la contre-visite
			this.entretien.typeAvisControle = null;

			//Réinitialisation de la liste des contre-visites
			this.entretien.listeContreVisites = [];
		} else if (this.entretien.typeAvisControle == 'DEFAVORABLE')
			//Calcul de la date limite de contre-visite
			this.computeDateLimiteForContreVisite(this.entretien.typeAvisControle);
	}

	/**
	 * Affichage de la liste des contre-visites
	 */
	showListeContreVisites() {
		//Affichage de la liste des contres-visites
		this.entretienService.showListeContreVisites(this.entretien).subscribe({
			next: listeContreVisites => {
				//Mise à jour du résultat
				this.entretien.listeContreVisites = listeContreVisites;
			}
		});
	}

	/**
	 * Récupération du libellé pour l'affichage des contre-visites
	 */
	getLibelleForListeContreVisites(listeContreVisites: Array<any> = this.entretien?.listeContreVisites): string {
		let date: Date;
		let libelle: string;

		//Vérification de la présence d'une contre-visite favorable
		if (listeContreVisites?.some((contreVisite: any) => contreVisite.typeAvisControle == 'FAVORABLE')) {
			//Définition de la date
			date = listeContreVisites?.find((contreVisite: any) => contreVisite.typeAvisControle == 'FAVORABLE').dateRealisation;

			//Définition du libellé
			libelle = this.translateService.instant('entretien.contreVisite.libelle.favorable',{ dateRealisation: moment(date).format('LL') });
		} else if (moment(this.entretien?.dateLimiteContreVisite).isSameOrAfter(moment.now())) {
			//Définition du libellé
			libelle = this.translateService.instant('entretien.contreVisite.libelle.defavorable',{ dateLimite: moment(this.entretien.dateLimiteContreVisite).format('LL') });
		} else
			//Définition du libellé
			libelle = this.translateService.instant('entretien.contreVisite.libelle.timeout',{ dateLimite: moment(this.entretien.dateLimiteContreVisite).format('LL') });

		//Retour du résultat
		return libelle;
	}

	/**
	 * Vérification de l'existence d'une contre visite
	 */
	getLibelleAlerteForContreVisite(entretien: any = this.entretien): string {
		//Retour du résultat
		return this.translateService.instant('entretien.contreVisite.alerte.pending.message',{
			dateLimite: moment(entretien?.dateLimiteContreVisite).format('LL'),
			type: this.translateService.instant(entretien.type == 'CONTROLE_REGLEMENTAIRE' ? ('vehicule.controleReglementaire.type.' + entretien.sousType) : ('entretien.type.' + entretien.type))?.toLowerCase()
		})
	}

	/**
	 * Calcul de la date limite de contre-visite
	 */
	computeDateLimiteForContreVisite(typeAvisControle: 'FAVORABLE' | 'DEFAVORABLE') {
		//Définition de la date limite de contre-visite (recalculée dans le backend)
		this.entretien.dateLimiteContreVisite = typeAvisControle == 'DEFAVORABLE' ? moment(this.entretien?.dateRealisation)?.add(2,'M').subtract(1,'d').toDate() : null;
	}

	/**
	 * Récupération de la liste des types d'entretien et des sous-types disponibles en fonction du véhicule
	 */
	retrieveListeTypesEntretienAndSousType(vehicule?: any,entretien: any = this.entretien) {
		//Vérification de la création d'un entretien 'manuel' et de la présence d'un véhicule
		if (!entretien?.idEntretien && !!vehicule?.idVehicule) {
			//Récupération de la liste des sous-types
			this.listeSousTypes = this.controleReglementaireService.getListeTypes().filter(sousType => {
				//Vérification du sous-type
				if (sousType.code == 'TECHNIQUE')
					//Vérification de la date du prochain contrôle technique
					return vehicule.extension?.dateProchainControleTechnique == null;
				else if (sousType.code == 'ANTIPOLLUTION')
					//Vérification de la date du prochain contrôle anti-pollution
					return vehicule.extension?.dateProchainControleAntiPollution == null;
			});

			//Définition de la liste des types d'entretien
			this.listeTypesEntretien = this.entretienService.getListeTypesEntretien().filter(typeEntretien => this.listeSousTypes.length || typeEntretien.code != 'CONTROLE_REGLEMENTAIRE');

			//Vérification du type de l'entretien
			if (entretien?.type == 'CONTROLE_REGLEMENTAIRE') {
				//Vérification de la liste des sous-types
				if (!!this.listeSousTypes?.length || !this.listeSousTypes?.includes(entretien.sousType))
					//Réinitialisation du sous-type
					entretien.sousType = this.listeSousTypes?.length > 0 ? this.listeSousTypes[0]?.code : null;

				//Vérification de la liste des types d'entretien
				if (!this.listeSousTypes?.length)
					//Réinitialisation du type d'entretien
					entretien.type = null;
			}
		} else {
			//Réinitialisation de la liste des types d'entretien
			this.listeTypesEntretien = this.entretienService.getListeTypesEntretien();

			//Réinitialisation de la liste des sous-types
			this.listeSousTypes = this.controleReglementaireService.getListeTypes();
		}
	}
}