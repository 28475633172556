import { Injectable } from '@angular/core';
import { Router,ActivatedRoute,NavigationEnd,ActivatedRouteSnapshot,Data,NavigationBehaviorOptions,Navigation } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { filter,map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { omit } from 'lodash-es';

import { Session } from 'src/app/domain/security/session';
import { AppState } from 'src/app/domain/appstate';
import { UPDATE_TITLE } from 'src/app/reducers/layout';
import { ExtendedRouteData } from 'src/app/domain/common/route/extended-route';
import { routes } from 'src/app/routes';
import { ExtendedRoute } from 'src/app/domain/common/route/extended-route';
import { UPDATE_FIL_ARIANE,UPDATE_ACTIVATED_ROUTE_DATA } from 'src/app/reducers/layout';
import { LOAD_SESSION_USER } from 'src/app/reducers/session';
import { Filter } from 'src/app/domain/common/list-view';
import { ExtraData } from 'src/app/domain/common/list-view/extra-data';
import { EntiteService } from 'src/app/share/components/entite/entite.service';

const ID_LINK_CUSTOM_CSS: string = 'id_link_custom_css';

/**
 * Service de gestion du layout
 */
@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	/** Sujet de changement de route **/
	public routeChangeSubject: Subject<{ state: string,data: ExtendedRouteData }> = new Subject();

	/** Données de la route courante **/
	private extendedRouteData: ExtendedRouteData;

	/** Instance du composant de la route courante **/
	private routeComponentInstance: any;

	/** Route courante **/
	private activatedRouteSnapshot: ActivatedRouteSnapshot;

	/** Ouverture du premier élément de la liste **/
	private isOpenFirstItem: boolean = false;

	/** Liste des routes enregistrées **/
	private listeSavedRoutes: Array<{ state: string,queryParams?: any,paramsToRestore?: any,done: boolean }> = [];

	/** Paramètres mis à jour **/
	private mapUpdatedParams: any = {};

	/**
	 * Constructeur
	 */
	constructor(public router: Router,private store: Store<AppState>,private activatedRoute: ActivatedRoute,private location: Location,private entiteService: EntiteService) { }

	/**
	 * Initialisation
	 */
	init() {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => {
			//Mise à jour du CSS de l'utilisateur
			this.updateCSSForTenant(session?.user?.tenant?.code);
		});

		//Chargement de l'utilisateur en session (si inexistant)
		this.store.dispatch({
			type: LOAD_SESSION_USER
		});

		//Détection du changement de route
		this.router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			map(() => this.router.getCurrentNavigation()),
			filter((navigation: Navigation) => !(navigation?.extras?.info as any)?.isFromReplaceUrl),
			map(() => this.activatedRoute),
			map(route => route.firstChild || route),
			filter(route => route.outlet === 'primary')
		).subscribe(route => {
			let filArianeState: string;
			let data: Data;

			//Récupération des données de la route
			data = route.snapshot.data;

			//Définition du titre
			this.store.dispatch({
				type: UPDATE_TITLE,
				payload: typeof data?.title == 'string' && data?.title || ''
			});

			//Notification du changement de route
			this.routeChangeSubject.next({ state: data?.state,data });

			//Scroll vers le haut de la page
			window.scrollTo(0,0);

			//Définition des paramètres de la route
			this.store.dispatch({
				type: UPDATE_ACTIVATED_ROUTE_DATA,
				payload: data
			});

			//Mise à jour des données de la route
			this.extendedRouteData = data;

			//Mise à jour de la route courante
			this.activatedRouteSnapshot = route.snapshot;

			//Récupération du fil d'ariane
			filArianeState = data?.state?.split('-').slice(0,-1).join('-');

			//Définition du fil d'ariane
			this.store.dispatch({
				type: UPDATE_FIL_ARIANE,
				payload: filArianeState || ''
			});
		});
	}

	/**
	 * Récupération des données de la route courante
	 */
	public getExtendedRouteData(): ExtendedRouteData { return this.extendedRouteData; }

	/**
	 * Mise à jour du CSS du tenant
	 */
	public updateCSSForTenant(codeTenant?: string) {
		let link: HTMLLinkElement;
		let head: HTMLHeadElement;
		let listeLinks: HTMLCollectionOf<HTMLLinkElement>;

		//Suppression de l'ancien lien vers le CSS
		document.getElementById(ID_LINK_CUSTOM_CSS)?.remove();

		//Vérification de la présence du code du tenant
		if (codeTenant) {
			//Recherche de la balise head
			head = document.getElementsByTagName('head')[0];

			//Recherche de tous les liens
			listeLinks = head.getElementsByTagName('link');

			//Initialisation d'un nouveau lien
			link = document.createElement('link');

			//Définition du lien
			link.href = `/controller/Theme/loadCSS/${codeTenant}`;
			link.rel = 'stylesheet';
			link.type = 'text/css';
			link.id = ID_LINK_CUSTOM_CSS;

			//Insertion du lien dans la page
			head.insertBefore(link,head.getElementsByTagName('style')[listeLinks.length]);
		}
	}

	/**
	 * Navigation vers une route identifiée par le nom du state associé
	 */
	public goToByState(state: string,options?: { reloadOnSameUrl?: boolean,savedSearch?: { listeSelectedFilters?: Array<Filter>,extraData?: ExtraData },routeParams?: any,routeData?: any,isOpenFirstItem?: boolean,withGoBack?: boolean,goBackParams?: { queryParams?: any,paramsToRestore?: any },preserveSavedRoutes?: boolean }): void {
		let route: ExtendedRoute;
		let listeRouteItems: Array<string>;
		let listeRouteStaticItems: Array<string>;
		let listeRouteDynamicItems: Array<string>;

		//Recherche de la route associée au state fourni
		route = routes.find(r => r.data?.state == state);

		//Vérification du flag d'enregistrement de la route
		if (options?.withGoBack) {
			//Enregistrement de la route actuelle
			this.listeSavedRoutes.push({
				queryParams: { ...this.activatedRouteSnapshot?.params,...this.mapUpdatedParams,...options?.goBackParams?.queryParams },
				paramsToRestore: { ...options?.goBackParams?.paramsToRestore,...omit(history.state,['navigationId']) },
				state: this.getExtendedRouteData()?.state,
				done: false
			});
		} else if (!options?.preserveSavedRoutes) {
			//Suppression des routes
			this.listeSavedRoutes = [];

			//Suppression des paramètres
			this.mapUpdatedParams = {};
		}

		//Vérification de la présence d'un filtre
		if (options?.savedSearch) {
			//Mémorisation des filtres de la liste
			sessionStorage.setItem(`savedSearch.${state}`,JSON.stringify({
				...options.savedSearch,
				listeSelectedFilters: options.savedSearch?.listeSelectedFilters,
				listeFiltres: options.savedSearch?.listeSelectedFilters,
				extraData: options.savedSearch?.extraData
			}));
		}

		//Définition de l'indicateur d'ouverture du premier élément de la liste
		this.isOpenFirstItem = options?.isOpenFirstItem || false;

		//Vérification de la route
		if (route) {
			//Découpage de la route
			listeRouteItems = route.path.split('/');

			//Récupération des paramètres statiques et dynamiques
			listeRouteStaticItems = listeRouteItems.filter(i => !i.startsWith(':'));
			listeRouteDynamicItems = listeRouteItems.filter(i => i.startsWith(':')).map(i => options?.routeParams?.[i.substring(1)] || (i.startsWith(':id') ? 0 : ''));

			//Vérification de la dernière route parcourue
			if (options?.reloadOnSameUrl && this.router.routerState.snapshot.url.startsWith('/' + listeRouteStaticItems.join('/')))
				//Rechargement de la route
				this.router.navigateByUrl('/').then(() => this.router.navigate(listeRouteDynamicItems?.length ? [listeRouteStaticItems.join('/')].concat(listeRouteDynamicItems) : listeRouteItems,{ queryParams: omit(options?.routeParams,listeRouteItems.filter(i => i.startsWith(':')).map(i => i.substring(1))),state: options?.routeData }));
			else
				//Navigation vers la route identifiée
				this.router.navigate(listeRouteDynamicItems?.length ? [listeRouteStaticItems.join('/')].concat(listeRouteDynamicItems) : listeRouteItems,{ queryParams: omit(options?.routeParams,listeRouteItems.filter(i => i.startsWith(':')).map(i => i.substring(1))),state: options?.routeData });
		}
	}

	/**
	 * Navigation vers une route identifiée par son URL associée
	 */
	public goToByUrl(url: string,extras?: NavigationBehaviorOptions): void {
		let route: ExtendedRoute;

		//Suppression des routes
		this.listeSavedRoutes = [];

		//Suppression des paramètres
		this.mapUpdatedParams = {}

		//Recherche de la route associée à l'URL fournie
		route = routes.find(r => r.path == url.substring(1));

		//Vérification de la route
		if (route)
			//Navigation vers la route identifiée
			this.router.navigateByUrl(`/${route.path}`,extras);
		else
			//Navigation par URL
			this.router.navigateByUrl(url,extras);
	}

	/**
	 * Navigation vers la route précédente
	 */
	public goBack(fallbackState?: string) {
		let savedRoute: { state: string,queryParams?: any,paramsToRestore?: any,done: boolean };

		//Récupération de la dernière route enregitrée
		savedRoute = this.getLastSavedRoute();

		//Vérification de la présence d'une route
		if (savedRoute) {
			//Navigation vers la route (via le state)
			this.goToByState(savedRoute.state,{
				routeParams: savedRoute.queryParams,
				routeData: savedRoute.paramsToRestore,
				reloadOnSameUrl: true
			});
		} else if (fallbackState) {
			//Navigation vers le state fourni
			this.goToByState(fallbackState);
		} else
			//Navigation vers la route précédente
			this.getExtendedRouteData().state?.split('-').slice(0,-1).join('-')?.split('/')?.forEach(state => this.goToByState(state));
	}

	/**
	 * Récupération de la dernière route enregistrée
	 */
	public getLastSavedRoute(): { state: string,queryParams?: any,paramsToRestore?: any,done: boolean } {
		let listeSavedRoutes: Array<{ state: string,queryParams?: any,paramsToRestore?: any,done: boolean }>;
		let savedRoute: { state: string,queryParams?: any,paramsToRestore?: any,done: boolean };

		//Lecture des routes inutilisées
		listeSavedRoutes = this.listeSavedRoutes.filter(r => !r.done);

		//Vérification de la présence d'une route
		if (listeSavedRoutes?.length) {
			//Récupération de la route
			savedRoute = listeSavedRoutes[listeSavedRoutes.length - 1];

			//Utilisation de la route
			savedRoute.done = true;

			//Définition de la liste des routes
			this.listeSavedRoutes = listeSavedRoutes;

			//Retour de la route enregistrée
			return savedRoute;
		} else
			//Aucun retour
			return null;
	}

	/**
	 * Rechargement de la route courante
	 */
	public reloadSameUrl() {
		//Rechargement de la route
		this.goToByState(this.getExtendedRouteData().state,{
			routeParams: this.activatedRouteSnapshot.params,
			reloadOnSameUrl: true
		});
	}

	/**
	 * Recherche de la route associée au paramètre fourni
	 */
	public getListeRoutesByExtendedDataField(key: string,value: any): Array<ExtendedRoute> {
		//Recherche de la route associée aux paramètres fournis
		return routes.filter(r => r.data?.[key] == value);
	}

	/**
	 * Définition du composant instancié pour la route courante
	 */
	public setRouteComponentInstance(routeComponentInstance: any) {
		//Définition du composant instancié pour la route courante
		this.routeComponentInstance = routeComponentInstance;
	}

	/**
	 * Récupération du composant instancié pour la route courante
	 */
	public getRouteComponentInstance() {
		//Récupération du composant instancié pour la route courante
		return this.routeComponentInstance;
	}

	/**
	 * Mise à jour de l'URL sans recharger la page
	 */
	public replaceUrl(path: string,query?: string,state?: string): void {
		//Mise à jour de l'URL sans recharger la page
		this.location.replaceState(path,query,state);
	}

	/**
	  * Mise à jour de l'identifiant contenu dans l'URL sans recharger la page
	  */
	public replaceUrlWith(data: { [key: string]: number | string } | number,forceReload: boolean = false): void {
		let currentPath: Array<string>;
		let newLocationUrl = '';

		//Récupération du chemin courant
		currentPath = this.location.path().substring(1).split('/');

		//Itération sur le chemin de la route
		newLocationUrl = '/' + this.activatedRouteSnapshot?.routeConfig?.path?.split('/').map((p: string,index: number) => {
			let currentKey;

			//Vérification de la présence d'un paramètre sur le chemin
			if (p.startsWith(':')) {
				//Récupération du nom du paramètre
				currentKey = p.replace(':','').split('?')[0];

				//Mise à jour des paramètres de navigation
				this.mapUpdatedParams[currentKey] = typeof data == 'number' ? data : data[currentKey] || currentPath[index];

				//Retour du paramètre modifié
				return typeof data == 'number' ? data : data[currentKey] || currentPath[index];
			} else
				//Retour du chemin courant
				return p;
		}).join('/');

		//Vérification d'un changement d'url
		if (newLocationUrl != this.location.path()) {
			//Vérification du rechargement forcé
			if (forceReload)
				//Navigation vers la nouvelle URL
				this.router.navigateByUrl('/').then(() => this.goToByUrl(newLocationUrl));
			else
				//Mise à jour de l'URL sans recharger la page
				this.router.navigate(newLocationUrl.split('/'),{ replaceUrl: true,info: { isFromReplaceUrl: true }});
		}
	}

	/**
	 * Vérification de l'ouverture du premier élément de la liste
	 */
	public checkAndResetOpenFirstItem() {
		//Vérification de l'ouverture du premier élément de la liste
		if (this.isOpenFirstItem) {
			//Désactivation de l'ouverture du premier élément de la liste
			this.isOpenFirstItem = false;

			//Ouverture demandée
			return true;
		} else
			//Aucune ouverture
			return false;
	}

	/**
	 * Vérification que le composant instancié pour la route courante est une entité en création
	 */
	public isNewEntity() {
		let routeComponent: any;

		//Récupération de l'instance du composant de la route courante
		routeComponent = this.getRouteComponentInstance();

		//Vérification de la présence d'une nouvelle entité
		return this.entiteService.isNewEntityForComponent(routeComponent);
	}
}