import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { mapEntites } from 'src/app/components/vehicule/infraction/contravention/contravention.entites';
import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ContraventionListItemComponent } from './contravention-list-item.component';
import { ContraventionService } from './contravention.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './contravention-list.component.html'
})
export class ContraventionListComponent implements OnInit,IListEntity {
	/** Données **/
	liste: ListView<any,ContraventionListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,public extractionService: ExtractionService,public dashboardService: DashboardService,private contraventionService: ContraventionService,public connecteurService: ConnecteurService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ContraventionListItemComponent>({
			uri: `/controller/Contravention/filtreContraventions`,
			title: this.translateService.instant('infraction.contravention.liste.title'),
			component: ContraventionListItemComponent,
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'vehicule',
				autocomplete: {
					type: 'vehicule'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			},{
				clef: 'dateContravention',
				type: TypeFilter.DATE
			},{
				clef: 'dateAvis',
				type: TypeFilter.DATE
			},{
				clef: 'dateMajoration',
				type: TypeFilter.DATE,
				dateOptions: {
					format: 'date',
					isStoreDate: true
				}
			},{
				clef: 'montantContravention',
				type: TypeFilter.DECIMAL
			},{
				clef: 'statut',
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG,
				listeValues: this.contraventionService.getListeStatuts()
			},{
				clef: 'type',
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG,
				listeValues: this.contraventionService.getListeTypesInfraction()
			},{
				clef: 'pays',
				autocomplete: {
					type: 'pays'
				},
				type: TypeFilter.AUTOCOMPLETE
			},{
				clef: 'isMajoree',
				type: TypeFilter.BOOLEAN
			}],
			getKeyFieldName: () => 'idContravention',
			defaultOrder: '-dateContravention,vehicule.reference,reference',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Contravention/loadContravention/0'))
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}