import { DecimalPipe } from '@angular/common';

import { Directive,ElementRef,forwardRef,HostBinding,HostListener,Input,Renderer2 } from '@angular/core';
import { ControlValueAccessor,NG_VALIDATORS,NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
	selector: '[nNumber]',
	exportAs: 'nNumber',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => NumberDirective),
		multi: true
	},{
		provide: NG_VALIDATORS,
		useExisting: NumberDirective,
		multi: true
	}]
})
export class NumberDirective implements ControlValueAccessor {
	/** Nombre de décimales **/
	@Input() nNumber: number = 0;

	/** Valeur minimale **/
	@Input() min: number = -999999999999.99;

	/** Valeur maximale **/
	@Input() max: number = 999999999999.99;

	/** Interdiction du zéro **/
	@Input() noZero: boolean = false;

	/** Alignement du texte de l'élément hôte **/
	@HostBinding('style.text-align') textAlign = 'right';

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Valeur actuelle **/
	private _value: string | null;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef<HTMLInputElement>,private decimalPipe: DecimalPipe,private renderer: Renderer2) {

	}

	/**
	 * Formattage de la valeur
	 */
	private formatValue(value: string | null) {
		let formatedValue: string;
		let oldNumValue: number;
		let newNumValue: number;

		//Récupération de la valeur numérique avant formattage
		oldNumValue = value !== null && value !== undefined ? Number(value) : null;

		//Vérification que la valeur est numérique
		if (!Number.isNaN(oldNumValue)) {
			//Définition de la valeur à afficher
			formatedValue = value !== null && value !== undefined ? this.decimalPipe.transform(value,`.${this.nNumber || 0}-${this.nNumber || 0}`) : '';

			//Mise à jour de l'élément
			this.updateNativeElement(formatedValue);

			//Récupération de la valeur numérique après formattage
			newNumValue = formatedValue ? Number(formatedValue.replace(/[^,\d.-]/g,'').replace(/[,]/g,'.')) : null;

			//Vérification que la valeur numérique a changé
			if (newNumValue != oldNumValue)
				//Déclenchement de la modification
				this.onChange?.(formatedValue ? Number(formatedValue.replace(/[^,\d.-]/g,'').replace(/[,]/g,'.')) : null);
		}
	}

	/**
	 * Déformattage de la valeur
	 */
	private unFormatValue() {
		let value;

		//Lecture de la valeur affichée
		value = this.elementRef.nativeElement.value;

		//Remplacement des caractères non numériques
		this._value = value.replace(/[^,\d.-]/g,'').replace(/[,]/g,'.') || null;

		//Mise à jour de la valeur à afficher
		this.updateNativeElement(value ? this._value : '');
	}

	/**
	 * Mise à jour du composant natif
	 */
	private updateNativeElement(value) {
		//Définition de la valeur
		this.renderer.setProperty(this.elementRef.nativeElement,'value',value);
	}

	/**
	 * Interception d'un changement de valeur
	 */
	@HostListener('input',['$event.target'])
	onInput(target: HTMLInputElement) {
		let firstInvalidCharacterIndex: number;
		let numValue: number;

		//Récupération de la position du premier caractère non numérique
		firstInvalidCharacterIndex = target.value.search(/[^\d.-]/);

		//Suppression des caractères non numériques
		this._value = target.value.replace(/[^\d.-]/g,'');

		//Mise à jour de l'élément avec la nouvelle valeur formatée
		this.updateNativeElement(this._value);

		//Vérification que des caractères ont été supprimés
		if (firstInvalidCharacterIndex > -1)
			//Rétablissement de la position du curseur
			target.selectionEnd = firstInvalidCharacterIndex;

		//Récupération de la valeur numérique
		numValue = Number(this._value);

		//Déclenchement de la modification du modèle
		this.onChange(!Number.isNaN(numValue) ? numValue : this._value);
	}

	/**
	 * Interception de la sortie du champ
	 */
	@HostListener('blur')
	onBlur() {
		//Formattage de la valeur
		setTimeout(() => this.formatValue(this._value));
	}

	/**
	 * Interception d'un focus sur le champ
	 */
	@HostListener('focus')
	onFocus() {
		//Déformattage de la valeur
		setTimeout(() => this.unFormatValue());
	}

	/**
	 * Ecriture de la valeur
	 */
	writeValue(value: any) {
		//Définition de la valeur
		this._value = value;

		//Formattage de la valeur
		this.formatValue(this._value);
	}

	/**
	 * Enregistrement de la fonction d'interception du changement
	 */
	registerOnChange(fn: any) {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement de la fonction d'interception de l'appui
	 */
	registerOnTouched(fn: any) {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Validation des données
	 */
	validate() {
		let valueToValidate: number;

		//Lecture de la valeur
		valueToValidate = Number(this._value);

		//Validation de la valeur
		return this._value != null && (Number.isNaN(valueToValidate) || this.min !== null && this.min > valueToValidate || this.max !== null && this.max < valueToValidate || this.noZero && valueToValidate == 0) && {
			invalid: true
		};
	}

	/**
	 * Mise à jour de l'état du composant
	 */
	setDisabledState(isDisabled: boolean): void {
		//Définition de l'état de l'élément
		this.renderer.setProperty(this.elementRef.nativeElement,'disabled',isDisabled);
	}
}