import { NgModule } from '@angular/core';

import { TaxeModule } from './taxe/taxe.module';
import { RegleTaxeModule } from './regle-taxe/regle-taxe.module';
import { AvantageNatureAbattementModule } from './avantage-nature/avantage-nature-abattement.module';
import { BaremeTaxeModule } from './bareme-taxe/bareme-taxe.module';

@NgModule({
	imports: [TaxeModule,RegleTaxeModule,AvantageNatureAbattementModule,BaremeTaxeModule]
})
export class FiscaliteModule { }
