<div class="container-fluid">
	<form #factureForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation') || facture.interfaceFournisseur || ['COMPTABILISEE','VALIDEE'].includes(facture.statut)">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
								<label translate>facture.fournisseur</label>
								<div>
									<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="facture.fournisseur" required></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>facture.reference.item</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="facture.reference" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
								<label translate>facture.type.item</label>
								<div class="half">
									<selectpicker name="type" #typeField="ngModel" [(ngModel)]="facture.type" required>
										<mat-option *ngFor="let type of listeTypes" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': factureForm?.controls?.['societe']?.invalid }">
								<label translate>facture.societe</label>
								<div *ngIf="!facture.interfaceFournisseur || facture.societe">
									<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="facture.societe" required></autocomplete>
								</div>
								<div *ngIf="facture.interfaceFournisseur && !facture.societe">
									<span translate [translateParams]="{ compteFacturation: facture.codeCompteFacturation }">facture.alerte.codeFacturationInconnu</span>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': date.invalid }">
								<label translate>facture.dateEmission</label>
								<div class="half">
									<div class="input-group">
										<input type="text" class="form-control" name="date" #date="ngModel" [(ngModel)]="facture.date" datePicker format="date" [storeDate]="true" required/>
										<div class="input-group-addon" *ngIf="facture.date > dateCourante">
											<icon name="warning" [tooltip]="'facture.date.info' | translate"></icon>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" *ngIf="facture.statut">
								<label translate>facture.statut.item</label>
								<div>
									<span [translate]="'facture.statut.'+ facture.statut"></span>
								</div>
							</div>
							<div class="form-group" *ngIf="facture.dateCreation">
								<label translate>facture.dateCreation</label>
								<div>
									<span>{{ facture.dateCreation | date:'short' }}</span>
								</div>
							</div>
							<div class="form-group">
								<label>
									<span translate>facture.montantTTC.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'facture.montantTTC.info' | translate"></icon>
								</label>
								<div>
									<span>{{ (facture.montant | currency:facture.devise) + (facture.montant == 0 ? ' ' + ('facture.montantEmpty' | translate) : '') }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>facture.dateEcheance</label>
								<div class="half">
									<input type="text" class="form-control" name="dateEcheance" #dateEcheance="ngModel" [(ngModel)]="facture.dateEcheance" datePicker format="date" [storeDate]="true"/>
								</div>
							</div>
							<div class="form-group">
								<label translate>facture.dateDebut</label>
								<div class="half">
									<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="facture.dateDebut" datePicker format="date" [storeDate]="true"/>
								</div>
							</div>
							<div class="form-group">
								<label translate>facture.dateFin</label>
								<div class="half">
									<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="facture.dateFin" datePicker format="date" [storeDate]="true"/>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>facture.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="facture.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteFacture();" *ngIf="facture?.idFacture && (null | right:'suppression') && facture.statut == 'INTEGREE'">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveFacture();" *ngIf="(null | right:'creation') && !facture.interfaceFournisseur && facture.statut != 'COMPTABILISEE'" [disabled]="factureForm.invalid">
				<span [translate]="'actions.' + (facture?.idFacture ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="facture?.idFacture" [formDetectChanges]="{ doAction: close,isDirty: factureForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>