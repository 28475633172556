<div class="container-fluid">
	<form #userForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': civiliteField.invalid }">
								<label>
									<span translate>user.civilite</span>
									<icon name="warning" class="c-warning m-l-5" [tooltip]="'user.updatedByUser' | translate:{ date: user.userDescription.dateModification | date:'shortDate' }" *ngIf="isDataUpdated('CIVILITE')"></icon>
								</label>
								<div>
									<selectpicker name="civilite" #civiliteField="ngModel" [(ngModel)]="user.civilite">
										<mat-option *ngFor="let civilite of listeTypesCivilite" [value]="civilite"><span [translate]="'user.genre.' + civilite"></span></mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': nom.invalid }">
								<label personalData translate>user.nom</label>
								<div>
									<input type="text" name="nom" #nom="ngModel" class="form-control" [(ngModel)]="user.nom" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': prenom.invalid }">
								<label personalData translate>user.prenom</label>
								<div>
									<input type="text" name="prenom" #prenom="ngModel" class="form-control" [(ngModel)]="user.prenom" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': matricule.invalid }">
								<label personalData translate>user.matricule</label>
								<div>
									<input type="text" name="matricule" #matricule="ngModel" class="form-control" [(ngModel)]="user.matricule" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': email.invalid }">
								<label personalData translate>user.email</label>
								<div>
									<input type="email" email name="email" #email="ngModel" class="form-control" [(ngModel)]="user.email" (ngModelChange)="onLoginChange(user)" maxlength="600" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }" *ngIf="user.tenant.type == 'CLIENT' && user.type != 'ADMINISTRATEUR'">
								<label translate>user.societe</label>
								<div>
									<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="user.societe" required [disabled]="user.idUser"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': service.invalid }" *ngIf="user.tenant.type == 'CLIENT' && user.type != 'ADMINISTRATEUR'">
								<label translate>user.serviceAffectation</label>
								<div>
									<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="user.service" [filter]="{ idSociete: user?.societe?.idService }" [disabled]="!user?.societe" [required]="user?.societe?.extension?.nbSousServices > 0" [disabled]="user.idUser"></autocomplete>
								</div>
							</div>
							<div class="form-group">
								<label translate>user.locale</label>
								<div class="half">
									<selectpicker name="locale" #localeField="ngModel" [(ngModel)]="user.locale" [placeholder]="'common.parDefaut' | translate">
										<mat-option [value]="null">{{ 'common.parDefaut' | translate }}</mat-option>
										<mat-option *ngFor="let locale of listeLocales" [value]="locale">{{ locale.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group">
								<label translate>user.categorie.item</label>
								<div>
									<autocomplete name="categorie" type="userCategorie" #categorie="ngModel" [(ngModel)]="user.categorie"></autocomplete>
								</div>
							</div>
							<div class="form-group">
								<label translate>user.dateSortie</label>
								<div class="half">
									<input type="text" class="form-control" name="dateSortie" #dateSortie="ngModel" [(ngModel)]="user.dateSortie" datePicker format="date" [storeDate]="true"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>user.enabled.item</label>
								<div>
									<mat-checkbox name="enabled" #enabled="ngModel" [(ngModel)]="user.enabled"><span translate>user.enabled.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': telephonePro.invalid }">
								<label personalData translate>user.telephonePro</label>
								<div class="half">
									<input type="text" name="telephonePro" #telephonePro="ngModel" class="form-control" [(ngModel)]="user.userDescription.telephonePro" phoneNumber/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': telephonePerso.invalid }">
								<label personalData translate>user.telephonePerso</label>
								<div class="half">
									<input type="text" name="telephonePerso" #telephonePerso="ngModel" class="form-control" [(ngModel)]="user.userDescription.telephonePerso" phoneNumber/>
								</div>
							</div>
							<div class="form-group">
								<label personalData>
									<span translate>user.adressePerso</span>
									<icon name="warning" class="c-warning m-l-5" [tooltip]="'user.updatedByUser' | translate:{ date: user.userDescription.dateModification | date:'shortDate' }" *ngIf="isDataUpdated('ADRESSE_PERSO')"></icon>
								</label>
								<div>
									<adresse name="adressePerso" #adressePerso="ngModel" [(ngModel)]="user.userDescription.adressePerso"></adresse>
								</div>
							</div>
							<div class="form-group">
								<label personalData>
									<span translate>user.dateNaissance</span>
									<icon name="warning" class="c-warning m-l-5" [tooltip]="'user.updatedByUser' | translate:{ date: user.userDescription.dateModification | date:'shortDate' }" *ngIf="isDataUpdated('DATE_NAISSANCE')"></icon>
								</label>
								<div class="half">
									<input type="text" class="form-control" name="dateNaissance" #dateNaissance="ngModel" [(ngModel)]="user.userDescription.dateNaissance" datePicker format="date" maxDate="today"/>
								</div>
							</div>
							<div class="form-group">
								<label personalData>
									<span translate>user.lieuNaissance</span>
									<icon name="warning" class="c-warning m-l-5" [tooltip]="'user.updatedByUser' | translate:{ date: user.userDescription.dateModification | date:'shortDate' }" *ngIf="isDataUpdated('LIEU_NAISSANCE')"></icon>
								</label>
								<div>
									<adresse name="lieuNaissance" #lieuNaissance="ngModel" [(ngModel)]="user.userDescription.lieuNaissance" searchMode="VILLE"></adresse>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': userN1.invalid || user?.userDescription?.userN1?.idUser && (user?.userDescription?.userN2?.idUser == user?.userDescription?.userN1?.idUser || user?.userDescription?.userN1?.idUser == user?.idUser) }">
								<label>
									<span translate>user.userN1.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'user.userN1.info' | translate"></icon>
								</label>
								<div>
									<autocomplete name="userN1" type="user" #userN1="ngModel" [(ngModel)]="user.userDescription.userN1"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': userN2.invalid || user?.userDescription?.userN2?.idUser && (user?.userDescription?.userN1?.idUser == user?.userDescription?.userN2?.idUser || user?.userDescription?.userN2?.idUser == user?.idUser) }">
								<label>
									<span translate>user.userN2.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'user.userN2.info' | translate"></icon>
								</label>
								<div>
									<autocomplete name="userN2" type="user" #userN2="ngModel" [(ngModel)]="user.userDescription.userN2"></autocomplete>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="card full">
			<data-customization entite="com.notilus.data.user.User" [object]="user" [disabled]="!(null | right:'creation')"></data-customization>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteUser();" *ngIf="user?.idUser && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveUser();" *ngIf="null | right:'creation'" [disabled]="userForm.invalid">
				<span [translate]="'actions.' + (user?.idUser ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="user?.idUser" [formDetectChanges]="{ doAction: close,isDirty: userForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>