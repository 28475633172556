<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idAbattement">
		<div class="pull-left lv-avatar"><icon name="electric_car"></icon></div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data?.montantAbattement | currency:'EUR' }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idAbattement">
		<li><span translate>fiscalite.abattement.dateDebut</span>&#160;:&#160;<span>{{ data.dateDebut | date:'shortDate' }}</span></li>
		<li><span translate>fiscalite.abattement.abattement</span>&#160;:&#160;<span>{{ data.abattement+'%' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idAbattement && (null | right:'suppression')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteAbattement();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<abattement-avantage-nature [abattement]="data" (onObjectChange)="onObjectChange($event)"></abattement-avantage-nature>
</div>