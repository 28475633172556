import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'etat-lieux-template-detail-line',
	templateUrl: './etat-lieux-template-detail-line.component.html'
})
export class EtatLieuxTemplateDetailLineComponent implements OnInit {
	/** Droit courant **/
	@Input() detail: any = {};

	/** Mode 'Edition' **/
	@Input() isEdition: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) { }

	/**
	 * Chargement du composant
	 */
	ngOnInit() {
		//Vérification du mode
		if (!this.isEdition)
			//Ajout de l'indicateur d'affichage
			this.detail.visible = true;
	}

	/**
	 * Interception du changement de détail
	 */
	onDetailChange() {
		//Vérification de la propriété
		if (!this.detail.visible) {
			//Mise à jour des détails
			this.detail.photoObligatoire = false;
			this.detail.remarqueObligatoire = false;
			this.detail.verificationObligatoire = false;
		}
	}

	/**
	 * Récupération du libellé
	 */
	getLibelle() {
		let libelle: string = '';

		//Vérification du type
		if (!['TYPE_EQUIPEMENT','TYPE_AMENAGEMENT','AUTRE'].includes(this.detail.type))
			//Définition du libellé
			libelle = this.translateService.instant(`vehicule.etatLieuxTemplate.detail.sections.STATIQUE.items.${this.detail.type}`);
		else if (this.detail.type == 'AUTRE' && this.detail.libelle?.length)
			//Définition du libellé
			libelle =  this.detail.libelle;
		else if (this.detail.type == 'TYPE_EQUIPEMENT' && this.detail.typeEquipement)
			//Définition du libellé
			libelle = this.translateService.instant(`equipement.type.${this.detail.typeEquipement}`);
		else if (this.detail.type == 'TYPE_AMENAGEMENT' && this.detail.typeAmenagement)
			//Définition du libellé
			libelle = this.detail.typeAmenagement.libelle;

		return libelle;
	}
}
