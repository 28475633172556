<form #etatVehiculeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="etat-lieux-template">
	<fieldset [disabled]="!(null | right:'creation')">
		<div class="card full">
			<div class="card-body card-padding etat-vehicule" [ngClass]="{ 'disabled': !isEdition }">
				<div class="p-t-20 p-b-20 d-flex justify-content-space-between align-items-center">
					<h4 class="flex-1" translate>vehicule.etatLieuxTemplate.etatVehicule.exterieur</h4>
					<mat-button-toggle-group [(ngModel)]="etatVehicule.exterieur" name="exterieur">
						<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
						<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
					</mat-button-toggle-group>
				</div>
				<div class="line-container exterieur" *ngIf="etatVehicule.exterieur">
					<ng-container *ngFor="let item of listeTypesMasqueExterieur">
						<div>
							<label>{{ item.libelle }}</label>
							<div class="el-template-container" (click)="etatVehicule.typeMasqueExterieur = item.code" [ngClass]="{ 'selected': item.code == etatVehicule.typeMasqueExterieur }">
								<div class="el-items-container" [ngClass]="item.code" [ngSwitch]="item.code">
									<ng-container *ngSwitchCase="'QUATRE_ZONES'">
										<div class="el-item top"></div>
										<div class="middle">
											<div class="el-item middle-left"></div>
											<div class="el-item middle-right"></div>
										</div>
										<div class="el-item bottom"></div>
									</ng-container>
									<ng-container *ngSwitchCase="'HUIT_ZONES'">
										<div class="el-item top"></div>
										<div class="middle-top">
											<div class="el-item left"></div>
											<div class="el-item right"></div>
										</div>
										<div class="middle">
											<div class="el-item left"></div>
											<div class="el-item right"></div>
										</div>
										<div class="middle-bottom">
											<div class="el-item left"></div>
											<div class="el-item right"></div>
										</div>
										<div class="el-item bottom"></div>
									</ng-container>
								</div>
							</div>
							<div *ngIf="etatVehicule.typeMasqueExterieur || isEdition">
								<mat-radio-group name="typeMasqueExterieur" [(ngModel)]="etatVehicule.typeMasqueExterieur" required>
									<mat-radio-button [value]="item.code"></mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="form-group p-l-20" *ngIf="etatVehicule.exterieur && etatVehicule.typeMasqueExterieur">
					<label translate>vehicule.etatLieuxTemplate.etatVehicule.photoObligatoire.item</label>
					<div>
						<mat-checkbox name="exterieurPhotoObligatoire" [(ngModel)]="etatVehicule.exterieurPhotoObligatoire" [disabled]="!isEdition"><span translate>vehicule.etatLieuxTemplate.etatVehicule.photoObligatoire.description</span></mat-checkbox>
					</div>
				</div>
			</div>
		</div>

		<div class="card full">
			<div class="card-body card-padding etat-vehicule" [ngClass]="{ 'disabled': !isEdition }">
				<div class="p-t-20 p-b-20 d-flex justify-content-space-between align-items-center">
					<h4 class="flex-1" translate>vehicule.etatLieuxTemplate.etatVehicule.interieur</h4>
					<mat-button-toggle-group [(ngModel)]="etatVehicule.interieur" name="interieur">
						<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
						<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
					</mat-button-toggle-group>
				</div>
				<div class="line-container interieur" *ngIf="etatVehicule.interieur">
					<ng-container *ngFor="let item of listeTypesMasqueInterieur">
						<div>
							<label>{{ item.libelle }}</label>
							<div class="el-template-container" (click)="etatVehicule.typeMasqueInterieur = item.code" [ngClass]="{ 'selected': item.code == etatVehicule.typeMasqueInterieur }">
								<div class="el-items-container" [ngClass]="item.code" [ngSwitch]="item.code">
									<ng-container *ngSwitchCase="'TROIS_ZONES'">
										<div class="el-item top"></div>
										<div class="el-item middle"></div>
										<div class="el-item bottom"></div>
									</ng-container>
									<ng-container *ngSwitchCase="'QUATRE_ZONES'">
										<div class="el-item top"></div>
										<div class="el-item middle-top"></div>
										<div class="el-item middle-bottom"></div>
										<div class="el-item bottom"></div>
									</ng-container>
								</div>
							</div>
							<div *ngIf="etatVehicule.typeMasqueInterieur || isEdition">
								<mat-radio-group name="typeMasqueInterieur" [(ngModel)]="etatVehicule.typeMasqueInterieur">
									<mat-radio-button [value]="item.code"></mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="form-group p-l-20" *ngIf="etatVehicule.interieur && etatVehicule.typeMasqueExterieur">
					<label translate>vehicule.etatLieuxTemplate.etatVehicule.photoObligatoire.item</label>
					<div>
						<mat-checkbox name="interieurPhotoObligatoire" [(ngModel)]="etatVehicule.interieurPhotoObligatoire" [disabled]="!isEdition"><span translate>vehicule.etatLieuxTemplate.etatVehicule.photoObligatoire.description</span></mat-checkbox>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
	<ng-container *ngIf="isEdition">
		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveEtatVehicule()" [disabled]="etatVehiculeForm.invalid || !etatVehicule.interieur && !etatVehicule.exterieur" *ngIf="null | right:'creation'">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: etatVehiculeForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</ng-container>
	<div class="clearfix"></div>
</form>