import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class EquipementService {
	/** Liste des types **/
	private listeTypes: Array<string> = ['AVERTISSEUR_RADAR','GEOLOCALISATION','TELEMATIQUE','TELEPEAGE','CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE','CABLE_CHARGE'];

	/** Liste des statuts **/
	private listeStatuts: Array<string> = ['EN_COMMANDE','ACTIF','ANNULE','OPPOSE','CLOTURE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {

	}

	/**
	 * Suppression de l'équipement
	 */
	public deleteEquipement(equipement: any): Observable<Result> {
		//Suppression de l'équipement
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Equipement/deleteEquipement/${equipement.idEquipement}`);
	}

	/**
	 * Enregistrement de l'équipement
	 */
	public saveEquipement(equipement: any): Observable<Result> {
		//Enregistrement de l'équipement
		return this.http.put<Result>(`${environment.baseUrl}/controller/Equipement/saveEquipement`,equipement);
	}

	/**
	 * Chargement de l'équipement
	 */
	public loadEquipement(idEquipement: number): Observable<Result> {
		//Chargement de l'équipement
		return this.http.post<Result>(`${environment.baseUrl}/controller/Equipement/loadEquipement/${idEquipement}`,null);
	}

	/**
	 * Chargement du résumé
	 */
	public loadResume(idEquipement: number): Observable<Result> {
		//Chargement du résumé
		return this.http.post<Result>(`${environment.baseUrl}/controller/Equipement/loadResume/${idEquipement}`,null);
	}

	/**
	 * Récupération de la liste des types
	 */
	getListeTypes(): Array<{ code: string,libelle: string }> {
		//Création de la correspondance entre un code et son libellé
		return this.listeTypes.map(type => ({
			code: type,
			libelle: this.translateService.instant('equipement.type.'+type)
		})).sort((a,b) => a.libelle?.localeCompare(b.libelle));
	}

	/**
	 * Récupération de la liste des statuts
	 */
	getListeStatuts(): Array<{ code: string,libelle: string }> {
		//Création de la correspondance entre un code et son libellé
		return this.listeStatuts.map(statut => ({
			code: statut,
			libelle: this.translateService.instant('equipement.statut.'+statut)
		})).sort((a,b) => a.libelle?.localeCompare(b.libelle));
	}

	/**
	 * Recherche du véhicule lié à un équipement pour une date donnée
	 */
	findVehiculeForDate(idEquipement: number,date: Date): Observable<Result> {
		//Recherche du véhicule lié à l'équipement pour la date donnée
		return this.http.post<Result>(`${environment.baseUrl}/controller/Equipement/findVehiculeForDate/${idEquipement}/${date}`,null);
	}
}