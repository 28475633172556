<form #etatLieuxDetailForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="m-t-20">
	<div class="card full etat-lieux-template">
		<div class="card-body card-padding detail" [ngClass]="{ 'disabled': !isEdition }">
			<div class="section m-t-0">
				<h4 class="card-title" translate>vehicule.etatLieuxTemplate.detail.sections.STATIQUE.title</h4>
				<div *ngIf="listeStaticDetails?.length">
					<fieldset [disabled]="!isEdition">
						<ul class="liste-details">
							<li *ngFor="let detail of listeStaticDetails">
								<etat-lieux-template-detail-line [detail]="detail" [isEdition]="isEdition"></etat-lieux-template-detail-line>
							</li>
						</ul>
					</fieldset>
				</div>
				<div *ngIf="!listeStaticDetails?.length" class="empty-message">
					<span translate>vehicule.etatLieuxTemplate.detail.empty</span>
				</div>
			</div>

			<div class="section">
				<h4 class="card-title" translate>vehicule.etatLieuxTemplate.detail.sections.TYPE_EQUIPEMENT.title</h4>
				<div *ngIf="listeTypeEquipementDetails?.length">
					<fieldset [disabled]="!isEdition">
						<ul class="liste-details">
							<li *ngFor="let detail of listeTypeEquipementDetails">
								<etat-lieux-template-detail-line [detail]="detail" [isEdition]="isEdition"></etat-lieux-template-detail-line>
							</li>
						</ul>
					</fieldset>
				</div>
				<div class="listview m-t-10" *ngIf="isEdition">
					<div class="lv-body">
						<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addItem('TYPE_EQUIPEMENT')">
							<span><a [routerLink]="[]" translate>vehicule.etatLieuxTemplate.detail.sections.TYPE_EQUIPEMENT.addTypeEquipement</a></span>
						</div>
					</div>
				</div>
				<div *ngIf="!listeTypeEquipementDetails?.length && !isEdition" class="empty-message">
					<span translate>vehicule.etatLieuxTemplate.detail.empty</span>
				</div>
			</div>

			<div class="section">
				<h4 class="card-title" translate>vehicule.etatLieuxTemplate.detail.sections.TYPE_AMENAGEMENT.title</h4>
				<div *ngIf="listeTypeAmenagementDetails?.length">
					<fieldset [disabled]="!isEdition">
						<ul class="liste-details">
							<li *ngFor="let detail of listeTypeAmenagementDetails">
								<etat-lieux-template-detail-line [detail]="detail" [isEdition]="isEdition"></etat-lieux-template-detail-line>
							</li>
						</ul>
					</fieldset>
				</div>
				<div class="listview m-t-10" *ngIf="isEdition">
					<div class="lv-body">
						<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addItem('TYPE_AMENAGEMENT')">
							<span><a [routerLink]="[]" translate>vehicule.etatLieuxTemplate.detail.sections.TYPE_AMENAGEMENT.addTypeAmenagement</a></span>
						</div>
					</div>
				</div>
				<div *ngIf="!listeTypeAmenagementDetails?.length && !isEdition" class="empty-message">
					<span translate>vehicule.etatLieuxTemplate.detail.empty</span>
				</div>
			</div>

			<div class="section">
				<h4 class="card-title" translate>vehicule.etatLieuxTemplate.detail.sections.AUTRE.title</h4>
				<div *ngIf="listeOtherDetails?.length">
					<fieldset [disabled]="!isEdition">
						<ul class="liste-details">
							<li *ngFor="let detail of listeOtherDetails">
								<etat-lieux-template-detail-line [detail]="detail" [isEdition]="isEdition"></etat-lieux-template-detail-line>
							</li>
						</ul>
					</fieldset>
				</div>
				<div class="listview m-t-10" *ngIf="isEdition">
					<div class="lv-body">
						<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addItem('AUTRE')">
							<span><a [routerLink]="[]" translate>vehicule.etatLieuxTemplate.detail.sections.AUTRE.addLibelle</a></span>
						</div>
					</div>
				</div>
				<div *ngIf="!listeOtherDetails?.length && !isEdition" class="empty-message">
					<span translate>vehicule.etatLieuxTemplate.detail.empty</span>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="isEdition">
		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveDetails()" *ngIf="null | right:'creation'" [disabled]="etatLieuxDetailForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button (click)="close()">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>