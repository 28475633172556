import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,of } from 'rxjs';
import { filter,first,map,switchMap,tap } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ConnecteurService as SharedConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConnecteurEditComponent } from './connecteur-edit.component';
import { ConnecteurExecutionListComponent } from './connecteur-execution-list.component';
import { ConnecteurService } from './connecteur.service';

@Component({
	selector: 'connecteur',
	templateUrl: './connecteur.component.html'
})
export class ConnecteurComponent implements OnInit {
	/** Options de la page complexe **/
	public options: Options;

	/** Connecteur courant **/
	public connecteur: any;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Résumé **/
	private resume: { nbAppels: number } = {
		nbAppels: 0
	};

	/**
	 * Constructeur
	 */
	constructor(private pageContentService: PageContentService,private connecteurService: ConnecteurService,private activatedRoute: ActivatedRoute,private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService
			,private loggedUserService: LoggedUserService,private confirmService: ConfirmService,private toastrService: ToastrService,private sharedConnecteurService: SharedConnecteurService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.connecteur.Connecteur';

	/**
	 * Récupération de l'identifiant du connecteur à charger
	 */
	getIdObject: () => number = () => this.connecteur?.idConnecteur || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'identifiant du connecteur à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idConnecteur).subscribe({
					next: () => {
						//Définition des options d'affichage
						this.options = {
							listeFields: [{
								libelle: 'connecteur.title',
								key: 'type.libelle'
							},this.connecteur?.type?.typeConnecteur == 'INTERFACE' && {
								libelle: 'connecteur.version',
								key: 'version'
							},{
								libelle: 'connecteur.frequence',
								key: (data) => this.translateService.instant(data.scheduler?.type ? `scheduler.frequence.${data.scheduler?.type}` : `connecteur.${data.type.typeConnecteur == 'API' ? 'frequenceManuelle' : 'frequenceReceptionFichier'}`)
							},{
								libelle: 'connecteur.dateDerniereExecution',
								key: 'dateLastExecution',
								type: 'DATE',
								format: 'datetime'
							},this.connecteur?.type?.typeConnecteur == 'API' && (this.loggedUserService.getUserOrigine() || this.rightService.isRoot()) && {
								libelle: 'connecteur.environnement.title',
								key: (data) => this.translateService.instant(`connecteur.environnement.${data.typeEnvironnement}`)
							},this.connecteur?.type?.typeConnecteur == 'INTERFACE' && {
								libelle: 'connecteur.typeExecution.item',
								key: (data) => this.translateService.instant(`connecteur.typeExecution.${data.typeExecution}`)
							}].filter(f => !!f),
							listeActions: [{
								libelle: 'actions.modifier',
								doAction: () => this.editConnecteur(),
								isVisible: () => this.rightService.hasRight(null,'creation'),
								type: TypeAction.EDITION
							},{
								libelle: 'actions.consulter',
								doAction: () => this.editConnecteur(),
								isVisible: () => !this.rightService.hasRight(null,'creation'),
								type: TypeAction.CONSULTATION
							},{
								libelle: 'actions.supprimer',
								doAction: () => this.deleteConnecteur(),
								isVisible: () => this.rightService.hasRight(null,'suppression') && !this.connecteur.dateLastExecution,
								type: TypeAction.SUPPRESSION
							},{
								libelle: 'actions.tester',
								doAction: () => this.login(),
								isVisible: () => this.rightService.hasRight(null,'consultation') && this.connecteur?.type?.typeConnecteur == 'API',
								type: TypeAction.CONSULTATION
							},{
								libelle: 'actions.executer',
								doAction: () => this.execute(),
								isVisible: () => this.rightService.hasRight(null,'consultation') && this.connecteur?.type?.typeConnecteur == 'API',
								type: TypeAction.CONSULTATION
							}].filter(a => !!a),
							listeElements: [{
								type: 'EXECUTIONS',
								libelle: 'connecteur.elements.executions',
								component: ConnecteurExecutionListComponent,
								retrieveComponentData: () => ({
									connecteur: this.connecteur,
									resume: this.resume
								}),
								count: () => this.resume.nbAppels
							}]
						}

						//Vérification de l'identifiant du connecteur
						if (!this.connecteur.idConnecteur)
							//Edition du connecteur
							setTimeout(() => this.editConnecteur());
					}
				});
			}
		});
	}

	/**
	 * Chargement des données
	 */
	loadData(idConnecteur: number): Observable<any> {
		//Chargement du connecteur
		return (idConnecteur > 0 ? this.connecteurService.loadConnecteur(idConnecteur) : of(null)).pipe(
			tap((result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR && result.data?.connecteur) {
					//Définition du connecteur
					this.connecteur = result.data.connecteur;

					//Mise à jour du résumé
					this.resume.nbAppels = result.data?.nbAppels || 0;
				} else {
					//Création d'un objet vierge
					this.connecteur = {
						typeEnvironnement: 'DEFAULT',
						actif: true
					};
				}
			})
		);
	}

	/**
	 * Edition du connecteur
	 */
	private editConnecteur() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ConnecteurEditComponent,{
			data: {
				connecteur: cloneDeep(this.connecteur),
				deleteConnecteur: this.deleteConnecteur.bind(this),
				resume: this.resume
			}
		}).subscribe({
			next: (connecteur: any) => {
				//Vérification des données
				if (connecteur) {
					//Mise à jour du connecteur
					Object.assign(this.connecteur,connecteur);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.connecteur);
				}
			}
		});
	}

	/**
	 * Suppression du connecteur
	 */
	private deleteConnecteur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.connecteurService.deleteConnecteur(this.connecteur.idConnecteur))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.connecteur;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Authentification sur le connecteur
	 */
	login() {
		//Tentative de connexion sur le connecteur
		this.sharedConnecteurService.login(this.connecteur).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
				//Message d'information
				this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.connexion.success'),{ isCancelDisabled: true,actionColor: 'primary' });
			else
				//Message d'erreur
				this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.connexion.error'),{ isCancelDisabled: true,actionColor: 'primary' });
		});
	}

	/**
	 * Exécution du connecteur sélectionné
	 */
	execute() {
		//Vérification de la présence de paramètres facultatifs
		if (this.connecteur.type.listeParams.some(p => p.optional)) {
			//Afficahge des paramètres optionnels du connecteur
			this.connecteurService.showParams(this.connecteur,{
				isOnlyOptionals: true,
				buttonLabel: 'actions.executer'
			}).pipe(
				map(result => result?.listeParams),
				filter(listeParams => !!listeParams?.length)
			).subscribe({
				next: listeParams => {
					//Exécution du connecteur avec les paramètres optionnels
					this.executeConnecteur(listeParams);
				}
			});
		} else {
			//Exécution directe du connecteur
			this.executeConnecteur();
		}
	}

	/**
	 * Exéuction du connecteur
	 */
	executeConnecteur(listeOptionalParams?: Array<any>) {
		//Exécution du connecteur
		this.sharedConnecteurService.execute(Object.assign(this.connecteur,{ listeOptionalParams })).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
				//Message d'information
				this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.execution.success'),{ isCancelDisabled: true,actionColor: 'primary' });
			else
				//Message d'erreur
				this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.execution.error'),{ isCancelDisabled: true,actionColor: 'primary' });
		});
	}
}
