import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'dashboard-chart-configuration-filtre',
	templateUrl: './dashboard-chart-configuration-filtre.component.html'
})
export class DashboardChartConfigurationFiltreComponent extends PageContentItem {
	/** Graphique **/
	@Input() chart: any;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}
