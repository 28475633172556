import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaremeTaxeService {
	/** Liste des types de barème **/
	private readonly listeTypesBaremeTaxe: Array<string> = ['WLTP','NEDC','PUISSANCE_FISCALE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {}

	/**
	 * Récupération de la liste des types de barèmes de taxe
	 */
	public getListeTypesBaremeTaxe(): Array<{ code: string,libelle: string,avatar: string }> {
		//Liste des types de barèmes de taxe
		return this.listeTypesBaremeTaxe.map(code => ({
			code,
			libelle: this.translateService.instant('fiscalite.baremeTaxe.typeBareme.' + code)
		})).map(item => ({
			...item,
			avatar: item.libelle.split(' ').map(word => word[0]).join('').toUpperCase()
		}));
	}

	/**
	 * Chargement d'un barème de taxe
	 */
	public loadBaremeTaxe(idBaremeTaxe: number): Observable<Result> {
		//Chargement d'un barème de taxe
		return this.http.post<Result>(`${environment.baseUrl}/controller/Fiscalite/loadBaremeTaxe/${idBaremeTaxe}`,null);
	}

	/**
	 * Suppression d'un barème de taxe
	 */
	public deleteBaremeTaxe(baremeTaxe: any): Observable<Result> {
		//Suppression d'un barème de taxe
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fiscalite/deleteBaremeTaxe/${baremeTaxe.idBaremeTaxe}`);
	}

	/**
	 * Enregistrement d'un barème de taxe
	 */
	public saveBaremeTaxe(baremeTaxe: any): Observable<Result> {
		//Enregistrement d'un barème de taxe
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fiscalite/saveBaremeTaxe`,baremeTaxe);
	}
}