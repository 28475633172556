import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EtatLieuxTemplateListItemComponent } from './etat-lieux-template-list-item.component';

@Component({
	selector: 'etat-lieux-template-list',
	templateUrl: './etat-lieux-template-list.component.html'
})
export class EtatLieuxTemplateListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,EtatLieuxTemplateListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,EtatLieuxTemplateListItemComponent>({
			uri: `/controller/VehiculeReferentiel/filtreEtatLieuxTemplates`,
			title: this.translateService.instant('vehicule.etatLieuxTemplate.liste.title'),
			component: EtatLieuxTemplateListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Vehicule/loadEtatLieuxTemplate/0'))
		});
	}
}
