<div class="container-fluid">
	<form #baremeTaxeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>fiscalite.baremeTaxe.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="baremeTaxe.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeBaremeField.invalid }">
								<label translate>fiscalite.baremeTaxe.typeBareme.item</label>
								<div class="half">
									<selectpicker name="typeBareme" #typeBaremeField="ngModel" [(ngModel)]="baremeTaxe.typeBareme" required>
										<mat-option *ngFor="let typeBareme of listeTypesBaremeTaxe" [value]="typeBareme.code">{{ typeBareme.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
								<label translate>fiscalite.baremeTaxe.dateApplication</label>
								<div class="half">
									<input type="text" class="form-control" name="dateApplication" #dateApplication="ngModel" [(ngModel)]="baremeTaxe.dateApplication" datePicker format="date" [storeDate]="true" required/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
								<label>
									<span translate>fiscalite.baremeTaxe.actif.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'fiscalite.baremeTaxe.actif.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" type="actif" #actif="ngModel" [(ngModel)]="baremeTaxe.actif" [disabled]="!baremeTaxe?.listeTranches?.length"><span translate>fiscalite.baremeTaxe.actif.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteBaremeTaxe()" *ngIf="baremeTaxe?.idBaremeTaxe && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="baremeTaxeForm.invalid" (click)="saveBaremeTaxe(baremeTaxe,close)" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(baremeTaxe?.idBaremeTaxe ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="baremeTaxe.idBaremeTaxe" [formDetectChanges]="{ doAction: close,isDirty: baremeTaxeForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>