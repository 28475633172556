<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3) }}</div>
<div class="lv-title">
	<a [routerLink]="['/Vehicule/loadEtatLieuxTemplate',data.idEtatLieuxTemplate]">{{ data.libelle + ' (' + data.reference + ')' }}</a>
</div>
<ul class="lv-attrs">
	<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	<li><span translate>vehicule.etatLieuxTemplate.defaut.item</span>&#160;:&#160;<span [translate]="'common.'+(data?.defaut ? 'oui' : 'non')"></span></li>
	<li><span translate>vehicule.etatLieuxTemplate.typeAffectation.item</span>&#160;:&#160;<span [translate]="'vehicule.etatLieuxTemplate.typeAffectation.'+data.typeAffectation"></span></li>
</ul>
<div class="lv-actions actions dropdown">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="duplicateTemplate()"><span translate>actions.dupliquer</span></a></li>
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteTemplate()"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>