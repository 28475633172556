import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable,tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Step } from 'src/app/domain/common/stepper/step';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { DashboardChartConfigurationDataComponent } from './dashboard-chart-configuration-data.component';
import { DashboardService } from './dashboard.service';
import { DashboardChartConfigurationGraphiqueComponent } from './dashboard-chart-configuration-graphique.component';
import { Result, TypeCodeErreur } from 'src/app/domain/common/http/result';
import { DashboardChartConfigurationFiltreComponent } from './dashboard-chart-configuration-filtre.component';
import { EntiteService } from 'src/app/share/components/entite/entite.service';

@Component({
	selector: 'dashboard-chart-configuration',
	templateUrl: './dashboard-chart-configuration.component.html'
})
export class DashboardChartConfigurationComponent implements AfterContentChecked,OnInit {
	/** Identifiant du graphique **/
	@Input() idChart: number;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/** Section initialement choisie **/
	public selectedSection: any;

	/** Graphique **/
	public chart: any;

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<DashboardChartConfigurationComponent>,private dashboardService: DashboardService,private entiteService: EntiteService,public translateService: TranslateService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement du graphique
		this.loadData(this.idChart).subscribe({
			next: () => {
				//Définition de la liste des étapes
				this.listeSteps = [{
					type: 'DATA',
					component: DashboardChartConfigurationDataComponent,
					libelle: () => `${this.translateService.instant('dashboard.chart.configuration.data.title')} (${this.entiteService.translateEntityCode(this.chart?.extraction?.entityName.split('.').pop(-1))})`,
					retrieveComponentData: () => ({
						chart: this.chart
					}),
					canActivate: () => true
				},{
					type: 'FILTRE',
					component: DashboardChartConfigurationFiltreComponent,
					libelle: 'dashboard.chart.configuration.filtre.title',
					retrieveComponentData: () => ({
						chart: this.chart
					}),
					canActivate: () => true
				},{
					type: 'GRAPHIQUE_PRINCIPAL',
					component: DashboardChartConfigurationGraphiqueComponent,
					libelle: 'dashboard.chart.configuration.graphique.principal',
					retrieveComponentData: () => ({
						chart: this.chart,
						indexDefinition: 0
					}),
					canActivate: () => true
				},this.chart?.listeDefinitions?.length > 1 && {
					type: 'GRAPHIQUE_SECONDAIRE',
					component: DashboardChartConfigurationGraphiqueComponent,
					libelle: 'dashboard.chart.configuration.graphique.secondaire',
					retrieveComponentData: () => ({
						chart: this.chart,
						indexDefinition: 1
					}),
					canActivate: () => true
				}].filter(s => !!s);

				//Section initialement choisie
				this.selectedSection = this.listeSteps[0];
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idChart: number): Observable<Result> {
		//Chargement du graphique
		return this.dashboardService.loadChart(idChart).pipe(tap(result => {
			//Vérification du chargement
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
				//Définition de la facture
				this.chart = result.data?.chart;
		}));
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}