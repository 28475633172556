import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { ShareModule } from 'src/app/share/share.module';
import { DashboardCalendarCustomizerComponent } from './dashboard-calendar-customizer.component';
import { DashboardCalendarComponent } from './dashboard-calendar.component';
import { DashboardChartComponent } from './dashboard-chart.component';
import { DashboardRedirectorComponent } from './dashboard-redirector.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardEffects } from './dashboard.effects';
import { DashboardService } from './dashboard.service';
import { DashboardFavorisModule } from './favoris/dashboard-favoris.module';
import { DashboardChartCustomizerComponent } from './dashboard-chart-customizer.component';
import { DashboardChartCustomizerColorComponent } from './dashboard-chart-customizer-color.component';
import { DashboardEmbeddedComponent } from './dashboard-embedded.component';
import { DashboardChartConfigurationComponent } from './dashboard-chart-configuration.component';
import { DashboardChartConfigurationDataComponent } from './dashboard-chart-configuration-data.component';
import { ChartDefinitionModule } from '../chart/definition/chart-definition.module';
import { DashboardChartConfigurationGraphiqueComponent } from './dashboard-chart-configuration-graphique.component';
import { DashboardChartConfigurationFiltreComponent } from './dashboard-chart-configuration-filtre.component';

@NgModule({
	imports: [ShareModule,EffectsModule.forFeature([DashboardEffects]),DashboardFavorisModule,ChartDefinitionModule],
	declarations: [DashboardComponent,DashboardChartComponent,DashboardRedirectorComponent,DashboardCalendarComponent,DashboardCalendarCustomizerComponent,DashboardChartCustomizerComponent,DashboardChartCustomizerColorComponent,DashboardEmbeddedComponent,DashboardChartConfigurationComponent,DashboardChartConfigurationDataComponent,DashboardChartConfigurationGraphiqueComponent,DashboardChartConfigurationFiltreComponent],
	exports: [DashboardChartComponent],
	providers: [DashboardService]
})
export class DashboardModule {}
