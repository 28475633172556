import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EtatLieuxTemplateEditComponent } from './etat-lieux-template-edit.component';
import { EtatLieuxTemplateService } from './etat-lieux-template.service';
import { EtatLieuxTemplateEtatVehiculeComponent } from './etat-lieux-template-etat-vehicule.component';
import { TypeDroit } from 'src/app/domain/security/right';
import { EtatLieuxTemplateDetailComponent } from './etat-lieux-template-detail.component';

@Component({
	selector: 'etat-lieux-template',
	templateUrl: './etat-lieux-template.component.html'
})
export class EtatLieuxTemplateComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Template courant **/
	public template: any;

	/** Liste des détails fusionnés **/
	public listeDetailsMerged: Array<any> = [];

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private etatLieuxTemplateService: EtatLieuxTemplateService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.etatLieux.EtatLieuxTemplate';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.template?.idEtatLieuxTemplate || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'vehicule.etatLieuxTemplate.libelle',
				key: 'libelle'
			},{
				libelle: 'vehicule.etatLieuxTemplate.reference',
				key: 'reference'
			},{
				libelle: 'vehicule.etatLieuxTemplate.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editTemplate(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editTemplate(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteTemplate(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			},{
				libelle: 'actions.dupliquer',
				doAction: () => this.duplicateTemplate(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'view_column',
				title: this.translateService.instant('vehicule.etatLieuxTemplate.alerte.etatVehicule.title'),
				message: this.translateService.instant('vehicule.etatLieuxTemplate.alerte.etatVehicule.message'),
				isVisible: () => !this.template?.etatVehicule,
				doAction: () => this.editEtatVehicule(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeElements: [{
				type: 'ETAT_VEHICULE',
				libelle: 'vehicule.etatLieuxTemplate.etatVehicule.element',
				component: EtatLieuxTemplateEtatVehiculeComponent,
				retrieveComponentData: () => ({
					template: this.template,
					saveTemplate: this.saveTemplate.bind(this),
					isEdition: false
				}),
				isVisible: () => this.rightService.hasRight(TypeDroit.ETAT_LIEUX,'consultation'),
				doAction: () => this.editEtatVehicule(),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				libelleAction: 'vehicule.etatLieuxTemplate.etatVehicule.add'
			},{
				type: 'DETAIL',
				libelle: 'vehicule.etatLieuxTemplate.detail.element',
				component: EtatLieuxTemplateDetailComponent,
				retrieveComponentData: () => ({
					template: this.template,
					saveTemplate: this.saveTemplate.bind(this),
					isEdition: false
				}),
				isVisible: () => this.rightService.hasRight(TypeDroit.ETAT_LIEUX,'consultation'),
				doAction: () => this.editDetail(),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				libelleAction: 'vehicule.etatLieuxTemplate.detail.add',
				count: () => this.template.listeDetails?.length || 0
			}]
		};

		//Récupération de l'identifiant de l'objet
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idEtatLieuxTemplate || 0);
			}
		});
	}

	/**
	 * Edition du template
	 */
	editTemplate() {
		//Ouverture du composant d'édition
		this.pageContentService.open(EtatLieuxTemplateEditComponent,{
			data: {
				template: this.template.idEtatLieuxTemplate ? cloneDeep(this.template) : this.template,
				deleteTemplate: this.deleteTemplate.bind(this)
			}
		}).subscribe({
			next: data => {
				//Vérification des données
				if (data) {
					//Mise à jour du template
					this.template = data;

					//Redirection vers le template créé
					this.layoutService.replaceUrlWith({
						idEtatLieuxTemplate: data.idEtatLieuxTemplate
					},true);
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idEtatLieuxTemplate: number) {
		//Chargement du template
		this.etatLieuxTemplateService.loadTemplate(idEtatLieuxTemplate).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du template
					this.template = result.data.template;

					//Vérification du template
					if (!this.template?.idEtatLieuxTemplate) {
						//Création d'un template vierge
						this.template = {
							typeAffectation: 'TOUS'
						};

						//Edition du template
						this.editTemplate();
					} else
						//Récupération des détails fusionnés
						this.listeDetailsMerged = result.data.listeDetailsMerged?.map(item => ({ ...item,visible: !!item.idDetail }));
				}
			}
		});
	}

	/**
	 * Suppression de l'objet
	 */
	private deleteTemplate() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.etatLieuxTemplateService.deleteTemplate(this.template))
		).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					delete this.template;

					//Fermeture de la page
					this.layoutService.goBack();
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Duplication du template
	 */
	private duplicateTemplate() {
		//Duplication du template
		this.etatLieuxTemplateService.duplicateTemplate(this.template).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Redirection vers le template dupliqué
				this.layoutService.replaceUrlWith({
					idEtatLieuxTemplate: result.data.template.idEtatLieuxTemplate
				},true);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}


	/**
	 * Enregistrement du template
	 */
	saveTemplate(template: any,close: Function) {
		//Enregistrement du template
		this.etatLieuxTemplateService.saveTemplate(template).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du template
					this.template = result.data.template;

					//Fermeture du formulaire (si nécessaire)
					close?.(this.template);

					//Mise à jour des détails fusionnés
					this.listeDetailsMerged = result.data.listeDetailsMerged?.map(item => ({ ...item,visible: !!item.idDetail }));
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Vérification de l'état (actif et défaut)
					if (result.data.doublon & Math.pow(2,2))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.actif'),this.translateService.instant('actions.doublon.defaut'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Modification du contrôle de l'état du véhicule
	 */
	private editEtatVehicule() {
		//Ouverture du composant d'édition
		this.pageContentService.open(EtatLieuxTemplateEtatVehiculeComponent,{
			data: {
				template: {
					...this.template,
					etatVehicule: this.template.etatVehicule || {
						exterieur: true,
						interieur: true,
						exterieurPhotoObligatoire: true,
						interieurPhotoObligatoire: true,
						typeMasqueInterieur: 'QUATRE_ZONES',
						typeMasqueExterieur: 'HUIT_ZONES'
					}
				},
				saveTemplate: this.saveTemplate.bind(this),
				isEdition: true
			}
		},'sub').subscribe({
			next: (data) => {
				//Vérification des données
				if (data)
					//Mise à jour du template
					this.template = data;

				//Sélection de l'onglet
				this.complexPage.setSelectedElementByType('ETAT_VEHICULE',true);
			}
		});
	}

	/**
	 * Modification d'un détail
	 */
	private editDetail() {
		//Ouverture du composant d'édition
		this.pageContentService.open(EtatLieuxTemplateDetailComponent,{
			data: {
				template: this.template,
				saveTemplate: this.saveTemplate.bind(this),
				listeDetailsMerged: this.listeDetailsMerged,
				isEdition: true
			}
		},'sub').subscribe({
			next: (data) => {
				//Vérification des données
				if (data)
					//Mise à jour du template
					this.template = data;

				//Sélection de l'onglet
				this.complexPage.setSelectedElementByType('DETAIL',true);
			}
		});
	}
}
