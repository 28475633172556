<form #abattementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!abattement.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
							<label translate>fiscalite.abattement.dateDebut</label>
							<div class="input-group half">
								<input type="text" name="dateDebut" #dateDebut="ngModel" class="form-control" [(ngModel)]="abattement.dateDebut" datePicker format="date" [storeDate]="true" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': abattementField.invalid }">
							<label translate>fiscalite.abattement.abattement</label>
							<div class="input-group half">
								<input type="text" name="abattement" #abattementField="ngModel" class="form-control" [(ngModel)]="abattement.abattement" nNumber="2" [min]="0" [max]="100" required/>
								<span class="input-group-addon no-border">%</span>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': montantAbattement.invalid }">
							<label translate>fiscalite.abattement.montantAbattement</label>
							<div class="input-group half">
								<input type="text" name="montantAbattement" #montantAbattement="ngModel" class="form-control" [(ngModel)]="abattement.montantAbattement" nNumber="2" required/>
								<span class="input-group-addon no-border">EUR</span>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: abattementForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteAbattement();" *ngIf="abattement?.idAbattement && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveAbattement();" *ngIf="null | right:'creation'" [disabled]="abattementForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>