<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idEntretien">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
			<ng-container *ngIf="!data.previsionnel">
				<ng-container [ngSwitch]="entretienService.retrieveStateForEntretien(data)">
					<icon name="check" *ngSwitchCase="'FAVORABLE'"></icon>
					<icon name="hourglass_bottom" *ngSwitchCase="'PENDING'"></icon>
					<icon name="close" *ngSwitchCase="'DEFAVORABLE'"></icon>
				</ng-container>
			</ng-container>
			<icon name="warning" *ngIf="data.previsionnel"></icon>
		</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">
				<span>{{ (data.typeEntretienItem?.libelle || (data.type == 'CONTROLE_REGLEMENTAIRE' ? ('vehicule.controleReglementaire.type.'+data.sousType | translate) : ('entretien.type.'+data.type | translate))) + ' - ' + data.vehicule.reference + ' (' + ((data.previsionnel ? data.datePrevisionnelle : data.dateRealisation) | date:'MMMM yyyy' | capitalizeFirst) + ')' }}</span>
			</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idEntretien">
		<li *ngIf="!data.previsionnel && ['PENDING','DEFAVORABLE'].includes(entretienService.retrieveStateForEntretien(data))"><span translate>entretien.contreVisite.typeAvisControle.item</span>&#160;:&#160;<span translate>entretien.contreVisite.typeAvisControle.DEFAVORABLE</span></li>
		<li *ngIf="!data.previsionnel"><span translate>entretien.realisation</span>&#160;:&#160;<span>{{ data.dateRealisation | date:'shortDate' }}</span></li>
		<li *ngIf="data.previsionnel && data.type != 'CONTROLE_REGLEMENTAIRE'"><span translate>entretien.prevision</span>&#160;:&#160;<span>{{ data.datePrevisionnelle | date:'shortDate' }}</span></li>
		<li *ngIf="(data.type == 'COURANT' || !data.previsionnel) && data.fournisseur"><span translate>entretien.fournisseur</span>&#160;:&#160;<span>{{ data.fournisseur.libelle }}</span></li>
		<li *ngIf="!data.previsionnel && (data.compteur || data.compteurHoraire)"><span translate>entretien.releveCompteur</span>&#160;:&#160;<span>{{ data.vehicule.uniteUsage == 'DISTANCE' ? (data.compteur | number) : (data.compteurHoraire | number) }}&#160;{{ data.vehicule.uniteUsage == 'DISTANCE' ? (data.vehicule.unite?.libelleCourt || user.unite?.libelleCourt || '') : ('duree.heure.libelleCourt' | translate) }}</span></li>
		<li *ngIf="data.extension?.userAffecte"><span translate>entretien.userAffecte</span>&#160;:&#160;{{ data.extension.userAffecte | display:'user' }}</li>
		<li *ngIf="data.type == 'CONTROLE_REGLEMENTAIRE' && data.dateDebut && data.previsionnel"><span translate>entretien.periodeRealisation.item</span>&#160;:&#160;<span translate [translateParams]="{ dateDebut: data.dateDebut | date:'shortDate',dateFin: data.datePrevisionnelle | date:'shortDate' }">entretien.periodeRealisation.message</span></li>
		<li *ngIf="data.dateRdv"><span translate>entretien.dateRdv</span>&#160;:&#160;<span>{{ data.dateRdv | date:'short' }}</span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="data.idEntretien && !liste.nbSelectedItems && user?.type != 'VIRTUAL'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li *ngIf="(null | right:'suppression') && (data.type == 'COURANT' || !data.previsionnel)"><a [routerLink]="[]" (click)="deleteEntretien()" translate>actions.supprimer</a></li>
			<li *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'consultation') && data.vehicule"><a [routerLink]="[]" (click)="goToVehicule()" translate>entretien.actions.accederVehicule</a></li>
			<li *ngIf="(TypeDroit.FACTURE | right:'consultation') && data.typeOrigine == 'FACTURE'"><a [routerLink]="[]" (click)="goToFacture()" translate>entretien.actions.accederFacture</a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<entretien [entretien]="data" (onObjectChange)="onObjectChange($event)"></entretien>
</div>