import { Component,ContentChild,DoCheck,ElementRef,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ActivatedRoute,Data } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEqual,some } from 'lodash-es';
import { Observable,Subscription,combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Filter,ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ListItem } from 'src/app/domain/common/list-view/list-item';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { Selector } from 'src/app/domain/common/list-view/selector';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { NavigationState,UPDATE_OPEN_CREATION,UPDATE_SELECTED_SELECTOR } from 'src/app/reducers/navigation';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { AutocompleteService } from '../autocomplete/autocomplete.service';
import { ListViewFooterComponent } from './list-view-footer.component';
import { ListViewHeaderComponent } from './list-view-header.component';
import { ListViewService } from './list-view.service';

@Component({
	selector: 'list-view',
	templateUrl: './list-view.component.html'
})
export class ListViewComponent<T extends ListItem,K extends ListViewItem<T>> implements OnInit,DoCheck {
	/** Liste à afficher **/
	@Input() liste: ListView<T,K>;

	/** Interception d'une suppression **/
	@Output() onItemRemove: EventEmitter<T> = new EventEmitter<T>();

	/** Header **/
	@ContentChild(ListViewHeaderComponent)
	header: ListViewHeaderComponent;

	/** Footer **/
	@ContentChild(ListViewFooterComponent)
	footer: ListViewFooterComponent;

	/** Paramètres de la route **/
	routeData$: Observable<Data>;

	/** Fil d'ariane **/
	filAriane$: Observable<string>;

	/** Mapping des entités **/
	private mapEntites: any;

	/** Mémorisation de l'URI de récupération des données **/
	private oldUri: string;

	/** Mémorisation des filtres statiques **/
	private oldListeStaticFilters: Array<Filter>;

	/** Indicateur de demande d'ajout d'un élément **/
	private isOpenCreation: boolean = false;

	/** Données associées à la demande d'ajout d'un élément **/
	private creationData: any;

	/** Souscription au chargement de données supplémentaires après un scroll en bout de liste **/
	private onEndReachedSubscription: Subscription = null;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private listViewService: ListViewService<T>,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private layoutService: LayoutService,private store: Store<AppState>,private autocompleteService: AutocompleteService
			,private extractionService: ExtractionService,private notificationService: NotificationService) {
		//Binding
		this.onDisplayChange = this.onDisplayChange.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.onEndReached = this.onEndReached.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let componentInstance: any;

		//Vérification de la liste
		if (this.liste) {
			//Mémorisation de l'URI de récupération des données initiale
			this.oldUri = typeof this.liste.uri == 'function' ? this.liste.uri() : null;

			//Vérification de la présence d'un composant
			if (this.liste.component) {
				//Création d'une instance du composant de la liste
				componentInstance = new this.liste.component();

				//Vérification du type de composant instancié
				if (this.isIListEntity(componentInstance) && !this.liste.noSearch && !this.liste.isLocal) {
					//Récupération du mapping pour le composant
					this.mapEntites = componentInstance.getMapEntites();

					//Vérification de la présence d'extractions et ajout de l'action à la liste
					this.extractionService.addActionToListe(this.liste,this.mapEntites);

					//Vérification de la présence de notifications et ajout de l'action à la liste
					this.notificationService.addActionToListe(this.liste,this.mapEntites);
				}
			}

			//Récupération des paramètres de la route
			this.routeData$ = this.activatedRoute.data;

			//Récupération du fil d'ariane
			this.filAriane$ = this.store.select<string>(s => s.layout?.filAriane);

			//Vérification de l'absence d'ordre
			if (!this.liste.defaultOrder)
				//Définition du tri par défaut
				this.liste.defaultOrder = this.liste.listeFilters?.filter(f => f.isDefault).map(f => {
					//Vérification du type de filtre en cas d'absence de la clé
					if (!f.clef && f.type == TypeFilter.AUTOCOMPLETE) {
						//Récupération des options de l'autocomplete
						f.autocomplete.options = this.autocompleteService.getOptionsForType(f.autocomplete.type);

						//Retour du nom de l'identifiant
						return f.autocomplete.options.getKeyFieldName();
					} else
						//Retour direct de la clé dans le cas général
						return f.clef
				}).join(',');

			//Mise à jour des autocompletes et définition de la recherche par défaut
			this.liste.defaultSearch = this.liste.listeFilters?.map(f => {
				let optionalClef;

				//Vérification du type de filtre
				if (f.type == TypeFilter.AUTOCOMPLETE) {
					//Récupération des options de l'autocomplete
					f.autocomplete.options = this.autocompleteService.getOptionsForType(f.autocomplete.type);

					//Définition de la clef en mode 'jointures externes'
					optionalClef = (f.clef || '').split('.').map(subClef => subClef.startsWith('*') ? subClef : `*${subClef}`).join('.');

					//Récupération des filtres de l'autocomplete
					return f.isDefault && (f.autocomplete?.fieldForDefault?.split?.(',').map?.(field => `${f.clef ? (field.startsWith('*') && optionalClef || f.clef) + '.' : ''}${field.replace('*','')}`)?.join?.(',') || f.autocomplete.options?.listeFilters().filter(af => af.isDefault).map(af => `${f.clef ? f.clef + '.' : ''}${af.clef}`).join(',') || '');
				} else if (f.type == TypeFilter.MONTH) {
					//Définition du type de date
					f.dateOptions = {
						mode: 'month'
					};
				} else
					//Retour de la clef
					return f.isDefault && f.clef || '';
			}).filter(f => f?.length).join(',');

			//Définition des champs pour lesquels la recherche par défaut doit être étendue à leur valeur chiffrée
			this.liste.mapDefaultSearchEncryptedFields = some(this.liste.listeFilters || [],f => !!f.mapEncryptedFields) ? Object.assign({},...this.liste.listeFilters.map(f => f.mapEncryptedFields || {})) : null;

			//Mémorisation de la liste des filtres statics initiale
			this.oldListeStaticFilters = typeof this.liste.listeStaticFilters == 'function' ? this.liste.listeStaticFilters() : null;

			//Gestion des traductions
			this.liste.listeFilters?.filter(f => !f.title).forEach(f => {
				//Récupération de la traduction
				f.title = this.translateService.instant(`search.${(f.clef.indexOf('.') != -1 ? f.clef.substring(0,f.clef.indexOf('.')) : f.clef).replace(/[-*]/g,'')}`);
			});

			//Gestion des booléens
			this.liste.listeFilters?.filter(f => f.type == TypeFilter[TypeFilter.BOOLEAN]).forEach(f => {
				//Définition du type de comparaison
				f.typeComparaison = TypeComparaison[TypeComparaison.EQUAL];

				//Définition de la liste des valeurs
				f.listeValues = [{
					code: 'true',
					libelle: this.translateService.instant('common.oui')
				},{
					code: 'false',
					libelle: this.translateService.instant('common.non')
				}];
			});

			//Chargement des filtres mémorisés
			!this.liste.isLocal && this.listViewService.loadListeFilters(this.liste);

			//Récupération des informations de navigation
			combineLatest([this.store.select<NavigationState>(s => s.navigation)
				,this.routeData$
			]).pipe(first()).subscribe(([navigation,activatedRouteData]) => {
				let currentState: string;
				let defaultSelector: any;
				let cachedSelector: any;

				//Récupération de la route active
				currentState = activatedRouteData?.state;

				//Récupération du sélecteur actif par défaut de la liste
				defaultSelector = this.liste.selectedSelector;

				//Vérification de la demande de création d'un élément
				if (navigation.openCreation && currentState == navigation.openCreation) {
					//Remise à zéro de l'indicateur
					this.store.dispatch({
						type: UPDATE_OPEN_CREATION,
						payload: null
					});

					//Demande d'ajout d'un élément
					this.isOpenCreation = true;

					//Définition des propriétés de création
					this.creationData = navigation.creationData;
				}

				//Vérification de la nécessité de réactiver le sélecteur mis en cache
				if (!this.isOpenCreation && currentState && !!(cachedSelector = navigation.mapSelectedSelectors[currentState])) {
					//Vérification que le sélecteur mémorisé est toujours disponible
					if ((this.liste.listeSelectors || []).find(s => s.code == cachedSelector))
						//Réactivation du sélecteur mémorisé
						this.liste.selectedSelector = cachedSelector;
				}

				//Vérification de la nécessité de forcer le chargement initial des données
				if ((typeof defaultSelector == 'undefined' || defaultSelector == this.liste.selectedSelector) && !this.liste.noSearch)
					//Rafraichissement de la liste
					this.refreshData();
			});

			//Binding des fonctions
			this.liste.refresh = this.refreshData.bind(this);
			this.liste.addItem = this.addItem.bind(this);
		}
	}

	/**
	 * Vérification des changements
	 */
	ngDoCheck() {
		let newUri: string;
		let newListeStaticFilters: Array<Filter>;

		//Vérification que l'URI de récupération des données a changé
		if (typeof this.liste.uri == 'function' && this.oldUri !== (newUri = this.liste.uri())) {
			//Mise à jour de la dernière URI contactée
			this.oldUri = newUri;

			//Rafraichissement de la liste
			this.refreshData();
		} else if (typeof this.liste.listeStaticFilters == 'function' && !isEqual(this.oldListeStaticFilters,newListeStaticFilters = this.liste.listeStaticFilters())) {
			//Mise à jour des filtres statiques
			this.oldListeStaticFilters = newListeStaticFilters;

			//Rafraichissement de la liste
			this.refreshData();
		}
	}

	/**
	 * Rafraîchissement de la liste
	 */
	refreshData(isResetFilters: boolean = false) {
		//Vérification qu'un chargement est déjà en cours
		if (this.liste.refreshDataSubscription != null || this.onEndReachedSubscription != null) {
			//Annulation des requêtes en cours
			this.liste.refreshDataSubscription?.unsubscribe();
			this.onEndReachedSubscription?.unsubscribe();

			//Réinitialisation des souscriptions
			this.liste.refreshDataSubscription = null;
			this.onEndReachedSubscription = null;
		}

		//Vérification du rafraichissement avant chargement
		this.liste.onBeforeRefresh?.(this.liste);

		//Vidage de la liste
		this.liste.data = null;

		//Suppression des actions de masse
		this.liste.nbSelectedItems = 0;

		//Remise à zéro du numéro de page
		this.liste.numPage = 0;

		//Vérification de la demande de remise à zéro des filtres
		if (isResetFilters) {
			//Suppression des filtres
			this.liste.listeSelectedFilters.length = 0;

			//Suppression des données supplémentaires
			this.liste.extraData = null;
		}

		//Enregistrement des filtres pour la liste
		!this.liste.isLocal && this.listViewService.saveListeFilters(this.liste);

		//Chargement de la liste
		this.liste.refreshDataSubscription = this.listViewService.loadData(this.liste).pipe(first()).subscribe({
			next: (data) => {
				let openCreationData: any;

				//Mise à jour de la liste
				this.liste.data = data;

				//Vérification de la demande d'ajout d'un élément
				if (this.isOpenCreation) {
					//Remise à zéro de l'indicateur
					this.isOpenCreation = false;

					//Récupération des données fournies pour l'ajout
					openCreationData = history.state.openCreationData;

					//Ajout d'un élément si possible
					(!this.liste.hasMainAction || this.liste.hasMainAction()) && this.liste.doMainAction?.(openCreationData || this.creationData);
				}

				//Notification du rafraichissement
				this.liste.onRefresh?.(this.liste,data);

				//Vérification de l'indicateur d'ouverture du premier élément de la liste
				if (!this.liste.isLocal && this.layoutService.checkAndResetOpenFirstItem()) {
					//Mise en cycle
					setTimeout(() => {
						//Clic sur le premier élément de la liste
						this.elementRef.nativeElement.querySelector('.lv-body .lv-title a')?.click?.();
					});
				}
			},
			complete: () => {
				//Chargement terminé
				this.liste.refreshDataSubscription = null;
			}
		});
	}

	/**
	 * Ajout d'un élément à la liste
	 */
	addItem(item: T) {
		//Ajout de l'élément en début de liste
		this.liste.data.content = [item,...(this.liste.data?.content || [])];
	}

	/**
	 * Création d'un nouvel item
	 */
	retrieveNewData(Type: (new () => T)) {
		let data: T;
		let newItem: T;

		//Création du nouvel élément
		newItem = new Type();

		//Affichage du nouvel élément
		newItem.isDisplayed = true;

		//Récupération du premier élément de la liste
		data = this.liste.data.content && this.liste.data.content.length > 0 ? this.liste.data.content[0] : null;

		//Vérification de la présence d'un élément
		if (!data || data && newItem.getKey(data))
			//Insertion d'un nouvel élément en début de liste
			this.liste.data.content.splice(0,0,newItem);
	}

	/**
	 * Écoute de la suppression d'un élément
	 */
	onRemove(data: T) {
		let idx: number;

		//Récupération de l'index de l'élément
		idx = this.liste.data.content.findIndex(d => d === data);

		//Vérification de la présence de l'élément
		if (idx > -1) {
			//Suppression de l'élément
			this.liste.data.content.splice(idx,1);

			//Notification d'une suppression
			this.onItemRemove.emit(data);
		}
	}

	/**
	 * Masquage d'un élément
	 */
	onDisplayChange(data: T) {
		let idx: number;

		//Récupération de l'index de l'élément
		idx = this.liste.data.content.findIndex(d => d === data);

		//Vérification de la présence de l'élément
		if (idx > -1)
			//Masquage de l'élément
			this.liste.data.content[idx].isDisplayed = false;
		else if (data && this.liste.data.content[0].isDisplayed)
			//Mise à jour de l'élément
			this.liste.data.content[0] = data;
	}

	/**
	 * Détection du scroll en bout de liste
	 */
	onEndReached() {
		let content: Array<any>;

		//Vérification de la position
		if (!this.liste.isLoadingDisabled && this.liste.data && this.onEndReachedSubscription == null && !this.liste.data.last) {
			//Vérification du rafraichissement avant chargement
			this.liste.onBeforeRefresh?.(this.liste);

			//Chargement de la liste
			this.onEndReachedSubscription = this.listViewService.loadData(this.liste,++this.liste.numPage).subscribe({
				next: (data) => {
					//Récupération de l'ancien contenu
					content = this.liste.data.content;

					//Mise à jour du contenu
					this.liste.data = data;

					//Concaténation des listes
					this.liste.data.content = [...content,...this.liste.data.content];

					//Notification du rafraichissement
					this.liste.onRefresh?.(this.liste,data);
				},
				complete: () => {
					//Chargement terminé
					this.onEndReachedSubscription = null;
				}
			});
		}
	}

	/**
	 * Retour en arrière
	 */
	goBack(state: string) {
		//Retour en arrière
		this.layoutService.goToByState(state);
	}

	/**
	 * Récupération du contenu de la liste à afficher
	 */
	getContentToDisplay(): Array<T> {
		//Retour du contenu à afficher
		return this.liste.generateDisplayedContent?.(this.liste) || this.liste.data?.content;
	}

	/**
	 * Navigation en haut de l'écran
	 */
	scrollToTop() {
		//Navigation en haut de l'écran
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	/**
	 * Modification du sélecteur actif
	 */
	selectSelector(selector: Selector) {
		//Définition du sélecteur actif
		this.liste.selectedSelector = selector.code;

		//Récupération de la route courante
		this.routeData$.pipe(first()).subscribe(activatedRouteData => {
			//Mémorisation du sélecteur choisi pour la route
			this.store.dispatch({
				type: UPDATE_SELECTED_SELECTOR,
				payload: {
					state: activatedRouteData?.state,
					selector: selector.code
				}
			});
		});
	}

	/**
	 * Vérification du type 'IListEntity'
	 */
	private isIListEntity(instance: any): instance is IListEntity {
		//Vérification du type 'IListEntity'
		return (instance as IListEntity).getMapEntites !== undefined;
	}
}