import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { Filter,ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ModeleListItemComponent } from './modele-list-item.component';
import { ModeleService } from './modele.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';
import { TypeModule } from 'src/app/domain/connecteur/type-module';

@Component({
	templateUrl: './modele-list.component.html'
})
export class ModeleListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ModeleListItemComponent>;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/** Résumé **/
	resume: { nbCatalogue: number,nbHorsCatalogue: number,nbTous: number } = {
		nbCatalogue: 0,
		nbHorsCatalogue: 0,
		nbTous: 0
	};

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private modeleService: ModeleService,public connecteurService: ConnecteurService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ModeleListItemComponent>({
			uri: () => `/controller/VehiculeReferentiel/filtreModeles/${(!this.liste?.selectedSelector || this.liste?.selectedSelector == 'TOUS' ? '' : this.liste?.selectedSelector)}`,
			title: this.translateService.instant('vehicule.modele.liste.title'),
			component: ModeleListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'marque',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'marque'
				},
				isDefault: true,
			},{
				clef: 'actif',
				type: TypeFilter.BOOLEAN
			},this.rightService.isRoot() && {
				clef: 'custom',
				type: TypeFilter.BOOLEAN
			},this.rightService.isRoot() && {
				clef: 'nbPortes',
				type: TypeFilter.LONG,
				listeValues: [2,3,4,5].map(i => ({ code: i.toString(),libelle: i.toString() })),
				isListeValuesWithButton: true
			},this.rightService.isRoot() && {
				clef: 'finition',
				type: TypeFilter.STRING
			},this.rightService.isRoot() && {
				clef: 'modeleNiveau1',
				type: TypeFilter.STRING
			},this.rightService.isRoot() && {
				clef: 'modeleNiveau2',
				type: TypeFilter.STRING
			},this.rightService.isRoot() && {
				clef: 'modeleNiveau3',
				type: TypeFilter.STRING
			},this.rightService.isRoot() && {
				clef: 'information.commercialisationPrix.dateFin',
				type: TypeFilter.DATE,
				title: this.translateService.instant('vehicule.modele.dateFinCommercialisation'),
				dateOptions: {
					format: 'date',
					isStoreDate: true
				}
			} as Filter,this.rightService.isRoot() && {
				clef: 'carburant',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'carburant'
				},
			}].filter(f => !!f),
			getKeyFieldName: () => 'idModele',
			defaultOrder: 'marque.libelle.raw,libelle.raw,reference',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idModele) && this.liste.addItem({
					isDisplayed: true,
					actif: true,
					typeSource: this.rightService.isRoot() ? 'EXTERNE_IMMATRICULATION' : 'LOCAL'
				});
			},
			listeSelectors: this.rightService.isRoot() ? [{
				code: 'TOUS',
				libelle: this.translateService.instant('vehicule.modele.typeSource.TOUS'),
				count: () => this.resume.nbTous
			},{
				code: 'HORS_CATALOGUE',
				libelle: this.translateService.instant('vehicule.modele.typeSource.ADMINISTRATIF'),
				count: () => this.resume.nbHorsCatalogue
			},{
				code: 'CATALOGUE',
				libelle: this.translateService.instant('vehicule.modele.typeSource.CONSTRUCTEUR'),
				count: () => this.resume.nbCatalogue
			}] : [],
			selectedSelector: !this.rightService.isRoot() ? 'HORS_CATALOGUE/LOCAL' : 'TOUS',
			extraOptions: {
				resume: this.resume,
				onModeleAdd: this.onModeleAdd
			},
			onRefresh: (liste,result) => {
				//Vérification du tenant
				if (this.rightService.isRoot()) {
					//Chargement du résumé
					this.modeleService.loadResume(liste.selectedSelector == 'HORS_CATALOGUE' ? 'CATALOGUE' : 'HORS_CATALOGUE',result.searchSpec).subscribe({
						next: (resultData: Result) => {
							//Vérification du type de liste
							if (liste.selectedSelector === 'CATALOGUE') {
								//Définition des compteurs
								this.resume.nbCatalogue = result?.totalElements || 0;
								this.resume.nbHorsCatalogue = resultData?.data?.count || 0;
								this.resume.nbTous = this.resume.nbHorsCatalogue + this.resume.nbCatalogue;
							} else if (liste.selectedSelector === 'HORS_CATALOGUE') {
								//Définition des compteurs
								this.resume.nbHorsCatalogue = result?.totalElements || 0;
								this.resume.nbCatalogue = resultData?.data?.count || 0;
								this.resume.nbTous = this.resume.nbHorsCatalogue + this.resume.nbCatalogue;
							} else if (liste.selectedSelector === 'TOUS') {
								//Définition des compteurs
								this.resume.nbTous = result?.totalElements || 0;
								this.resume.nbHorsCatalogue = resultData?.data?.count || 0;
								this.resume.nbCatalogue = this.resume.nbTous - this.resume.nbHorsCatalogue;
							}
						}
					});
				}
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable(TypeModule.VEHICULE_REFERENTIEL).pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}

	/**
	 * Interception de la suppression
	 */
	onItemRemove(item) {
		//Vérification de l'élément
		if (item.idModele) {
			//Mise à jour des compteurs
			this.resume.nbTous -= 1;

			//Vérification du type
			if (item.typeSource === 'EXTERNE_CATALOGUE')
				//Mise à jour des compteurs
				this.resume.nbCatalogue -= 1;
			else
				//Mise à jour des compteurs
				this.resume.nbHorsCatalogue -= 1;
		}
	}

	/**
	 * Interception de l'ajout
	 */
	onModeleAdd(item: any) {
		//Vérification de l'élément
		if (item.idModele) {
			//Mise à jour des compteurs
			this.resume.nbTous += 1;

			//Vérification du type
			if (item.typeSource === 'EXTERNE_CATALOGUE')
				//Mise à jour des compteurs
				this.resume.nbCatalogue += 1;
			else
				//Mise à jour des compteurs
				this.resume.nbHorsCatalogue += 1;
		}
	}
}