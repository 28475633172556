<div *ngIf="!vertical">
	<ul class="stepper">
		<li *ngFor="let step of listeSteps; let idxStep = index;" class="step" (click)="step.canActivate() && selectStep(step);" [class.active]="selectedStep?.type == step.type" [class.forbidden]="!step.canActivate()" [class.done]="step.isDone()">
			<span class="content">
				<span class="marker">{{ idxStep + 1 }}</span>
				<span>{{ getLibelleForStep(step) }}</span>
			</span>
		</li>
	</ul>
</div>
<div *ngIf="!vertical">
	<template #holder></template>
</div>
<div class="container-fluid p-0" *ngIf="vertical">
	<div class="row">
		<div class="col-md-3">
			<ul class="nav nav-pills nav-stacked p-0" [ngClass]="{ 'nav-pills-stepper': isWithIndicators }">
				<li *ngFor="let step of getListeVisibleSteps(listeSteps); let idxStep = index;" [class.active]="selectedStep?.type == step.type" [class.forbidden]="!step.canActivate()" [class.disabled]="!step.canActivate()" [class.done]="step.isDone?.()" [class.has-error]="step.hasError?.()" matRipple>
					<a [routerLink]="[]" (click)="step.canActivate() && selectStep(step);" [attr.data-step]="idxStep + 1">
						<span>{{ getLibelleForStep(step) }}</span>
						<div class="subtitle" *ngIf="step.hasError?.()">{{ step.getSubtitle() }}</div>
					</a>
				</li>
			</ul>
		</div>
		<div class="col-md-9">
			<template #holder></template>
		</div>
	</div>
</div>