import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AvantageNatureAbattementListItemComponent } from './avantage-nature-abattement-list-item.component';

@Component({
	templateUrl: './avantage-nature-abattement-list.component.html'
})
export class AvantageNatureAbattementListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,AvantageNatureAbattementListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,AvantageNatureAbattementListItemComponent>({
			uri: `/controller/Fiscalite/filtreAvantageNatureAbattements`,
			title: this.translateService.instant('fiscalite.abattement.liste.title'),
			component: AvantageNatureAbattementListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'dateDebut',
				isDefault: true,
				type: TypeFilter.DATE,
				dateOptions: {
					format: 'date',
					isStoreDate: true
				}
			}],
			defaultOrder: '-dateDebut',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idAbattement) && this.liste.addItem({
					isDisplayed: true
				});
			})
		});
	}
}