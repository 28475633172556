<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ typeBareme?.avatar }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/Fiscalite/loadBaremeTaxe/',data.idBaremeTaxe]">{{ data?.libelle }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idBaremeTaxe">
		<li *ngIf="typeBareme"><span translate>fiscalite.baremeTaxe.typeBareme.item</span>&#160;:&#160;<span>{{ typeBareme.libelle }}</span></li>
		<li *ngIf="data.dateApplication"><span translate>fiscalite.baremeTaxe.dateApplication</span>&#160;:&#160;<span>{{ data.dateApplication | date:'shortDate' }}</span></li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idBaremeTaxe && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown">
			<icon name="more_vert"></icon>
		</a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteBaremeTaxe();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>