import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap,first,map,tap } from 'rxjs/operators';
import { Observable,of } from 'rxjs';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { BaremeTaxeService } from './bareme-taxe.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { cloneDeep,isEqual } from 'lodash-es';
import { BaremeTaxeEditComponent } from './bareme-taxe-edit.component';
import { BaremeTaxeTrancheListComponent } from './bareme-taxe-tranche-list.component';

@Component({
	selector: 'bareme-taxe',
	templateUrl: './bareme-taxe.component.html'
})
export class BaremeTaxeComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Barème de taxe courant **/
	public baremeTaxe: any;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private baremeTaxeService: BaremeTaxeService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.fiscalite.BaremeTaxe';

	/**
	 * Récupération de l'identifiant du barème de taxe à charger
	 */
	getIdObject: () => number = () => this.baremeTaxe?.idBaremeTaxe || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'fiscalite.baremeTaxe.libelle',
				key: 'libelle'
			},{
				libelle: 'fiscalite.baremeTaxe.typeBareme.item',
				key: (data) => this.translateService.instant(`fiscalite.baremeTaxe.typeBareme.${data.typeBareme}`)
			},{
				libelle: 'fiscalite.baremeTaxe.dateApplication',
				key: 'dateApplication',
				type: 'DATE',
				format: 'date'
			},{
				libelle: 'fiscalite.baremeTaxe.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editBaremeTaxe(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editBaremeTaxe(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteBaremeTaxe(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'warning',
				title: this.translateService.instant('fiscalite.baremeTaxe.alerte.tranches.title'),
				message: this.translateService.instant('fiscalite.baremeTaxe.alerte.tranches.message'),
				isVisible: () => !this.baremeTaxe?.listeTranches?.length && !this.pageContentService.isOpened(),
				doAction: () => this.addTranche(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeElements: [{
				type: 'TRANCHE',
				libelle: 'fiscalite.baremeTaxe.elements.tranche',
				component: BaremeTaxeTrancheListComponent,
				retrieveComponentData: () => ({
					baremeTaxe: this.baremeTaxe,
					addTranche: this.addTranche.bind(this)
				}),
				doAction: () => this.addTranche(),
				count: () => this.baremeTaxe?.listeTranches?.length || 0,
				libelleAction: this.translateService.instant('fiscalite.baremeTaxe.elements.tranche.ajouter'),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isVisible: () => this.rightService.hasRight(null,'consultation')
			}]
		};

		//Récupération de l'identifiant du barème de taxe à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idBaremeTaxe);
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idBaremeTaxe: number) {
		//Enregistrement du barème de taxe
		this.baremeTaxeService.loadBaremeTaxe(idBaremeTaxe).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du barème de taxe
					this.baremeTaxe = result.data?.baremeTaxe;

					//Vérification de l'identifiant du barème de taxe
					if (!this.baremeTaxe?.idBaremeTaxe) {
						//Création d'un objet vierge
						this.baremeTaxe = {
							actif: false
						};

						//Edition du barème de taxe
						this.editBaremeTaxe();
					}
				}
			}
		});
	}

	/**
	 * Edition du barème de taxe
	 */
	private editBaremeTaxe() {
		//Ouverture du composant d'édition
		this.pageContentService.open(BaremeTaxeEditComponent,{
			data: {
				baremeTaxe: cloneDeep(this.baremeTaxe),
				deleteBaremeTaxe: this.deleteBaremeTaxe.bind(this),
				saveBaremeTaxe: this.saveBaremeTaxe.bind(this)
			}
		}).subscribe({
			next: (baremeTaxe: any) => {
				//Vérification des données
				if (baremeTaxe) {
					//Mise à jour du barème de taxe
					this.baremeTaxe = Object.assign(this.baremeTaxe,baremeTaxe);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.baremeTaxe);
				}
			}
		});
	}

	/**
	 * Suppression du barème de taxe
	 */
	protected deleteBaremeTaxe() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.baremeTaxeService.deleteBaremeTaxe(this.baremeTaxe))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Ajout/modification des tranches
	 */
	protected addTranche(baremeTaxe: any = this.baremeTaxe) {
		//Ouverture du composant d'édition
		this.pageContentService.open(BaremeTaxeTrancheListComponent,{
			data: {
				baremeTaxe: cloneDeep(baremeTaxe),
				saveBaremeTaxe: this.saveBaremeTaxe.bind(this),
				isEdition: true
			}
		},'sub').subscribe({
			next: (data: { listeTranches: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('TRANCHE',!isEqual(baremeTaxe.listeTranches,data.listeTranches));

					//Mise à jour du barème de taxe
					baremeTaxe = Object.assign(baremeTaxe,data);
				}
			}
		});
	}

	/**
	 * Vérification du changement de l'entité
	 */
	private checkTypeBeforeSave(baremeTaxe: any): Observable<boolean> {
		//Vérification de la modification de l'entité
		if (this.baremeTaxe.idBaremeTaxe && this.baremeTaxe.typeBareme != baremeTaxe?.typeBareme) {
			//Affichage d'un message de confirmation
			return this.confirmService.showConfirm(this.translateService.instant('fiscalite.baremeTaxe.confirmation.changementType'),{ actionColor: 'primary' }).pipe(
				map((isConfirmed: boolean) => {
					//Vérification de la confirmation
					if (isConfirmed) {
						//Suppression des tranches
						baremeTaxe.listeTranches = null;

						//Retour de la vérification
						return true;
					} else
						//Réinitialisation du type
						baremeTaxe.typeBareme = this.baremeTaxe.typeBareme;

					//Retour de la vérification
					return false;
				}))
		} else
			//Non modification du type de barème
			return of(false);
	}

	/**
	  * Enregistrement de l'objet
	  */
	public saveBaremeTaxe(baremeTaxe: any,close: Function) {
		let isForceRefresh: boolean;

		//Enregistrement de l'objet
		this.checkTypeBeforeSave(baremeTaxe).pipe(
			tap((isRefresh: boolean) => isForceRefresh = isRefresh),
			switchMap(() => this.baremeTaxeService.saveBaremeTaxe(baremeTaxe))
		).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					baremeTaxe = Object.assign(baremeTaxe,result.data?.baremeTaxe);

					//Fermeture du formulaire
					close?.(baremeTaxe);

					//Rafraichissement de l'onglet (si nécessaire)
					this.complexPage?.setSelectedElementByType('TRANCHE',isForceRefresh);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification de la date d'application
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.dateApplication'));

						//Vérification du type de barème
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.typeBareme'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}