import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class EtatLieuxTemplateService {
	/** Liste des types d'affectation **/
	private listeTypesAffectation = ['TOUS','VEHICULE_FONCTION','VEHICULE_SERVICE','VEHICULE_POOL'];

	/** Liste des types de masques intérieurs **/
	private listeTypesMasqueInterieur = ['UNE_ZONE','TROIS_ZONES','QUATRE_ZONES'];

	/** Liste des types de masques extérieurs **/
	private listeTypesMasqueExterieur = ['UNE_ZONE','QUATRE_ZONES','HUIT_ZONES'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {}

	/**
	 * Récupération de la liste des types d'affectation
	 */
	public getListeTypesAffectation(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'affectation
		return this.listeTypesAffectation.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.etatLieuxTemplate.typeAffectation.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de masques intérieurs
	 */
	public getListeTypesMasqueInterieur(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de masques intérieurs
		return this.listeTypesMasqueInterieur.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.etatLieuxTemplate.etatVehicule.typeMasqueInterieur.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de masques extérieurs
	 */
	public getListeTypesMasqueExterieur(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de masques extérieurs
		return this.listeTypesMasqueExterieur.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.etatLieuxTemplate.etatVehicule.typeMasqueExterieur.${code}`)
		}));
	}

	/**
	 * Chargement d'un template d'état des lieux
	 */
	public loadTemplate(idTemplate: number): Observable<Result> {
		//Chargement du template
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadEtatLieuxTemplate/${idTemplate}`,null);
	}

	/**
	 * Suppression d'un template d'état des lieux
	 */
	public deleteTemplate(template: any): Observable<Result> {
		//Suppression du template
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteEtatLieuxTemplate/${template.idEtatLieuxTemplate}`);
	}

	/**
	 * Enregistrement d'un template d'état des lieux
	 */
	public saveTemplate(template: any): Observable<Result> {
		//Enregistrement du template
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveEtatLieuxTemplate`,template);
	}

	/**
	 * Duplication d'un template d'état des lieux
	 */
	public duplicateTemplate(template: any): Observable<Result> {
		//Duplication d'un template
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/duplicateEtatLieuxTemplate/${template.idEtatLieuxTemplate}`,null);
	}
}