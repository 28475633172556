import { AfterContentChecked,Component,Input,ChangeDetectorRef,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { TypeSecret } from 'src/app/domain/security/secret';
import { SecurityService } from 'src/app/share/components/security/security.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'connecteur-params',
	templateUrl: './connecteur-params.component.html'
})
export class ConnecteurParamsComponent implements AfterContentChecked,OnInit {
	/** Liste des paramètres **/
	@Input() listeParams: Array<any>;

	/** Identifiant du connecteur **/
	@Input() idConnecteur: number;

	/** Connecteur Expiration */
	@Input() connecteurExpiration: { dateSecretExpiration: Date,notificationSecretExpiration: boolean };

	/** Options d'affichage **/
	@Input() options?: { isOnlyOptionals?: boolean,buttonLabel?: string };

	/** Indicateur de la présence d'un secret */
	public hasSecret: boolean = false;

	/** Résultat **/
	result: { listeParams: Array<any>,dateSecretExpiration: Date,notificationSecretExpiration: boolean };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ConnecteurParamsComponent>,private securityService: SecurityService,private rightService: RightService,private loggedUserService: LoggedUserService) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Vérification de la présence d'un secret
		this.hasSecret = this.listeParams.some(param => param.param.secret);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement des paramètres
	 */
	public saveParams() {
		//Définition du résultat
		this.result = {
			listeParams: this.listeParams,
			dateSecretExpiration: this.connecteurExpiration?.dateSecretExpiration,
			notificationSecretExpiration: this.connecteurExpiration?.notificationSecretExpiration
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la moadal
		this.bsModalRef.hide();
	}

	/**
	 * Lecture du secret d'un paramètre
	 */
	public readParamSecret(param: any) {
		//Vérification de la nécessité de lire le secret
		if (!param.secretRead && param.idConnecteurParam) {
			//Vérification de la nécessité d'appeler la lecture du secret
			this.securityService.readSecretForType(TypeSecret.CONNECTEUR_PARAM,param.idConnecteurParam,'value').subscribe({
				next: (result: Result) => {
					//Vérification du résultat
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Inversion de l'indicateur d'affichage du secret
						param.showPass = !param.showPass;

						//Récupération de la valeur
						param.value = result?.data?.secret;

						//Définition d'un indicateur de lecture du secret
						param.secretRead = true;
					}
				}
			});
		} else {
			//Inversion de l'indicateur d'affichage du secret
			param.showPass = !param.showPass;
		}
	}

	/**
	 * Vérification de la possibilité de lire le secret
	 */
	public canSeeSecret(): boolean {
		//Vérification du droit de lecture des secrets pour l'utilisateur courant et celui d'origine
		return this.rightService.hasRight(TypeDroit.ADMIN_SECRET,'consultation') || this.rightService.hasRight(TypeDroit.ADMIN_SECRET,'consultation',this.loggedUserService.getUserOrigine());
	}
}
